<template>
  <div class="container">
    <div class="left">
      <div class="dateSelect">
        <el-select
          style="width: 140px"
          v-model="dbSelectValue"
          placeholder="请选择"
          :popper-append-to-body="false"
          @change="getYdfx(dbSelectValue)"
          filterable
          :filter-method="dbFilter"
          clearable
        >
          <el-option
            v-for="item in dbPageData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
          <el-pagination
            @size-change="handleDbSizeChange"
            @current-change="handleDbCurrentChange"
            :current-page.sync="dbCurrentPage"
            :page-size="dbPagesize"
            layout="total, prev, pager, next"
            :total="dbDeviceNameList.length"
            small
            :pager-count="5"
          >
          </el-pagination>
        </el-select>
      </div>
      <div
        id="ydfx"
        style="width: 96%; height: 75%; margin: 35px 0 0 8px"
      ></div>
    </div>
    <div class="right">
      <div class="dateSelect">
        <el-select
          style="width: 140px"
          v-model="sbSelectValue"
          placeholder="请选择"
          :popper-append-to-body="false"
          @change="getYsfx(sbSelectValue)"
          filterable
          :filter-method="sbFilter"
          clearable
        >
          <el-option
            v-for="item in sbPageData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
          <el-pagination
            @size-change="handleSbSizeChange"
            @current-change="handleSbCurrentChange"
            :current-page.sync="sbCurrentPage"
            :page-size="sbPagesize"
            layout="total, prev, pager, next"
            :total="sbDeviceNameList.length"
            small
            :pager-count="5"
          >
          </el-pagination>
        </el-select>
      </div>
      <div
        id="ysfx"
        style="width: 96%; height: 75%; margin: 35px 0 0 8px"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  // 智能水电页-下拉多线折线图
  name: "test9",
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      dbDeviceNameList: [], // 电表总数据
      dbDeviceNameList1: [], // 电表搜索总数据

      sbDeviceNameList: [], // 水表总数据
      sbDeviceNameList1: [], // 水表搜索总数据
      dbSelectValue: [],
      sbSelectValue: [],
      dbCurrentPage: 1, // 电表当前页码
      dbPagesize: 3, // 电表每页显示菜单数
      // -----------------------------
      sbCurrentPage: 1, // 水表当前页码
      sbPagesize: 3, // 水表每页显示菜单数
      waterMeterStaticData: [
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区1#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4700",
          "meterNo": "339900107001",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区2#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4701",
          "meterNo": "339900107002",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区3 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4702",
          "meterNo": "339900107003",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区4 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4703",
          "meterNo": "339900107004",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区5 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4704",
          "meterNo": "339900107005",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区6 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4705",
          "meterNo": "339900107006",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区7 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4706",
          "meterNo": "339900107007",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区8 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4707",
          "meterNo": "339900107008",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区9 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4708",
          "meterNo": "339900107009",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区10#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4709",
          "meterNo": "339900107010",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区11#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4710",
          "meterNo": "339900107011",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区12#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4711",
          "meterNo": "339900107012",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区13#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4712",
          "meterNo": "339900107013",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区14#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4713",
          "meterNo": "339900107014",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区15#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4714",
          "meterNo": "339900107015",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区16#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4715",
          "meterNo": "339900107016",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区17#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4716",
          "meterNo": "339900107017",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区18#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4717",
          "meterNo": "339900107018",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "2",
          "value": "0.0"
        }
      ],
      eleMeterStaticData: [
        {
          "alarmEnable": "1",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区1#",
          "deviceState": "-1",
          "eleAlarmSettings": {
            "id": "217b771f8762466db42e16934f70b150",
            "new": false
          },
          "id": "0d34a6e1d8ea4343be8d0ad547dd4600",
          "meterNo": "000002009001",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "QQ看板",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区2#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4601",
          "meterNo": "000002009002",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区3 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4602",
          "meterNo": "000002009003",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区4 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4603",
          "meterNo": "000002009004",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区5 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4604",
          "meterNo": "000002009005",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区6 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4605",
          "meterNo": "000002009006",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区7 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4606",
          "meterNo": "000002009007",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区8 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4607",
          "meterNo": "000002009008",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区9 #",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4608",
          "meterNo": "000002009009",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区10#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4609",
          "meterNo": "000002009010",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区11#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4610",
          "meterNo": "000002009011",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区12#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4611",
          "meterNo": "000002009012",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区13#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4612",
          "meterNo": "000002009013",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区14#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4613",
          "meterNo": "000002009014",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区15#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4614",
          "meterNo": "000002009015",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区16#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4615",
          "meterNo": "000002009016",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区17#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4616",
          "meterNo": "000002009017",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        },
        {
          "alarmEnable": "0",
          "collectYm": "2021-11",
          "createBy": {
            "credentialsSalt": "nullnull",
            "delFlag": "0",
            "dictList": [],
            "id": "",
            "new": false,
            "organizationNames": "",
            "otherOrganizationNames": "",
            "roleIdList": "",
            "rolesNames": "",
            "safetyHelmetId": "",
            "status": "1"
          },
          "delFlag": "0",
          "deviceMf": "donglw",
          "deviceName": "东门生活区18#",
          "deviceState": "-1",
          "id": "0d34a6e1d8ea4343be8d0ad547dd4617",
          "meterNo": "000002009018",
          "new": false,
          "projectId": "0b1425d254814883ae15272b74aa57f7",
          "projectName": "",
          "remarks": "0",
          "type": "1",
          "value": "0.0"
        }
      ],
      ele7DayMeterValues: [
        {
          "day": "2021-11-24",
          "value": "2.3"
        },
        {
          "day": "2021-11-23",
          "value": "1.2"
        },
        {
          "day": "2021-11-22",
          "value": "0.5"
        },
        {
          "day": "2021-11-21",
          "value": "1.1"
        },
        {
          "day": "2021-11-20",
          "value": "2.3"
        },
        {
          "day": "2021-11-19",
          "value": "5.1"
        },
        {
          "day": "2021-11-18",
          "value": ".19"
        }
      ],
      water7DayMeterValues: [
        {
          "day": "2021-11-24",
          "value": "2.6"
        },
        {
          "day": "2021-11-23",
          "value": "3.5"
        },
        {
          "day": "2021-11-22",
          "value": "4.1"
        },
        {
          "day": "2021-11-21",
          "value": "5.6"
        },
        {
          "day": "2021-11-20",
          "value": "3.2"
        },
        {
          "day": "2021-11-19",
          "value": "0.1"
        },
        {
          "day": "2021-11-18",
          "value": "5.3"
        }
      ],

    };
  },
  computed: {
    dbPageData() {
      return this.dbDeviceNameList.slice(
        (this.dbCurrentPage - 1) * this.dbPagesize,
        this.dbCurrentPage * this.dbPagesize
      );
    },
    sbPageData() {
      return this.sbDeviceNameList.slice(
        (this.sbCurrentPage - 1) * this.sbPagesize,
        this.sbCurrentPage * this.sbPagesize
      );
    },
  },
  mounted() {
    const _self = this;
    _self.$nextTick(() => {
      _self.getYdfx("000002009001"); // 页面刚下载渲染第一个电表的折线图
      _self.getYsfx("339900107001"); // 页面刚下载渲染第一个水表的折线图
    });
    _self.getYdParams();
    _self.getYsParams();
  },
  methods: {
    async getYdParams() {
      const _self = this;
      // let url = myRequest.getRequestUrlWithAnyParam(
      //   "/api/meter/eleMeters",
      //   "&type=-1"
      // );
      // const res = await axios.get(url);
      // if (res.status === 200 && res.data.meters !== undefined) {
      //   let data = res.data.meters;
        let data = this.eleMeterStaticData
        let ydMeterNoList = [];
        for (var i = 0; i < data.length; i++) {
          ydMeterNoList.push({
            label: data[i].deviceName,
            value: data[i].meterNo,
          });
          _self.dbDeviceNameList = ydMeterNoList;
          _self.dbDeviceNameList1 = ydMeterNoList;
        }
      // }
    },
    async getYsParams() {
      const _self = this;
      // let url = myRequest.getRequestUrlWithAnyParam(
      //   "/api/meter/waterMeters",
      //   "&type=-1"
      // );
      // const res = await axios.get(url);
      // if (res.status === 200 && res.data.meters !== undefined) {
      //   let data = res.data.meters;
        let data = this.waterMeterStaticData
        let ysMeterNoList = [];
        for (var i = 0; i < data.length; i++) {
          ysMeterNoList.push({
            label: data[i].deviceName,
            value: data[i].meterNo,
          });
          _self.sbDeviceNameList = ysMeterNoList;
          _self.sbDeviceNameList1 = ysMeterNoList;
        }
      // }
    },
    // 单表用电分析
    async getYdfx(ydId = "000002009001") {
      this.dbDeviceNameList = this.dbDeviceNameList1; // 还原下拉菜单中内容
      var ydfxData = [];
      // try {
        // let url = myRequest.getRequestUrlWithAnyParam(
        //   "/api/meter/ele7DayMeterValues",
        //   `&meterNo=${ydId}`
        // );
        // const res = await axios.get(url);
        // if (res.status == 200 && res.data !== undefined) {
        //   let data = res.data;
          let data = this.ele7DayMeterValues
          for (var i = 0; i < data.length; i++) {
            ydfxData.push({
              name: data[i].day,
              value: data[i].value,
              group: "",
            });
          }
      //   } else {
      //     ydfxData = [{ value: 0, name: "", group: "" }];
      //   }
      // } catch (err) {
      //   ydfxData = [{ value: 0, name: "", group: "" }];
      // }
      var option = MyEcharts.EchartsOption.Line("", "", ydfxData);
      option.legend.show = false;
      option.grid = {
        left: 60,
        bottom: 25,
        right: 45,
        top: 20,
      };
      option.series[0].areaStyle = {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(161, 72, 44,0.65)",
          },
          {
            offset: 0.8,
            color: "rgba(232, 167, 146,0.75)",
          },
        ]),
        // shadowColor: "rgba(44,118,222, 0.8)",
        shadowBlur: 20,
      };
      option.series[0].itemStyle = {
        normal: {
          color: {
            type: "radial",
            x: 0.5,
            y: 0.5,
            r: 0.5,
            colorStops: [
              {
                offset: 0,
                color: "#e89479", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#7e4231", // 0% 处的颜色
              },
            ],
            global: true, // 缺省为 false
          },
          lineStyle: {
            dashOffset: 0,
            width: 2,
            type: "solid", //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
          },
        },
      };
      MyEcharts.initChart(option, "ydfx");
    },
    // 单表用水分析
    async getYsfx(ysId = "339900107001") {
      this.sbDeviceNameList = this.sbDeviceNameList1; // 还原下拉菜单中内容
      var ysfxData = [];
      // try {
      //   let url = myRequest.getRequestUrlWithAnyParam(
      //     "/api/meter/water7DayMeterValues",
      //     `&meterNo=${ysId}`
      //   );
      //   const res = await axios.get(url);
      //   if (res.status === 200 && res.data !== undefined) {
          // let data = res.data;
          let data = this.water7DayMeterValues
          for (var i = 0; i < data.length; i++) {
            ysfxData.push({
              name: data[i].day,
              value: data[i].value,
              group: "",
            });
          }
      //   } else {
      //     ysfxData = [{ value: 0, name: "", group: "" }];
      //   }
      // } catch (err) {
      //   ysfxData = [{ value: 0, name: "", group: "" }];
      // }
      var option = MyEcharts.EchartsOption.Line("", "", ysfxData);
      option.legend.show = false;
      option.grid = {
        left: 60,
        bottom: 25,
        right: 45,
        top: 20,
      };
      option.series[0].areaStyle = {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(30, 120, 233,0.65)",
          },
          {
            offset: 0.8,
            color: "rgba(143, 179, 230,0.65)",
          },
        ]),
        // shadowColor: "rgba(44,118,222, 0.8)",
        shadowBlur: 20,
      };
      option.series[0].itemStyle = {
        normal: {
          color: {
            type: "radial",
            x: 0.5,
            y: 0.5,
            r: 0.5,
            colorStops: [
              {
                offset: 0,
                color: "#4ba0f9", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#4ba0f9", // 0% 处的颜色
              },
            ],
            global: true, // 缺省为 false
          },
          lineStyle: {
            width: 2,
            type: "solid", //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
          },
        },
      };
      MyEcharts.initChart(option, "ysfx");

      // ================================================================
    },
    dbFilter(val) {
      let arr = [];
      let value = val.toLowerCase();
      for (let i = 0, len = this.dbDeviceNameList1.length; i < len; i++) {
        if (this.dbDeviceNameList1[i].label.toLowerCase().indexOf(value) >= 0) {
          arr.push(this.dbDeviceNameList1[i]);
        }
      }
      this.dbDeviceNameList = arr;
      this.handleDbCurrentChange(1);
    },
    sbFilter(val) {
      let arr1 = [];
      let value = val.toLowerCase();
      for (let i = 0, len = this.sbDeviceNameList1.length; i < len; i++) {
        if (this.sbDeviceNameList1[i].label.toLowerCase().indexOf(value) >= 0) {
          arr1.push(this.sbDeviceNameList1[i]);
        }
      }
      this.sbDeviceNameList = arr1;
      this.handleSbCurrentChange(1);
    },
    handleDbSizeChange(val) {
      this.dbpagesize = val;
    },
    handleDbCurrentChange(val) {
      this.dbCurrentPage = val;
    },
    handleSbSizeChange(val) {
      this.sbpagesize = val;
    },
    handleSbCurrentChange(val) {
      this.sbCurrentPage = val;
    },
  },
};
</script>
<style lang='scss' scoped>
.container {
  display: flex;
  width: 1900px;
  height: 220px;
  .left {
    position: relative;
    width: 950px;
    height: 100%;
  }
  .right {
    position: relative;
    width: 950px;
    height: 100%;
  }
}
/deep/ .el-select__caret {
  margin-top: 0px;
}
.dateSelect {
  position: absolute;
  top: 12px;
  right: 14.8px;
  .el-select__tags-text {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /deep/.el-select {
    background: #01427b00;
    position: relative;
    .el-select__input {
      background-color: unset !important;
    }
    .el-select-dropdown {
      z-index: 5504 !important;
    }
    .el-input__icon {
      height: 100%;
      width: 22px;
      text-align: center;
      transition: all 0.3s;
      line-height: unset;
    }
    .el-input {
      .el-input__inner {
        background: #01427b2f;
        border-color: #01427b;
        color: #ddd;
        border: 0;
        height: 24px !important;
        line-height: 24px;
      }
    }

    .el-select-dropdown {
      background-color: #27343e;
      border: none; //;0.0125rem solid #646464;
    }
    .el-popper[x-placement^="bottom"] .popper__arrow::after {
      top: 1px;
      margin-left: -6px;
      border-top-width: 0;
      border-bottom-color: #fff;
    }
    .el-select-dropdown__item {
      color: #ddd;
    }
    // option选中的颜色
    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
      background-color: #23434f;
    }
    // option 鼠标经过的颜色
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #23434f;
    }

    .el-select-dropdown__item.selected {
      color: #ddd;
      font-weight: 700;
    }

    // 下拉中的分页按钮
    .el-pagination {
      color: #ddd;
      .el-pager li {
        background: transparent;
        color: #fff;
      }
      .el-pager li:hover {
        color: #62e3fb;
      }
      .el-pagination__total {
        color: #ddd;
      }
    }
    .el-pagination .btn-next,
    .el-pagination .btn-prev {
      background: center center no-repeat transparent;
    }
    .el-pagination .btn-next,
    .el-pagination .btn-prev {
      color: #ddd;
    }
  }
}
</style>