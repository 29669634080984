import { erpUrl, erpManUrl } from '@/config';
import request from '../axios'

export const getProjectListByCreateUser = (params) => request({
  url: erpUrl + '/projectinfo/getProjectListByCreateUser',
  method: 'get',
  params: params
});
export const getRNBProjectListByCreateUser = (params) => request({
  url: erpUrl + '/projectinfo/getRealNameProjectListByCreateUser',
  method: 'get',
  params: params
});
export const getCompanyListByCreateUser = (params) => request({
  url: erpUrl + '/company-level-business-kanban/company-list',
  method: 'get',
  params: params
});



export const listwaitPickApply = (BladeAuth, Authorization, SwitchToCompanyId) => request({
  url: '/api/huizhuyun-management/material-project-biboard/waitPickApplyList',
  method: 'get',
  hasOwnConfig: true,
  BladeAuth: BladeAuth,
  Authorization: Authorization,
  SwitchToCompanyId: SwitchToCompanyId
});

export const listwaitPickList = (data, BladeAuth, Authorization, SwitchToCompanyId) => request({
  url: '/api/huizhuyun-management/material-project-biboard/waitPickApplyDetailList',
  method: 'post',
  hasOwnConfigs: true,
  data: data
});

export const listwaitPickListCount = (params, BladeAuth, Authorization, SwitchToCompanyId) => request({
  url: '/api/huizhuyun-management/inner-stock/getCalculatedFlowBatchList',
  method: 'get',
  hasOwnConfig: true,
  params: params,
  BladeAuth: BladeAuth,
  Authorization: Authorization,
  SwitchToCompanyId: SwitchToCompanyId,
});

export const listgetInnerBatch = (BladeAuth, Authorization, SwitchToCompanyId) => request({
  url: '/api/huizhuyun-management/material-project-biboard/getInnerBatchList',
  method: 'get',
  hasOwnConfig: true,
  BladeAuth: BladeAuth,
  Authorization: Authorization,
  SwitchToCompanyId: SwitchToCompanyId,
});

export const getManagementAuth = (param) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  params: param,
  companyBiboard: true
});

export const getbiboardToken = (param) => request({
  url: '/api/blade-auth/oauth/tenant/getbiboardToken',
  method: 'get',
  params: param,
  companyBiboard: true
});

/**
 * 获取计算后的批次数据
 * @param params
 * @returns {AxiosPromise}
 */
export const getCalculatedFlowBatchList = (params) => {
  return request({
    url: '/api/huizhuyun-management/inner-stock/getCalculatedFlowBatchList',
    method: 'get',
    params: {
      ...params
    },
    companyBiboardMain: true
  })
}


export const getHzyProjectIdFromJianguan = (params) => {
  return request({
    url: erpManUrl + '/common/getTaiShanTohHzyProjectId',
    method: 'get',
    params: params
  })
}

