<template>
  <el-dialog append-to-body
             @open="open"
             title="导入导出"
             :close-on-click-modal="false"
             :visible.sync="show"
             width="60%">
    <monaco-editor v-model="json"></monaco-editor>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="importData"
                 size="small"
                 type="primary">导入数据</el-button>
      <el-button @click="exportData"
                 size="small"
                 type="danger">导出数据</el-button>
    </span>

  </el-dialog>

</template>

<script>
import MonacoEditor from '@/page/components/editor'
export default {
  components: { MonacoEditor },
  inject: ["contain"],
  data () {
    return {
      show: false,
      json: {}
    }
  },
  methods: {
    open () {
      this.json = {
        config: this.contain.config,
        component: this.contain.nav
      }
    },
    importData () {
      try {
        let json = JSON.parse(this.json)
        this.contain.config = json.config
        this.contain.nav = json.component
        this.show = false;
        this.$message.success('数据导入成功')
      } catch {
        this.$message.error('导入数据错误')
      }
    },
    dataURI2Blob(dataURI) {
  // 分割数据
  const [meta, data] = dataURI.split(',')
  // 对数据编码
  let byte
  if (meta.includes('base64')) {
    byte = atob(data)
  } else {
    byte = encodeURI(data)
  }
  // 获取图片格式
  const mime = meta.split(':')[1].split(';')[0]
  // 创建 8 位无符号整型数组
  const ia = new Uint8Array(byte.length)
  // 获取字符 UTF-16 编码值
  for (let i = 0; i < byte.length; i++) {
    ia[i] = byte.codePointAt(i)
  }
  // 生成类文件对象
  return new Blob([ia], { type: mime })
},
    exportData () {
      let _self = this
      var zip = new window.JSZip();
      zip.file(JSON.parse(_self.json).config.name + moment(new Date()).format('YYYYMMDDHHmm')+"data.txt", this.json);
      zip.generateAsync({ 	type: "blob",
        compression: "DEFLATE" })
        .then(function (content) {
          let blob = content
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveOrOpenBlob(blob, JSON.parse(_self.json).config.name + moment(new Date()).format('YYYYMMDDHHmm') + '.zip');
          }
          else {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob, { type: "text/base64" });
            a.download =JSON.parse(_self.json).config.name + moment(new Date()).format('YYYYMMDDHHmm') + ".zip";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        });
    }
  }
}
</script>

<style>
</style>
