<template>
  <el-form-item :label="labelSet"
                :prop="propSet"
                v-show="showSearch">
    <el-input v-model="inputValue"
              :placeholder=placeholder
              :disabled="disabled"
              clearable
              :maxlength="maxlength"
              @input="inputChange"
              @keyup.enter.native="$emit('enterQuery')"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: '',
  props: {
    // 是否禁用
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    // 配置title
    labelSet: {
      type: String
    },
    // 最大输入长度
    maxlength: {
      type: Number
    },
    // 值
    value: {
      type: String
    },
    type: {
      type: String
    },
    // prop值
    propSet: {
      type: String
    },
    // 占位符
    placeholder: {
      type: String
    },
    // 是否显示高级搜索
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    }
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    inputChange() {
      this.$emit('change', { value: this.inputValue })
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped lang='scss'></style>
