<!-- clapper配置 -->
<template>
  <div class="clappers-address-container">
    <el-form-item label="地址1">
      <avue-input v-model="main.activeObj.data.value1"></avue-input>
    </el-form-item>
    <el-form-item label="自动播放">
      <avue-switch v-model="main.activeOption.autoplay"></avue-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  inject: ['main'],
  created() {}
}
</script>

<style>
.clappers-address-container {
  max-height: 600px;
  overflow: scroll;
}
</style>