<template>
    <el-dialog class="el-dialog-cus2" ref="elDialog" v-dialogdrag v-bind="configNew" top="20vh"  :visible="visible" :before-close="beClose" append-to-body :close-on-click-modal="false" v-on="on">

        <div slot="title" style="height: 10px"></div>
        <div class="dialog-content-ovh">
            <div class="dialog-content">
                <slot v-if="visibleSlot"></slot>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    //https://www.cnblogs.com/tnnyang/p/13596099.html
    /**
     * time: 2021-7-10
     * abner
     */
    export default {
        inheritAttrs: false,
        props: {
            config: {
                type: Object,
                default: () => { },
            },
            autoClose: {
                //是否点击确定后自动关闭弹窗
                type: Boolean,
                default: true,
            },
            beforeClose: {
                //关闭按钮-关闭前
                type: Function,
                default: () => { },
            },
        },
        data() {
            return {
                visible: false,
                attributes: {
                    top: "3vh",
                    title: "数据展示",
                    width: "90%",
                    center: false,
                    btnTxt: ['关闭'] //["取消", "确定"],
                },
                on: this.getDialogEvents(),
                visibleSlot: false,
            };
        },
        computed: {
            configNew() {
                return Object.assign(this.attributes, this.config);
            },
        },
        methods: {
            //显示
            open(ok) {
                this.ok = ok || function () { };
                this.visible = true;
                this.visibleSlot = true; // v-if控制是否渲染,防止嵌套的组件生命周期只会执行一次
            },
            //取消
            cancel() {
                this.visible = false;
            },
            // 确定或提交按钮时触发的
            confirm() {
                let cancel = () => this.cancel();
                this.ok(cancel);
                this.autoClose && cancel();
            },

            //弹窗组件的关闭前
            beClose(done) {
                if (this.visible) {
                    done();
                    this.beforeClose();
                    this.cancel();
                }
            },
            getDialogEvents() {
                // closed: () => this.visibleSlot = false是为了防止弹窗中的内容先于弹窗消失而造成弹窗在关闭时有一个突然向上缩小的情况
                let { close } = this.config || {},
                    events = { closed: () => (this.visibleSlot = false) };

                if (close && typeof close == "function") {
                    Object.assign(events, {
                        close,
                    });
                }

                return events;
            },
        },
    };
</script>
<style lang="scss">
    .el-dialog-cus2 {
        .dialog-content-ovh {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .dialog-content {
                max-height: 83vh;
                width: 100%;
                /*padding-right: 8px;*/
                overflow: auto;
            }
        }

        .el-dialog__body {

            padding: -20px 0px 0px 0px !important;
            letter-spacing: 0.5px;
            // 修改火狐浏览器滚动条样式
            * {
                scrollbar-color: #b8b8b8 #f7f7f9; /* 滑块颜色  滚动条背景颜色 */
                scrollbar-width: auto; /* 滚动条宽度有三种：thin、auto、none */
            }
            margin-bottom: 0;
        }
        .el-dialog__header {
            border-bottom: 0;
            /*display: none;*/
        }
        .el-button:focus,
        .el-button:hover {
            color: #ecedee;
            border-color: #c6e2ff;
            background-color: #7c7c7c;
        }

    }
</style>
<style lang="scss" scoped>
    /deep/ .el-dialog {
        background-image: url("../../assets/dialog-bg.png");
        background-size: 100% 100%;
    }
    /deep/ .el-dialog__headerbtn {
        display: none;
    }
    /deep/ .el-dialog__header {
        min-height: 0px;
        padding: 10px 0;
        z-index: 999;
    }
    .el-dialog-cus2  {
        /deep/ .el-dialog__body {
            margin-top: -30px;
            padding: 0;
        }
    }

</style>
<style>
    .el-dialog-cus2 .header .el-dialog .el-range-input {
        border-color: unset !important;
        background-color: unset !important;
        background-image: url("../../assets/dialog-bg.png");
        background-size: 100% 100%;
    }

</style>