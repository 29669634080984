<template>
  <vue-seamless-scroll :data="dataChart" :class-option="optionHover" class="seamless-warp">
    <span :style="[styleName]" v-html="dataChart[0].title"></span>
  </vue-seamless-scroll>
</template>
<style lang="scss" scoped>
.seamless-warp {
  height: 200px;
  overflow: hidden;
  color: #fff;
}
</style>
<script>
export default {
  name:'companyDetail',
  props: {
    option: Object,
    component: Object
  },
  data () {
    return {
      listData: [
          {
        'title': '1无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行无缝滚动第一行',
      },
        {
          'title': 'asdfasd',
        },
        {
          'title': 'asdfas',
        },
        {
          'title': 'asdfafs',
        },
        {
          'title': 'asdfafs',
        },
        {
          'title': 'asdfafs',
        },
        {
          'title': 'asdfafs',
        },
        {
          'title': 'asdfafs',
        },
        {
          'title': 'asdfafs',
        },
        {
          'title': 'asdfafs',
        },
        {
          'title': 'asdfafs',
        },
        ]
    }
  },
  computed: {
    textWidth () {
      const textLen = (this.dataChart.length> 0 ? this.dataChart[0].title : '').length;
      return textLen * this.fontSize;
    },
    styleName () {
      return {
        width: '100%',
        textAlign: this.option.textAlign,
        letterSpacing: this.setPx(this.split),
        textIndent: this.setPx(this.split),
        backgroundColor: this.option.backgroundColor,
        fontWeight: this.option.fontWeight || "normal",
        fontSize: this.fontSize + "px",
        lineHeight: this.lineHeight + "px",
        color: this.option.color || "#333",
        whiteSpace:'pre-wrap',
      };
    },
    optionHover () {
      return {
        hoverStop: false,
        step:0.5
      }
    }
  },
  methods:{

  }
}
</script>
