<template>
    <el-form-item>
        <el-button :class="['el-button hzy-btn query-btn', costomClass]" :icon="icon" :size="size" autofocus :type="type" :disabled="disabled" @click="handleClick">{{btnText}}</el-button>
    </el-form-item>

</template>
<script>
export default {
    name: 'QuerySearchBtn',
    props: {
        type: {
            type: String,
            default: () => {
                return 'primary'
            }
        },
        size: {
            type: String,
            default: () => {
                return 'small'
            }
        },
        icon: {
            type: String,
            default: () => {
                return 'el-icon-search'
            }
        },
        btnText: {
            type: String,
            default: () => {
                return '搜 索'
            }
        },
        iconText: {
            type: String,
            default: () => {
                return '搜 索'
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        costomClass: {
            type: String,
            default: () => {
                return 'query-search-btn'
            }
        }
    },
    data() {
        return {}
    },
    methods: {
        handleClick() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
