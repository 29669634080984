<template>
	<div class="table-pages" :style="{fontSize:fontSize,color:'#fff'}">
		<div class="videoborder">
			<div v-if="playDict.video0" class="video-content">
				<video id="videoElement0" controls = "true"></video>
				<div class="video-btn el-icon-switch-button" @click="closePlayer(0)"></div>
			</div>
		</div>
		<div class="videoborder">
			<div v-if="playDict.video1" class="video-content">
				<video id="videoElement1" controls = "true"></video>
				<div class="video-btn el-icon-switch-button" @click="closePlayer(1)"></div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import videojs from 'video.js'
	import 'videojs-contrib-hls'
	import flvjs from 'flv.js'
	
	export default {
		name: 'safetyVideo',
		props: {
		  option: Object,
		  component: Object
		},
		data() {
		  return {
			  ws: null,
			  flvPlayer: null,
			  count:0,
			  flvPlayerList: [],
			  playDict:{video0:false,video1:false},
			  deviceIdlist:[],
		  }
		},
		computed: {
		  fontSize() {
		    return (this.option.fontSize || 30) + 'px'
		  }
		},
		mounted() {
			this.$bus.$on('videoMap',(data)=>{
				if(!data.deviceId){
					this.$confirm('该用户没有实时监控，请换一个用户查看')
					return;
				}
				if(this.deviceIdlist.length > 2){
					this.$confirm('您查看得设备超过2个，请点击更多进入监控列表查看')
					return;
				}
				this.deviceIdlist.forEach((item)=>{
					if(item === data.deviceId){
						this.$confirm('该设备已经再播放')
						return;
					}
				})
				for(let key in this.playDict){
					if(this.playDict[key] === false){
						this.playDict[key] = true
						this.changeCount(key)
						this.deviceIdlist.push(data.deviceId)
						this.webSocketJoin(data.deviceId)
						break;
					}
				}
			})
		},
		beforeDestroy(){
			this.$bus.$off('videoMap')
		},
		methods: { 
			changeCount(key){
				if(key === 'video0'){
					this.count = 0
				}
				if(key === 'video1'){
					this.count = 1
				}
			},
			closePlayer(index){
				if(index === 0){
					this.playDict.video0 = false
				}
				if(index === 1){
					this.playDict.video1 = false
				}
				let player = 'videoElement' + index
				var playerElement = document.getElementById(player);
				let i = this.flvPlayerList.findIndex(item => item._mediaElement.id === player);
				let flvPlayer = this.flvPlayerList[i]
				this.destoryVideo(flvPlayer)
				this.flvPlayerList.splice(i,1)
				this.deviceIdlist.splice(i,1)
			},
			webSocketJoin(deviceId) {
				const _self = this
				if ('WebSocket' in window) {
					const protocol = location.protocol === 'https:' ? 'wss://caps.runde.pro/wss' : 'ws://47.106.114.236:9511'
					this.ws = new WebSocket(protocol)
					this.ws.onopen = function() {
					  // Web Socket 已连接上，使用 send() 方法发送数据
					  _self.ws.send(JSON.stringify({ act: 'ma_open_rtsp', device_id: deviceId }))
					}
					
					this.ws.onmessage = function (evt) {
					  const data =evt.data
					  const message = JSON.parse(data)
					  if (message.cmd == 'ma_open_rtsp' && message.status == false){
					    _self.$confirm('设备未上线')
						if(_self.count === 0){
							this.playDict.video0 = false
						}
						if(_self.count === 1){
							this.playDict.video1 = false
						}
					  } else {
						let player = 'videoElement' + _self.count
						if (flvjs.isSupported()) {
							var videoElement = document.getElementById(player)
							this.flvPlayer = flvjs.createPlayer(
							  {
							    type: 'flv', // => 媒体类型 flv 或 mp4，m3u8
							    isLive: true, // => 是否为直播流
							    hasAudio: false, // => 是否开启声音
							    url: message.play_url[2], // => 视频流地址
							    stashInitialSize: 128 // 减少首桢显示等待时长
							  },
							  {
							    enableWorker: false, //不启用分离线程
							    enableStashBuffer: false, //关闭IO隐藏缓冲区
							    reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
							    autoCleanupSourceBuffer: true, //自动清除缓存
							    fixAudioTimestampGap: false //false才会音视频同步
							  }
							)
							this.flvPlayer.attachMediaElement(videoElement)
							
							if (message.play_url[2] !== '' && message.play_url[2] !== null) {
							  this.flvPlayer.load()
							  this.flvPlayer.play()
							  // 启用全屏按钮
							  this.flvPlayer.controls = true;
							  // this.flvPlayer.addEventListener('loadedmetadata', function() {
							  //   this.flvPlayer.requestFullscreen();
							  // });
							}
							
							_self.flvPlayerList.push(this.flvPlayer)
						}
						// this.flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) => {
						//   // alert("网络波动,正在尝试连接中...");
						//   if (this.flvPlayer) {
						//     // this.reloadVideo(this.flvPlayer);
						//   }
						//   // errType是 NetworkError时，对应errDetail有：Exception、HttpStatusCodeInvalid、ConnectingTimeout、EarlyEof、UnrecoverableEarlyEof
						//   // errType是 MediaError时，对应errDetail是MediaMSEError   或MEDIA_SOURCE_ENDED
						// })
					  }
					}
				}
			},
			destoryVideo(flvPlayer) {
			  flvPlayer.pause()
			  flvPlayer.unload()
			  flvPlayer.detachMediaElement()
			  flvPlayer.destroy()
			  flvPlayer = null
			}
		},
	}
</script>

<style scoped lang="scss">
	.table-pages{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		.videoborder{
			background: url('../../assets/safetyHat.png') no-repeat;
			-moz-background-size:100% 100%;
			background-size:100% 100%;
			width: 49%;
			height: 100%;
			box-sizing: border-box;
			.video-content{
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				video{
					width: 98%;
					height:88%;
				}
			}
		}
	}
	.video-btn{
		text-align: center;
		margin-top: 5px;
	}
</style>