import EchartBar from './packages/bar';
import EchartPie from './packages/pie';
import EchartLine from './packages/line';
import EchartTable from './packages/table';
import EchartTablePicking from './packages/tablePicking';
import EchartTableOutbound from './packages/tableOutbound';
import EchartTableBatch from './packages/tableBatch';
import EchartFlop from './packages/flop';
import EchartDatetime from './packages/datetime';
import EchartText from './packages/text';
import EchartSwiper from './packages/swiper';
import EchartIframe from './packages/iframe';
import EchartVideo from './packages/video';
import EchartWordCloud from './packages/wordCloud';
import EchartGauge from './packages/gauge';
import EchartProgress from './packages/progress';
import EchartMaps from './packages/map';
import EchartImg from './packages/img';
import EchartImgBorder from './packages/imgBorder';
import EchartTabs from './packages/tabs';
import EchartPictorialBar from './packages/pictorialBar';
import EchartRadar from './packages/radar';
import EchartFunnel from './packages/funnel';
import EchartScatter from './packages/scatter';
import EchartCommon from './packages/common';
import EchartDatav from './packages/datav';
import EchartClapper from './packages/clappr';
import EchartClappers from './packages/clapprs';
import EchartYunClappers from './packages/yunClapprs';
import EchartYunClappersAuto from './packages/yunClapprsAuto';
import EchartTime from './packages/time';
import EchartDatePicker from './packages/datePicker';
import EchartDefaultDay from './packages/defaultDay';
import EchartPie3D from './packages/pie3d';
import EchartMaterialSearch from './packages/materialSearch';
import EchartTree from './packages/tree';
import EchartInput from './packages/input';
import EchartInputSearch from './packages/inputSearch';
import EchartYear from './packages/year';

import EchartMonth from './packages/month';
import EchartMonthSB from './packages/monthSB';
import EchartPopupQuestion from './packages/popupQuestion';
import EChartcustomPie from '@/components/test/customPie';
import EChartCustomPieTransverse from '@/components/test/customPieTransverse';
import EChartDetailTable from '@/components/test/detailTable';

import EchartDay from './packages/day';
import EchartDays from './packages/days';
import EchartThree from './packages/three';
export default { EchartPopupQuestion,EchartInputSearch, EchartRadar, EchartScatter, EchartFunnel, EchartTabs, EchartVideo, EchartWordCloud, EchartPictorialBar, EchartMaps, EchartImg, EchartImgBorder, EchartBar, EchartGauge, EchartIframe, EchartSwiper, EchartTable, EchartTablePicking, EchartTableOutbound, EchartTableBatch, EchartPie, EchartText, EchartLine, EchartFlop, EchartDatetime, EchartProgress, EchartCommon, EchartDatav, EchartClapper, EchartClappers, EchartYunClappers, EchartYunClappersAuto, EchartTime, EchartDatePicker,EchartDefaultDay, EchartPie3D, EchartMaterialSearch, EchartTree, EchartInput, EchartYear, EchartDay, EchartDays, EchartMonth, EchartMonthSB,EChartcustomPie,EChartCustomPieTransverse,EChartDetailTable, EchartThree };
