<template>
  <div class="container">
    <ul class="kb-nav">
      <li
        v-for="(item, index) in navList"
        :key="index"
        @click="currentIndex = item.path"
      >
        <a :href="item.path">{{ item.title }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  // 主导航组件
  name: "test4",
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      navList: [
        { title: "综合看板", path: "1381056484640559105" },
        //{ title: "经营看板", path: "1425638031481753601" },
        //{ title: "劳务实名制", path: "1382334722725646337" },
        { title: "质量安全", path: "1382587692419649537" },
        //{ title: "物料管理", path: "1425991330693234690" },
        { title: "数字工地", path: "1426004324638638082" },
        { title: "BIM看板", path: "1425293471199121409" },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.kb-nav {
  width: 650px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  box-sizing: border-box;
  li {
    background-image: url("/img/border/border15.png");
    background-size: 100% 100%;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 0px;
    margin: 0px 4px;
    color: #eeeeee;
    height: 35px;
    line-height: 35px;
    padding: 0 8px;
    cursor: pointer;
    list-style: none;
    &.active {
      background-image: url("/img/banner/banner5.png");
      background-size: 100% 100%;
      color: rgb(77, 255, 255);
    }
    a {
      color: #eeeeee;
      font-size: 16px;
    }
  }
}
</style>