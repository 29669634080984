<template>
    <div class="material-container">
        <el-select
                v-model="value"
                class="select"
                size="medium"
                collapse-tags
                @change="blur"
                placeholder="请选择">
            <!--<div slot="empty" class="select-popup-container">-->
                <!--<div class="condition">-->
                    <!--<div class="search">-->
                        <!--<input v-model="searchValue" class="input" type="text" placeholder="请搜索物料名称">-->
                        <!--<div class="search-btn"><i class="el-icon-search" style="font-size: 16px"></i></div>-->
                    <!--</div>-->
                    <!--<div class="tabs">-->
                        <!--<div class="tab" :class="{active: activeTabIndex == 0}" @click="tabClick(0)">金额排名</div>-->
                        <!--<div class="tab" :class="{active: activeTabIndex == 1}" @click="tabClick(1)">频次排名</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
                <div class="select-popup-container">
                    <div slot="prefix" class="condition">
                        <el-option
                                style="enable: false; height: 0"
                                key="PLACE"
                                label=""
                                >
                            <!--<span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>-->
                        </el-option>
                        <div class="search">
                            <input v-model="searchValue" class="input" type="text" placeholder="请搜索物料名称">
                            <div class="search-btn"><i class="el-icon-search" style="font-size: 16px"></i></div>
                        </div>
                        <div class="tabs">
                            <div class="tab" :class="{active: activeTabIndex == 0}" @click="tabClick(0)">金额排名</div>
                            <div class="tab" :class="{active: activeTabIndex == 1}" @click="tabClick(1)">频次排名</div>
                        </div>
                    </div>
                    <div style="overflow: hidden; margin-top: 0px; padding: 0 3px">
                        <div v-if="activeTabIndex == 0">
                            <el-option
                                    v-for="item in showData['金额']"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                <span style="float: left">{{ item.label }}</span>
                                <!--<span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>-->
                            </el-option>
                        </div>
                        <div v-if="activeTabIndex == 1">
                            <el-option
                                    v-for="item in showData['频次']"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                <span style="float: left">{{ item.label }}</span>
                                <!--<span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>-->
                            </el-option>
                        </div>
                    </div>
                </div>



        </el-select>
    </div>
</template>

<script>
    import create from "../../create";
    export default create({
        name: "materialSearch",
        data() {
            return  {
                value: '',
                activeName: 'second',
                activeTabIndex: 0,
                searchValue: ''
            }
        },
        computed: {
            showData() {
                if(this.searchValue == "") return this.dataChart;
                let filterMoneyData = this.dataChart["金额"].filter(item=> item.label.includes(this.searchValue))
                let filterPinciData = this.dataChart["频次"].filter(item=> item.label.includes(this.searchValue))
                return {
                    "金额": filterMoneyData,
                    "频次": filterPinciData
                }
            }
        },
        methods: {
            tabClick(index) {
                this.activeTabIndex = index
            },
            handleClick() {
                console.log("handleClick")
                console.log(this.value)
                let param = this.value
                // let param = this.value
                this.updateClick({value: param});

                this.clickFormatter &&
                this.clickFormatter(
                    {
                        data: this.value
                    },
                    this.getItemRefs()
                )
            },
            blur(e) {
                this.handleClick()
            }
        }
    });
</script>

<style scoped lang='scss'>
    .material-container {
        width: 100%;
        .select {
            /*padding: 5px;*/
            width: 100%;
            /*height: 100%;*/
            /deep/ input {
                min-height: 40px;
                background-color: unset !important;
                background-image: url("../../../assets/Select-box.png") !important;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        /deep/ .el-tag--info {
            color: #fff;
            background-color: #00d3f1;
            border-color: #00d3f1;
            .el-tag__close {
                background-color: #fff;
            }
        }

    }
    .condition {
        /*position: absolute;*/
        /*left: 0;*/
        /*right: 0;*/
        /*top: 0;*/
        margin: auto;
        padding: 15px 5px 5px;
        width: 90%;
        color: #fff;
        /*background-color: rgba(7,204,240, 0.6);*/
        z-index: 9;
        font-size: 12px;
        .search {
            margin: auto;
            width: 100%;
            height: 30px;
            border: #fff 1px solid;
            display: flex;
            align-items: center;
            background-color: #15406C;
            .input {
                flex: 1;
                margin-left: 10px;
                background-color: unset !important;
                border: unset !important;
            }
            .search-btn {
                width: 25px;
            }
            .input::placeholder {
                font-weight: 600;
                color: #fff;
            }

        }
        .tabs {
            margin: 5px 0 2px 0;
            height: 22px;
            display: flex;
            .tab {
                /*flex: 1;*/
                margin: 0 10px;
                text-align: center;
                &.active {
                    border-bottom: 2px #fff solid;
                }
            }
        }
    }

    .el-select-dropdown__item {
        color: #fff;
        .hover {
            color: #00d3f1
        }
    }


</style>
<style>
    /*.el-select-dropdown {*/
        /*background-color: rgba(7,204,240, 0.6);*/
    /*}    */
    .select-popup-container {
        margin-top: -5px;
        /*background-color: rgba(7,204,240, 0.5);*/
        background-color: #055983;
    }
</style>



