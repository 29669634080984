<template>
  <div>
    <div class="container">
      <div class="left_container">
        <!-- 左上模块 -->
        <div class="left1">
          <!-- 在线/离线图标 -->
          <Chartimg :option="optin"></Chartimg>
          <!-- 标题-卸料平台信息 -->
          <div class="tjxx_title1">
            <div class="echart-text">
              <span>卸料平台信息</span>
            </div>
          </div>
          <!-- 内容-卸料平台信息 -->
          <div class="taji_text">
            <p>
              设备编号：<i>{{ xlptxxData.unload_id }}</i>
            </p>
            <p>
              设备名称：<i>{{ xlptxxData.unload_name }}</i>
            </p>
            <p>
              安装时间：<i>{{ xlptxxData.install_date }}</i>
            </p>
            <p>
              预警阈值：<i>{{ earlyWarningData }}</i>
            </p>
            <p>
              报警阈值：<i>{{ alarmData }}</i>
            </p>
          </div>
        </div>
        <!-- 左下模块 -->
        <div class="left2">
          <div class="scroll_board">
            <dv-scroll-board
              :config="config"
              class="scroll_board_body"
              @click="handleTjlb"
            />
          </div>
        </div>
      </div>
      <div class="right_container">
        <!-- 右上模块 -->
        <div class="right1">
          <span>{{ option1[0].value }}</span>
          <span>{{ option1[1].value }}</span>
          <span>{{ option1[2].value }}</span>
          <span>{{ option1[3].value }}</span>
        </div>
        <!-- 右下模块 -->
        <div class="right2">
          <div class="center_info" v-if="isShowCenterInfo">
            <div class="info_item">
              <div class="title_icon">
                <img :src="centerInfo[0].icon" alt="" />
              </div>
              <div class="title1">{{ centerInfo[0].title }}</div>
              <div class="val_content">
                <span class="val">{{ centerInfo[0].val }}</span>
              </div>
              <div class="progress">
                <div class="progress_bar" :style="centerInfo[0].style2"></div>
              </div>
              <div class="rec" :style="centerInfo[0].style1"></div>
            </div>
            <!-- ------------------------------ -->
            <div class="info_item">
              <div class="title_icon">
                <img :src="centerInfo[1].icon" alt="" />
              </div>
              <div class="title1">{{ centerInfo[1].title }}</div>
              <div class="val_content">
                <span class="val">{{ centerInfo[1].val }}</span>
              </div>
              <div class="progress">
                <div class="progress_bar" :style="centerInfo[1].style2"></div>
              </div>
              <div class="rec" :style="centerInfo[1].style1"></div>
            </div>
            <!-- ------------------------------ -->
            <div class="info_item">
              <div class="title_icon">
                <img :src="centerInfo[2].icon" alt="" />
              </div>
              <div class="title1">{{ centerInfo[2].title }}</div>
              <div class="val_content">
                <span class="val">{{ xlptxxData.electric_quantity1 }}</span>
              </div>
              <div class="progress">
                <div class="progress_bar" :style="centerInfo[2].style2"></div>
              </div>
              <div class="rec" :style="centerInfo[2].style1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chartimg from "./chartimg.vue";
export default {
  // 卸料平台监测页组件
  name: "test6",
  components: { Chartimg },
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      config: {
        rowNum: 8,
        headerBGC: "rgba(8, 90, 111, 0.2)",
        oddRowBGC: "rgba(14, 86, 241, 0.08)", //奇
        evenRowBGC: "", //偶
        header: ["姓名", "工种", "班组", "时间"],
        data: [],
      },
      tower_crane_no: "", // 获取卸料平台信息接口的参数
      tjxxData: [
        {
          tower_crane_no: "",
          tower_crane_name: "",
          manufacturer: "",
          driver_name: "",
          recognition_type: "",
        },
      ],
      virtualData: [["", "", ""]],
      centerInfo: [
        {
          title: "重量",
          val: "",
          unit: "千克",
          style1: "background-color: #0ff70f;",
          style2: "",
          icon: "/img/source/重量.svg",
        },
        {
          title: "偏置值",
          val: "",
          unit: "",
          // style1: "background-color: #f99032;",
          style1: "background-color: #0ff70f;",
          style2: "",
          icon: "/img/source/偏置值.svg",
        },
        {
          title: "电量",
          val: "",
          unit: "%",
          style1: "background-color: #0ff70f;",
          style2: "",
          icon: "/img/source/电量.svg",
        },
      ],
      optin: {
        data: [
          {
            style:
              "width: 70px;right: 10px; top: 25px;color:rgba(78, 80, 82); font-size: 18px; ",
            value: "离线",
            url: "/img/source/source234.svg",
            // title: "在册人数",
            spin: false,
          },
        ],
      },
      option1: [
        {
          title: "预警次数",
          value: "0",
          style: "color: #2cf314",
        },
        {
          title: "报警次数",
          value: "0",
          // style: "color: #f71214",
          style: "color: #2cf314",
        },
        {
          title: "超载次数",
          value: "2",
          // style: "color: #f71214",
          style: "color: #2cf314",
        },
        {
          title: "超载率",
          value: "6%",
          // style: "color: #f7951f",
          style: "color: #2cf314",
        },
      ],
      xlptxxData: {},
      earlyWarningData: "",
      alarmData: "",
      isShowCenterInfo: true,
      virtualData: [["", "", ""]],
      unloadDataStaticList: [
        {
          "unload_id": "UL-65332",
          "unload_name": "集中卸料台#1",
          "alarm_weight": 266,
          "bias": 2.5,
          "early_warning_weight": 200,
          "record_time": "2021-11-27 23:15:16",
          "early_warning": 12,
          "warning": 8,
          "weight": 300,
          "electric_quantity": 95,
          "unload_status": "在线",
          "install_date": "2020-05-08",
          "overload_time": "3",
          "overload_rate": "6%"
        },
        {
          "unload_id": "UL-65344",
          "unload_name": "集中卸料台#2",
          "alarm_weight": 530.5,
          "bias": 1.4,
          "early_warning_weight": 500,
          "record_time": "2021-11-27 10:23:16",
          "early_warning": 21,
          "warning": 11,
          "weight": 300,
          "electric_quantity": 77,
          "unload_status": "未工作",
          "install_date": "2020-11-22",
          "overload_time": "5",
          "overload_rate": "12%"
        },
        {
          "unload_id": "UL-65359",
          "unload_name": "集中卸料台#3",
          "alarm_weight": 350.6,
          "bias": 1.8,
          "early_warning_weight": 300,
          "record_time": "2021-11-27 22:17:16",
          "early_warning": 15,
          "warning": 9,
          "weight": 300,
          "electric_quantity": 15,
          "unload_status": "在线",
          "install_date": "2020-09-23",
          "overload_time": "15",
          "overload_rate": "31%"
        },
        {
          "unload_id": "UL-65378",
          "unload_name": "集中卸料台#4",
          "alarm_weight": 203,
          "bias": 1.7,
          "early_warning_weight": 200,
          "record_time": "2021-11-27 22:09:16",
          "early_warning": 33,
          "warning": 22,
          "weight": 300,
          "electric_quantity": 83,
          "unload_status": "未工作",
          "install_date": "2020-12-31",
          "overload_time": "0",
          "overload_rate": "0%"
        },
      ]
    };
  },
  created() {
    this.$nextTick(() => {
      this.getYlptlb(); // 卸料平台列表
    });
  },
  methods: {
    // 卸料平台列表
    async getYlptlb() {
      let _self = this;
      // let url = myRequest.getRequestUrlWithAnyParam2(
      //   "/api/homepage/unload/list"
      // );
      // const res = await axios.get(url);
      let ylptlbArr = [];
      // if (res.status === 200 && res.data.data !== undefined) {
        // let data = res.data.data;
        let data = this.unloadDataStaticList
        let unload_id = []; // 保存卸料平台列表的设备编号
        data.forEach((item) => {
          unload_id.push(item.unload_id),
            ylptlbArr.push([
              item.unload_id,
              item.unload_name,
              item.unload_status,
            ]);
        });
        // this.optin.data[0].spin = data.tower_crane_status;
        // 卸料平台列表数据获取到后 使用卸料平台列表的第一个设备编号作为卸料平台信息的情趣参数
        this.getYlptxx(0);
      // } else {
      //   ylptlbArr = this.virtualData;
      //   _self.isShowCenterInfo = false;
      // }
      _self.config = {
        rowNum: 8,
        headerBGC: "rgba(8, 90, 111, 0.2)",
        oddRowBGC: "rgba(14, 86, 241, 0.08)", //奇
        evenRowBGC: "", //偶
        header: ["设备编号", "卸料平台名称", "在线/离线"],
        hoverPause: true,
        data: ylptlbArr,
      };
    },
    // 卸料平台信息
    async getYlptxx(index) {
      let _self = this;
      // let url = myRequest.getRequestUrlWithAnyParam(
      //   "/api/homepage/unload/info",
      //   `&unload_id=${id}`
      // );
      // const res = await axios.get(url);
      // if (res.status === 200 && res.data.data !== undefined) {
        let data = this.unloadDataStaticList[index]
        
        // let data = res.data.data;
        _self.xlptxxData = data;
        if (data.early_warning_weight !== undefined) {
          _self.earlyWarningData = data.early_warning_weight + "kg";
        }
        if (
          data.alarm_weight !== undefined &&
          data.alarm_weight !== NaN
        ) {
          _self.alarmData = data.alarm_weight + "kg";
        }
        // 中间顶部四个小模块-------------------------------------------------
        if (data.early_warning !== undefined) {
          _self.option1[0].value = data.early_warning;
        }
        if (data.warning !== undefined) {
          _self.option1[1].value = data.warning;
        }
        if (data.overload_time !== undefined) {
          _self.option1[2].value = data.overload_time;
        }
        if (data.overload_rate !== undefined) {
          _self.option1[3].value = data.overload_rate;
        }
        // 设置:重量、偏置值、电量---------------------------------------------
        if (data.weight !== undefined) {
          _self.centerInfo[0].val = data.weight + "kg"; // 重量
        } else {
          _self.centerInfo[0].val = 0;
        }
        _self.centerInfo[0].style2 = `width:${
          (parseInt(data.weight) / parseInt(data.alarm_weight)) * 100
        }%;`; // 重量进度条
        if (data.bias !== undefined) {
          _self.centerInfo[1].val = data.bias; // 偏置值
        } else {
          _self.centerInfo[1].val = 0;
        }
        _self.centerInfo[1].style2 = `width:${Math.abs(
          parseInt(data.bias)
        )}%;`; // 偏置值的进度条
        if (data.electric_quantity !== undefined) {
          _self.xlptxxData.electric_quantity = data.electric_quantity;
          _self.xlptxxData.electric_quantity1 = data.electric_quantity; // 电量
        } else {
          _self.xlptxxData.electric_quantity = 0;
        }
        _self.centerInfo[2].style2 = `width:${parseInt(
          data.electric_quantity
        )}%;`; // 电量进度条
        // 判断 重量 色块颜色 #0ff70f #f7951f #f71214
        data.weight = parseInt(data.weight);
        data.alarm_weight = parseInt(data.alarm_weight);
        data.early_warning_weight = parseInt(data.early_warning_weight);
        if (
          data.weight > data.early_warning_weight &&
          data.weight < data.alarm_weight
        ) {
          _self.centerInfo[0].style1 = "background-color: #f7951f;";
        } else if (data.weight >= data.alarm_weight) {
          _self.centerInfo[0].style1 = "background-color: #f71214;";
        } else if (data.weight < data.early_warning_weight) {
          _self.centerInfo[0].style1 = "background-color: #0ff70f;";
        }
        // 判断 偏置值 色块颜色 #0ff70f #f7951f #f71214
        data.bias = Math.abs(parseInt(data.bias));
        if (data.bias / 100 < 0.1) {
          _self.centerInfo[1].style1 = "background-color: #0ff70f;";
        } else if (data.bias / 100 >= 0.1 && data.bias / 100 < 0.3) {
          _self.centerInfo[1].style1 = "background-color: #f7951f;";
        } else if (data.bias / 100 >= 0.3) {
          _self.centerInfo[1].style1 = "background-color: #f71214;";
        }
        // ------------------------------------------
        // 判断 电量 色块颜色 #0ff70f #f7951f #f71214
        data.electric_quantity = parseInt(data.electric_quantity);
        if (data.electric_quantity > 50) {
          _self.centerInfo[2].style1 = "background-color: #0ff70f;";
        } else if (
          data.electric_quantity <= 50 &&
          data.electric_quantity > 20
        ) {
          _self.centerInfo[2].style1 = "background-color: #f7951f;";
        } else if (data.electric_quantity <= 20) {
          _self.centerInfo[2].style1 = "background-color: #f71214;";
        }
        // ------------------------------------------
        // 设置预料平台设备工作状态
        _self.optin.data[0].value = _self.xlptxxData.unload_status;
        if (_self.xlptxxData.unload_status === "未工作") {
          _self.optin.data[0].spin = false;
          _self.optin.data[0].style =
            "width: 70px;right: 10px; top: 25px;color:rgba(78, 80, 82); font-size: 18px; ";
        } else {
          _self.optin.data[0].spin = true;
          _self.optin.data[0].style =
            "width: 70px;right: 10px; top: 25px;color:rgba(31, 240, 33); font-size: 18px; ";
        }
      // } else {
      //   _self.isShowCenterInfo = false;
      // }
    },
    handleTjlb(row) {
      this.getYlptxx(row.rowIndex);
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 1387px;
  height: 922px;
  display: flex;
  .left_container {
    width: 437px;
    height: 922px;
    // background-color: rgba(255, 192, 203, 0.4);
    .left1 {
      overflow: hidden;
      position: relative;
      width: 434px;
      height: 254px;
      // background-color: rgba(255, 206, 68, 0.4);
      .tjxx_title1 {
        width: 170px;
        height: 35px;
        margin: 7px auto 0;
        color: #fff;
        text-align: center;
        background: url("/img/source/标题框.png") center 0 no-repeat;
        background-size: 100% 100%;
        .echart-text {
          font-size: 16px;
          line-height: 35px;
        }
      }
      .tjxx_title2 {
        width: 170px;
        height: 35px;
        margin: 162px auto 0;
        color: #fff;
        text-align: center;
        background: url("/img/source/标题框.png") center 0 no-repeat;
        background-size: 100% 100%;
        .echart-text {
          font-size: 16px;
          line-height: 35px;
        }
      }
      .taji_text {
        position: absolute;
        top: 38px;
        left: 22px;
        padding: 2px 0 0 6px;
        width: 460px;
        height: 145px;
        p {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 35px;
          letter-spacing: 2px;
          i {
            font-style: normal;
            color: #e8a36a;
          }
        }
      }
      .siji_text {
        position: absolute;
        top: 244px;
        left: 20px;
        padding: 2px 0 0 6px;
        width: 460px;
        height: 80px;
        p {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 2px;
          i {
            font-style: normal;
            color: #e8a36a;
          }
        }
      }
    }
    .left2 {
      width: 434px;
      height: 320px;
      padding-top: 46px;
      // background-color: rgba(255, 0, 0, 0.4);
      .scroll_board {
        width: 407px;
        margin: auto;
        height: 100%;
      }
    }
  }
  .right_container {
    position: relative;
    width: 950px;
    height: 922px;
    .right1 {
      display: flex;
      position: absolute;
      top: 62px;
      left: 0px;
      width: 950px;
      height: 50px;
      span {
        flex: 1;
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        text-align: center;
        color: #2cf314;
      }
    }
    .right2 {
      position: relative;
      top: 240px;
      left: 530px;
      width: 400px;
      height: 400px;
      .center_info {
        width: 310px;
        height: 350px;
        .info_item {
          position: relative;
          width: 100%;
          margin-bottom: 30px;
          .title_icon {
            position: absolute;
            left: 0;
            width: 22px;
            height: 22px;
            img {
              width: 100%;
            }
          }
          .title1 {
            color: #3fc2ff;
            font-size: 18px;
            font-weight: 600;
            margin-left: 35px;
          }

          .val_content {
            display: flex;
            width: 130px;
            margin: 10px 0 0 10px;
            .val {
              flex: 7;
              color: #fff;
              font-size: 28px;
              font-weight: 700;
            }
            .unit {
              flex: 3;
              margin-top: 7px;
              color: #48a0f5;
            }
          }
          .progress {
            width: 130px;
            height: 9px;
            margin: 5px 0 0 5px;
            border-radius: 9px;
            background-color: #fff;
            box-sizing: border-box;
            border: 1px solid #7b75a1;
            .progress_bar {
              // width: 80%;
              height: 100%;
              max-width: 100%;
              border-radius: 9px;
              background-color: #564aa3;
            }
          }
          .rec {
            position: absolute;
            top: 40px;
            right: 0;
            left: 180px;
            width: 115px;
            height: 27px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.dv-scroll-board .header {
  font-size: 12px;
}
</style>