<!-- 选项卡配置 -->
<template>
  <div>
    <el-form-item label="只可选择当前日期之前的">
      <avue-switch v-model="main.activeOption.intercept"></avue-switch>
    </el-form-item>
    <el-form-item label="默认值日期">
      <avue-switch v-model="main.activeOption.defaultValue"></avue-switch>
    </el-form-item>
  </div>
</template>

<script>
import { dicOption } from "@/option/config";
export default {
  data() {
    return {
      dicOption: dicOption,
    };
  },
  inject: ["main"],
};
</script>

<style></style>
