<template>
  <div :style="{ fontSize: fontSize, color: '#fff' }">
    <iframe
      scrolling="no"
      :src="weatherSrc"
      frameborder="0"
      width="550"
      height="95"
      allowtransparency="true"
      class="iframe"
    ></iframe>
  </div>
</template>
<script>
export default {
  // 天气预报组件
  name: "test1",
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      weatherSrc:
        "https://tianqiapi.com/api.php?style=tj&skin=pitaya&color=FFFFFF&paddingleft=35&paddingtop=10&city=",
    };
  },
  computed: {
    fontSize() {
      return (this.option.fontSize || 30) + "px";
    },
  },
  methods: {
    async getCityName() {
      // let url = myRequest.getRequestUrlWithAnyParam(
      //   "/api/homepage/project/info"
      // );
      // const res = await axios.get(url);
      // const result = res.data.data;
      // let cityName = result.city_name;
      const cityName = "抚顺"
      if (cityName !== undefined) {
        this.weatherSrc = this.weatherSrc + cityName;
      } else {
        this.weatherSrc =
          "https://tianqiapi.com/api.php?style=tj&skin=pitaya&color=FFFFFF&paddingleft=35&paddingtop=10";
      }
    },
  },
  created() {
    this.getCityName();
  },
};
</script>