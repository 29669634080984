<template>
  <div :class="b()" class="input-custom-content" ref="main">
    <el-input :placeholder="option.placeholder" v-model="data"  style="border: 1px solid #07DBFF;"></el-input>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "input",
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },


});
</script>

<style scope lang="scss">
    .el-tree {
        background: unset;
        color: #fff;
        /deep/ .el-tree-node__content  {
            background-color: unset;
        }
    }
</style>
<style scope>
    .el-tree-node:focus>.el-tree-node__content {
        background-color: unset;
    }
    .el-tree-node.is-current>.el-tree-node__content {
        background-color: unset;
    }
    .el-tree-node__content:hover {
        background-color: unset;
    }
    .el-tree-node__label {
        font-size: 14px;
    }
</style>
<style>
.input-custom-content .el-input__inner {
    background-color: #1A245A !important;
    border-color: #1A245A !important;
}
</style>


