<template>
  <div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; height: 425px"
      :data-checkRow="checkRow"
      :data-multipleSelectionOut="multipleSelection"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
      type="selection"
      width="55">
    </el-table-column> -->
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="materielCode" label="物料编码"> </el-table-column>
      <el-table-column prop="materielName" label="物料名称" width="290">
      </el-table-column>
      <el-table-column prop="specification" label="规格型号"> </el-table-column>
      <el-table-column prop="locationName" label="物料区域"> </el-table-column>
      <el-table-column prop="unitCodeDesc" label="单位"> </el-table-column>
      <el-table-column prop="brand" label="品牌"> </el-table-column>
      <el-table-column prop="availableQuantity" label="可用数量">
      </el-table-column>
      <el-table-column prop="materialStock" label="库存"> </el-table-column>
      <el-table-column prop="pickingApplyAmount" label="申请数量">
      </el-table-column>
      <el-table-column prop="outerQuantity" label="已出库数量">
      </el-table-column>
      <el-table-column prop="amount" label="出库数量">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入"
            v-model="scope.row.amount"
            @blur="calDetailData(scope, scope.$index)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="单价（元）"> </el-table-column>
      <el-table-column prop="noTaxPrice" label="不含税单价（元）">
      </el-table-column>
      <el-table-column prop="total" label="合计（元）"> </el-table-column>
      <el-table-column prop="noTaxTotal" label="不含税总额（元）">
      </el-table-column>
      <el-table-column prop="type" label="材料属性">
        <template slot-scope="scope">
          <el-select v-model="scope.row.type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button @click="cellClick(scope.row)" type="text"
            >选择批次</el-button
          >
          <el-button @click="delClick(scope.$index, tableData)" type="text"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { listwaitPickList, listwaitPickListCount } from "@/api/erp";
import { accAdd, accDiv, accMul, accSub } from "@/utils/utils2";
import create from "../../create";
export default create({
  name: "tableOutbound",
  data() {
    return {
      options: [
        {
          value: "1",
          label: "主材",
        },
        {
          value: "2",
          label: "辅材",
        },
      ],
      value: "",
      headerHeight: "",
      height: "",
      scrollCheck: "",
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
      extentParam: {},
      input: "",
      orderId: "",
      id: "",
      materielCode: "",
      materielName: "",
      bladeAuthInfo: "",
      AuthorizationInfo: "",
      SwitchToCompanyIdInfo: "",
      storeId: "",
      tableData: [],
      tableDataSubData: [],
      ignoreRequestDatas: [],
      multipleSelection: [],
      checkRow: {},
      tatals: null,
      amounts: null,
      enterNum: 1,
    };
  },
  watch: {
    scrollSpeed() {
      this.setTime();
    },
    scroll: {
      handler() {
        this.setTime();
      },
    },
    dataChart() {
      setTimeout(() => {
        if (
          this.dataChart &&
          this.dataChart.length > 0 &&
          this.option.pageShow
        ) {
          this.total = this.dataChart[0]._total;
        }

        this.setTime();
      }, this.scrollTime);
    },
  },
  computed: {
    scrollTime() {
      return this.option.scrollTime;
    },
    scrollSpeed() {
      return this.option.scrollSpeed || 1;
    },
    scroll() {
      return this.option.scroll;
    },
    cellHeight() {
      return parseInt((this.height - this.headerHeight) / this.option.count);
    },
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    window.addEventListener("message", function (e) {
      this.tableDataSubData.push(e.data);
      console.log("自定义传值s", e);
    });
    this.getList();
    this.$nextTick(() => {
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(
        this.$refs.table.$refs.headerWrapper.clientHeight
      );
      // setTimeout(() => {
      //   this.setTime();
      // }, this.scrollTime)
    });
  },
  mounted() {},
  methods: {
    getList() {
      let _self = this;
      let query = window.location.search.substring(1);

      query = query.split("&");
      for (let i = 0; i < query.length; i++) {
        let ele = query[i];
        let pair = ele.split("=");
        if (pair[0] && pair[0] === "enterNum") {
          _self.enterNum = decodeURI(pair[1]);
          console.log(_self.enterNum, 444555666);
        }
        if (pair[0] && pair[0] === "orderId") {
          _self.orderId = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "storeId") {
          _self.storeId = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "id") {
          _self.id = decodeURI(pair[1]);
        }
        // if (pair[0] && pair[0] === 'Blade-Auth') {

        //   _self.bladeAuthInfo = decodeURI(pair[1])

        // }
        // if (pair[0] && pair[0] === 'Authorization') {

        //   _self.AuthorizationInfo = decodeURI(pair[1])

        // }
        // if (pair[0] && pair[0] === 'SwitchToCompanyId') {

        //   _self.SwitchToCompanyIdInfo = decodeURI(pair[1])

        // }
        // if (pair[0] && pair[0] === 'ignoreRequestDatas') {
        //       debugger

        //       const ignoreRequestDatasStr = decodeURIComponent(pair[1])
        //       _self.ignoreRequestDatas = JSON.parse(ignoreRequestDatasStr)
        //       _self.tableDataSubData = _self.ignoreRequestDatas
        //      console.log(_self.ignoreRequestDatas,888555222)

        //     }
      }
      //console.log(localStorage.getItem('ignoreRequestDatasInfo'),"**********************************")
      let params = {
        "Blade-Auth": localStorage.getItem("BladeAuth"),
        Authorization: localStorage.getItem("Authorization"),
        SwitchToCompanyId: localStorage.getItem("SwitchToCompanyId"),
        orderId: _self.orderId,
        storeId: _self.storeId,
        relationType: "2",
        modifyStatus: "1",
        mainRelationId: _self.id,
      };
      listwaitPickList(params, {
        queryMaterialStock: true,
        queryAvailableQuantity: true,
        queryFlowList: true,
      }).then((response) => {
        if (localStorage.getItem("two") == "two") {
          let _data = JSON.parse(localStorage.getItem("tableDataTotel")) || [];
          let batchMultipleSelection =
            JSON.parse(localStorage.getItem("batchMultipleSelection")) || [];

          if (batchMultipleSelection.length) {
            batchMultipleSelection.map((res) => {
              return { materielId: res.materielId, flowAmount: res.flowAmount };
            });

            // let totalCount = batchMultipleSelection.reduce((pre, item) => {
            //   return Number(pre) + Number(item.flowAmount);
            // }, 0);
            // let target = _data.find((item) => {
            //   return item.materielId == batchMultipleSelection[0].materielId;
            // });
            // target.amount = totalCount;
            
            let checkRow = JSON.parse(localStorage.getItem("checkRow"));

            // 单位转换
            this.tatals = 0
            let noTaxTotal = 0
            this.amounts = 0
            let locationName = "";
            batchMultipleSelection.forEach((item,index) => {
              noTaxTotal = accAdd(noTaxTotal, accMul(accDiv(item.outerPrice,(accAdd(1,accDiv(item.rate,100)))), item.flowAmount))
              this.tatals = accAdd(item.total,this.tatals)
              this.amounts = accAdd(item.amount,this.amounts)
              if(!locationName.match(item.materielLocationName)){
                if(index == 0){
                  locationName = item.materielLocationName
                } else {
                  locationName += "," + item.materielLocationName
                }
              }
            })

            let price = parseFloat(accDiv(Number(this.tatals) , Number(this.amounts)).toFixed(8))
            let noTaxPrice = parseFloat(accDiv(Number(noTaxTotal) , Number(this.amounts)).toFixed(8))
            let rateMoney = parseFloat(Number(accSub(this.tatals ,noTaxTotal)).toFixed(8))


            // 计算总价等
            _data.map((item) => {
              if (item.id == checkRow.id) {
                item.amount = this.amounts;
                item.noTaxTotal = parseFloat(noTaxTotal.toFixed(8))
                item.noTaxPrice = noTaxPrice
                item.rateMoney = rateMoney
                item.total = this.tatals
                item.price = price
                item.flowList =batchMultipleSelection
                item.locationName = locationName
              }
              return item;
            });

            _self.tableData = _data;
            _self.listwaitPickListCount();
          }
        } else {
          _self.tableData = response.data.data;
          localStorage.setItem(
            "multipleSelection",
            JSON.stringify(_self.tableData)
          );
        }
      });
    },
    calDetailData(scope) {
      let row = scope.row;
      let detailIndex = scope.$index;
      let amount = row.amount;
      if (amount > row.availableQuantity) {
        this.$message.warning("输入数量不能大于可用数量，已变更为为最大数量");
        this.tableData[detailIndex].amount = row.availableQuantity;
        amount = row.availableQuantity;
      }
      // 自动计算批次问题
      // 获取批次
      listwaitPickListCount(
        {
          materielId: row.materielId,
          storeId: scope.row.storeId,
          amount: amount,
        },
        this.bladeAuthInfo,
        this.AuthorizationInfo,
        this.SwitchToCompanyIdInfo
      ).then((response) => {
        this.tableData[detailIndex].total = response.data.data.total;
        this.tableData[detailIndex].price = response.data.data.price;
        this.tableData[detailIndex].noTaxTotal = response.data.data.noTaxTotal;
        this.tableData[detailIndex].noTaxPrice = response.data.data.noTaxPrice;
        this.tableData[detailIndex].rateMoney = response.data.data.rateMoney;
        this.tableData[detailIndex].flowList =
          response.data.data.innerBatchList;
        this.tableData[detailIndex].locationName =
          response.data.data.locationName;
      });

      //const urlNum = `${erpManUrl}/inner-stock/getCalculatedFlowBatchList?`
    },

    handleClick(row) {
      console.log(row, 987654321);
    },
    handleSelectionChange(val) {
      console.log(val, 123456798);
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.current = 1;
      this.size = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.updateData({ ...this.extentParam, current: this.current });
    },
    mouseEnter() {
      this.option.scroll = true;
      this.setTime();
    },
    mouseLeave() {
      this.option.scroll = false;
      clearInterval(this.scrollCheck);
    },
    cellClick(row, column, cell, event) {
      this.checkRow = row;
      this.updateClick(row);
      this.clickFormatter &&
        this.clickFormatter(
          {
            type: column,
            item: cell,
            row,
            data: this.dataChart,
          },
          this.getItemRefs()
        );
    },
    delClick(index, row) {
      row.splice(index, 1);
      localStorage.setItem("multipleSelection", JSON.stringify(this.tableData));
    },
    setTime() {
      if (this.option.count < this.dataChart.length) {
        clearInterval(this.scrollCheck);
        this.height = parseInt(this.$el.clientHeight);
        this.headerHeight = parseInt(
          this.$refs.table.$refs.headerWrapper.clientHeight
        );
        const table = this.$refs.table;
        const divData = table.bodyWrapper;
        const speed = this.scrollSpeed;
        let top = 0;
        if (this.scroll) {
          this.scrollCheck = setInterval(() => {
            top = top + speed;
            divData.scrollTop += speed;
            if (
              divData.clientHeight + divData.scrollTop ==
              divData.scrollHeight
            ) {
              divData.scrollTop = 0;
            }
            if (top >= this.cellHeight && this.scrollTime) {
              divData.scrollTop = divData.scrollTop - (top - this.cellHeight);
              clearInterval(this.scrollCheck);
              setTimeout(() => {
                this.setTime();
              }, this.scrollTime);
            }
          }, 20);
        } else {
          //divData.scrollTop = 0
        }
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign:
          column.type == "index" ? "center" : this.option.bodyTextAlign,
        backgroundColor:
          rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      };
    },
    rowStyle({ rowIndex }) {
      return {
        backgroundColor: "transparent",
      };
    },
    headerRowStyle() {
      return {
        backgroundColor: this.option.headerBackground,
      };
    },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign:
          column.type == "index" ? "center" : this.option.headerTextAlign,
      };
    },
  },
});
</script>
<style scoped lang='scss'>
/deep/ .el-pagination .btn-prev {
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-pagination .btn-next {
  padding-left: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-pager li.active {
  color: #409eff;
  cursor: default;
}

/deep/ .el-table {
  background-image: url(../../../../public/img/table_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-table th {
  background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-table td {
  background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-table tr {
  background-color: transparent !important;
}
/deep/ .el-table td {
  border-bottom: none !important;
}
/deep/ .el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/ .el-table {
  background-color: transparent !important;
}
/deep/ .el-table__expanded-cell {
  background-color: transparent !important;
}
/deep/ .el-input__inner {
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  border-color: transparent !important;
  background-color: transparent !important;
  color: #fff;
}
/deep/ .el-checkbox__inner {
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important;
}
/deep/ .el-table::before {
  display: none;
}
/deep/ .el-table__body-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 353px;
}
</style>


