<template>
  <div>
    <!-- 单个窗口 -->
    <div class="clapprs-container"
         v-if="reload">
      <!-- 使用窗口数amount遍历播放容器 -->
      <div v-for="(item,index) in amount"
           :key="item"
           ref="play"
           :class="{...showShade(item),...clapprsItem,'current-window': item == currentIndex}"
           style="background:#000"
           @click="handleClick(item)">
        <div :id="'play'+index">
        </div>
        <i class="close-icon"
           @click.stop="closePlay(item)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from '@/utils/utils'
import create from '../../create'
import EZUIKit from 'ezuikit-js'
import {
  getvideoPage,
  getProjectName,
  getplayVideoPage,
  getVideoConfigTokenInfo
} from '@/api/video'
export default create({
  name: 'yunClapprs',
  data() {
    return {
      hid: 'main_' + uuid(),
      reload: true,
      config: {},
      amount: 4,
      playList: [],
      urlList: [],
      containerList: [],
      currentIndex: '',
      token: '',
      player: [
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null }
      ]
    }
  },
  computed: {
    autoplay() {
      return this.option.autoplay
    },
    /* 遮罩样式 */
    showShade() {
      return function (item) {
        let res
        switch (this.amount) {
          case 1:
            if (!this.player[0].p) res = { shade1: true }
            break
          case 4:
            if (!this.player[item - 1].p) res = { shade4: true }
            break
          case 9:
            if (!this.player[item - 1].p) res = { shade9: true }
            break
          case 16:
            if (!this.player[item - 1].p) res = { shade16: true }
            break
        }
        return res
      }
    },
    /* 窗口样式 */
    clapprsItem() {
      let res
      switch (this.amount) {
        case 1:
          res = { 'clapprs-item1': true }
          break
        case 4:
          res = { 'clapprs-item4': true }
          break
        case 9:
          res = { 'clapprs-item9': true }
          break
        case 16:
          res = { 'clapprs-item16': true }
          break
      }
      return res
    }
  },
  watch: {},
  mounted() {
    let that = this
    this.getToken()
    // this.getProjectNameData()
    this.$bus.$on('changeAmount', function (params) {
      if (params && params.amount) that.changeWindow(params.amount)
    })
    this.$bus.$on('playMonitoring', function (params) {
      if (params.row) {
        that.handlePlay(params)
      }
    })
  },
  beforeDestroy() {
    this.$bus.$off('changeAmount')
    this.$bus.$off('playMonitoring')
  },
  methods: {
    async getToken() {
      const res = await getVideoConfigTokenInfo()
      if (res.data.code === 200) {
        this.token = res.data.data
        this.initPlayer()
      }
    },
    /* 获取项目名称 */
    async getProjectNameData() {
      const res = await getProjectName()
      if (res.data.code == 200) {
        this.projectName = res.data.data.simple
        this.initPlayer()
      }
    },
    /* 初始化时加载4窗口的画面 */
    async initPlayer() {
      // 获取div的宽高
      let width = this.$refs.play[0].offsetWidth
      let height = this.$refs.play[0].offsetHeight
      const res = await getplayVideoPage({
        projectId: this.$route.query.projectId
      })
      if (res.data.code == 200) {
        // 获取监控播放地址url数组
        const urlList = []
        var idx = 0
        res.data.data.forEach((item,index) => {
            if (item.equipmentCategory == 0) {
                var subList = item.videoInfoVOList
                subList.forEach((subItem, subIndex) => {
                    urlList.push({i:idx+1,name:subItem.channelName,nvrDeviceSerial:subItem.nvrDeviceSerial,channelNo:subItem.channelNo})
                    idx = idx + 1
                })
            } else {
                urlList.push({i:idx+1,name:item.deviceName,nvrDeviceSerial:item.deviceSerial,channelNo:1})
                idx = idx + 1
            }
  
        })
        // 遍历url数组，播放4个窗口画面
        urlList.forEach((item, index) => {
          const player = new EZUIKit.EZUIKitPlayer({
            id: 'play' + index,
            url: `ezopen://open.ys7.com/${item.nvrDeviceSerial}/${item.channelNo}.live`,
            accessToken: this.token,
            template: 'pcLive',
            height,
            width
          })
          this.player[index].p = player
        })
        this.urlList = urlList
      }
    },
    /* 切换窗口个数 */
    changeWindow(amount) {
      // 处理 playList 后再给this.amount赋值
      this.amount = amount
      this.$nextTick(() => {
        // 获取div的宽高
        let width = this.$refs.play[0].offsetWidth
        let height = this.$refs.play[0].offsetHeight
        let _self = this
        // 每次切换窗口个数时，保证playList的长度等于窗口个数，没有play对象的位置使用null填充
        for (let index = 0; index < amount; index++) {
          const player = new EZUIKit.EZUIKitPlayer({
            id: 'play' + index,
            url: `ezopen://open.ys7.com/${_self.urlList[index].nvrDeviceSerial}/${_self.urlList[index].channelNo}.live`,
            accessToken: _self.token,
            template: 'pcLive',
            height,
            width
          })
          _self.player[index].p = player
        }
      })
    },
    /* 点击左侧列表执行播放操作 */
    handlePlay(params) {
      // // 初始化未选中窗口时，点击列表某个监控，给this.currentIndex赋1
      if (!this.currentIndex) {
        this.$message.warning('请选择播放窗口')
      }
      let width = this.$refs.play[0].offsetWidth
      let height = this.$refs.play[0].offsetHeight
      const player = new EZUIKit.EZUIKitPlayer({
        id: 'play' + (this.currentIndex - 1),
        url: `ezopen://open.ys7.com/${params.row.nvrDeviceSerial}/${params.row.channelNo}.live`,
        accessToken: this.token,
        template: 'pcLive',
        height,
        width
      })
      this.player[this.currentIndex - 1].p = player
    },
    /* 点击某一个播放窗口 */
    handleClick(index) {
      // index 从1开始
      if (this.currentIndex == index) {
        this.currentIndex = ''
      } else {
        this.currentIndex = index
      }
    },
    /* 关闭播放 */
    closePlay(index) {
      this.player[index - 1].p.stop()
      this.player[index - 1].p = null
    }
  }
})
</script>
<style scoped lang='scss'>
.current-window {
  border: 2px solid #419de6;
}

.clapprs-container {
  display: flex;
  flex-wrap: wrap;
  width: 1428px;
  height: 888px;
  // 单窗口样式
  .clapprs-item1 {
    position: relative;
    width: 1415px !important;
    height: 890px !important;
  }
  .shade1 {
    &::before {
      content: '';
      position: absolute;
      z-index: 1999;
      top: 0px;
      left: 0px;
      width: 1415px;
      height: 890px;
      background-color: #000;
    }
  }

  // 4窗口样式
  .clapprs-item4 {
    position: relative;
    width: 702px !important;
    height: 437px !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .shade4 {
    &::before {
      content: '';
      position: absolute;
      z-index: 1999;
      top: 0px;
      left: 0px;
      width: 702px;
      height: 437px;
      background-color: #000;
    }
  }

  // 9窗口样式
  .clapprs-item9 {
    position: relative;
    width: 464px !important;
    height: 284px !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .shade9 {
    &::before {
      content: '';
      position: absolute;
      z-index: 1999;
      top: 0px;
      left: 0px;
      width: 464px;
      height: 284px;
      background-color: #000;
    }
  }
  // 16窗口样式
  .clapprs-item16 {
    position: relative;
    width: 346px !important;
    height: 215px !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .shade16 {
    &::before {
      content: '';
      position: absolute;
      z-index: 1999;
      top: 0px;
      left: 0px;
      width: 346px;
      height: 215px;
      background-color: #000;
    }
  }
}
// 关闭按钮样式
.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  z-index: 11;
  &:hover {
    background: url('/img/source/win-close.png') center 0 no-repeat;
    background-size: 100% 100%;
  }
}
/deep/ .player-poster {
  z-index: unset !important;
}
/* 播放视频撑满窗口 */
/deep/ video[data-html5-video] {
  padding: 2px;
  border: 2px solid #032f80;
  object-fit: cover;
  bject-position: center center;
}
::v-deep .top {
  padding: unser !important;
}
</style>



