<template>
  <div :class="b()" class="input-custom-content" ref="main">
<!--    <el-autocomplete
      v-model="state"
      :fetch-suggestions="querySearchAsync"
      placeholder="请输入内容"
      @select="handleSelect"
    ></el-autocomplete> -->
	<el-select v-model="value" clearable filterable placeholder="请选择" @change="handleSelect">
	    <el-option
	      v-for="item in this.dataChart"
	      :key="item.value"
	      :label="item.label"
	      :value="item.value">
	    </el-option>
	  </el-select>
  </div>
</template>

<script>
import create from "../../create";
export default create({
	data() {
	  return {
		value: null,
	  };
	},
  name: "inputSearch",
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
	methods: {
	      
	      handleSelect(item) {
			// this.state = item.value
			this.value = item
	        console.log(item);
	      }
	    },
	    mounted() {
			
	    }

});
</script>

<style scope lang="scss">
    .el-tree {
        background: unset;
        color: #fff;
        /deep/ .el-tree-node__content  {
            background-color: unset;
        }
    }
</style>
<style scope>
    .el-tree-node:focus>.el-tree-node__content {
        background-color: unset;
    }
    .el-tree-node.is-current>.el-tree-node__content {
        background-color: unset;
    }
    .el-tree-node__content:hover {
        background-color: unset;
    }
    .el-tree-node__label {
        font-size: 14px;
    }
</style>
<style>
.input-custom-content .el-input__inner {
    background-color: #1A245A !important;
    border: 1px solid #1A245A !important;
}
.input-custom-content .el-select {
    border: 1px solid rgb(7, 219, 255) !important;;
}
</style>


