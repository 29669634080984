<template>
  <div
    class="border3"
    :style="option.height != '' ? 'height:' + option.height : ''"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    option: {
      type: Object,
      default: () => {
        return { height: "100%" };
      },
    },
  },
};
</script>

<style lang='scss' scoped>
.border3 {
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba(180, 181, 198, 0.1);
  background-clip: padding-box;
  opacity: 1;
  filter: blur(0px);
  border-image-source: url(/img/border/border5.png);
  border-image-slice: 10 10 10 10 fill;
  border-width: 10px;
  border-style: solid;
  box-sizing: border-box;

  .echart-img-item {
    background: url("/img/source/source23.svg") center 0 no-repeat;
    width: 140px;
    height: 40px;
    margin: 0 auto;
    padding-bottom: 5px;

    .echart-text {
      .echart-text__box {
        text-align: center;

        .echart-text__text {
          width: auto;
          transform: translateX(0px);
          letter-spacing: 0px;
          text-indent: 0px;
          font-weight: normal;
          font-size: 16px;
          line-height: 40px;
          color: rgb(255, 255, 255);
        }
      }
    }
  }
}
</style>
