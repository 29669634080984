<template>
  <div class="cus-datepicker-container">
    <el-date-picker v-model="dateVal"
                    prefix-icon="none"
                    :size="option.datePickerSize"
                    type="monthrange"
                    :clearable="false"
                    :format="option.format"
                    :value-format="option.dateParamFormat"
                    popper-class="layoutPopperClass"
                    @change="dateChange"
                    range-separator="至"
                    :unlink-panels="true"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份"
                    placeholder="请选择"
					 :picker-options="pickerOptions"
                    class="date-style">
    </el-date-picker>
  </div>
</template>

<script>
/**
@description: 日期选择器
@createTime: 2022-05-10
@create: lixu
*/
import create from '../../create'
export default create({
  name: 'datePicker',
  props: {
      option: Object,
      component: Object
  },
  components: {},
  data() {
	let minTime = null;
	let maxTime = null;
    return {
      dateVal: [],
	  pickerOptions: {
		  disabledDate(time) {
			if (minTime) {
			  return time.getTime() < minTime || time.getTime() > maxTime;
			}
		  },
		  onPick(time) {
			  // 选择了两个时间放开删除限制
			if (time.maxDate && time.minDate) {
			  minTime = null;
			  maxTime = null;
			// 如果选择了一个时间
			} else if (!time.maxDate) {
			  // 31104000000是一年的时间戳  2592000000是一个月的时间戳
			  minTime = time.minDate.getTime() - 31104000000 + 2592000000;
			  maxTime = time.minDate.getTime() + 31104000000;
			} else {
			  // 放开限制，方便再次选择
			  minTime = null;
			  maxTime = null;
			}
		  }
		}
    }
  },
  computed: {},
  watch: {
    dateVal: {
      handler(val,val1) {
		if (!val || val.length == 0 || val.length>0&&!val[0]) {
		  this.handleClick()
		}
      },
      deep: true
    },
  },
  mounted() {
	  this.$bus.$on('changeDate', (data) => {
		  if(data){
			var parts = data.split("-");
			var year = parseInt(parts[0]); // 将年份转换为整数
			var month = parseInt(parts[1]); // 将月份转换为整数
			var newMonth = month - 6;
			var newYear = year;
			if (newMonth <= 0) {
				newMonth += 12; // 加上12个月，相当于向前借一年
				newYear--; // 年份减一
			}
			var newMonthString = newYear + "-" + (newMonth < 10 ? "0" : "") + newMonth;
			var oldMonthString = year + "-" + (month < 10 ? "0" : "") + month;
			let rangeMonthList = [newMonthString,oldMonthString]
			localStorage.setItem('changeDate', rangeMonthList.join(', '));
		  }
	  })
      let dateSection = this.option.datePickerDefaultSection;
	  let changeDate = localStorage.getItem('changeDate')
	  if(changeDate == null){
		  if(dateSection == 'year') {
		      //一年
		      // let yearAgo = moment().subtract(11, 'month').format('yyyy-MM')
		      // this.dateVal = [yearAgo, moment().format('yyyy-MM')]
			  let halfYearAgo = moment().subtract(5, 'month').format('yyyy-MM')
			  this.dateVal = [halfYearAgo, moment().format('yyyy-MM')]
		  } else {
		      //半年
		      //let halfYearAgo = moment().subtract(5, 'month').format('yyyy-MM')
		      this.dateVal = ["",""]
		  }
	  }else{
		  let changeRange = changeDate.split(', ');
		  this.dateVal = changeRange
	  }
	  this.dateTimeRangeUpdateClick({value: this.dateVal});
  },
  updated() {
	  
  },
  methods: {
    handleClick() {
        let a = this.dateVal;
        this.dateTimeRangeUpdateClick({value: this.dateVal});
      // this.dateUpdateClick({
      //   value: this.dateVal
      // })
      this.clickFormatter &&
        this.clickFormatter(
          {
            data: this.dateVal
          },
          this.getItemRefs()
        )
    },
    /* 日期选择器选定日期 */
    dateChange() {
      this.handleClick()
	  localStorage.removeItem('changeDate');
    },
  }
})
</script>
<style scoped lang='scss'>
/deep/.input {
  background-color: unset !important;
}
/deep/ .el-input__inner {
  /*background: -webkit-linear-gradient(*/
    /*top left,*/
    /*rgba(0, 25, 83, 0.8),*/
    /*rgba(0, 3, 38, 0.8)*/
  /*);*/
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  /*border: 1px solid #2580dc !important;*/
  border: 1px solid transparent !important;
  color: #fff !important;
  width: 300px;
  .el-range-input {
    /*background: unset !important ;*/
    /*background: -webkit-linear-gradient(*/
                    /*top left,*/
                    /*rgba(0, 25, 83, 0.8),*/
                    /*rgba(0, 3, 38, 0.8)*/
    /*);*/
    background-color: unset !important;
    background-image: url("../../../assets/Select-box.png") !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #c0c4cc !important;
    width: 45%;
  }
  .el-range-separator {
    color: #fff;
    height: unset;
  }
  .el-range__close-icon {
    width: 0;
  }
}
.date-style {
  width: 100%;
  height: 100%;
  /deep/.input {
    background-color: unset !important;
  }
  /deep/ .el-input__inner {
    background-color: #0b2255 !important;
  }
}
.layoutPopperClass {
  /deep/ .input {
    background-color: unset !important;
  }
}
.select-style {
  // border: 1px solid #2580dc !important;
  height: 32px !important;
  margin-right: 15px;
  /deep/ .input {
    background-color: unset !important;
  }
  /deep/ .el-input__inner {
    width: 105px !important;
    height: 32px;
    border-color: #07DBFF !important; // 取消蓝边框
    background-color: #0b2255 !important;
  }
  /deep/ .el-input__icon {
    line-height: 29px !important;
  }
}
</style>
<style lang='scss'>
.cus-datepicker-container {
  display: flex;
}
.cus-datepicker-container input {
  background-color: transparent !important;
}
// bim日期下拉样式
.layoutPopperClass {
  /deep/ .el-input__inner {
    background-color: #0b2255 !important;
  }
  background: -webkit-linear-gradient(
    top left,
    rgba(0, 25, 83, 0.8),
    rgba(0, 3, 38, 0.8)
  );
  border: 1px solid #2580dc;
  .el-date-range-picker__header {
    color: #c0c4cc;
    .el-date-picker__header-label {
      color: #c0c4cc;
    }
    .el-picker-panel__icon-btn {
      color: #c0c4cc !important;
    }
  }
  .el-date-table {
    .el-date-table__row {
      .in-range {
        div {
          background-color: #4978a9 !important;
        }
      }
    }
  }
  .el-month-table {
    .in-range {
      div
      {
        background-color: #4978a9 !important;
      }
    }
  }
  .el-picker-panel__content {
    .el-date-table {
      tr {
        th {
          color: #c0c4cc !important;
        }
      }
      .el-date-table__row {
        .prev-month {
          color: #fff !important;
        }
        .available {
          color: #c0c4cc !important;
        }
      }
    }
  }
  .el-picker-panel__footer {
    .popper__arrow {
      background-color: #1dd1f2 !important;
    }
  }
  .input {
    background-color: unset !important;
  }
  .el-range-input {
    background: unset !important;
    width: 45%;
  }
}
</style>