<template>
	<div :class="b()" :style="{fontSize:fontSize,color:'#fff'}">
		<div class="chart-content flex-center">
			<div :ref="id" :id="mychart" :style="myChartStyle"></div>
			<div class="chart-top flex-center">
				<div class="chart-data" v-for="(item,index) in chartName">{{item}}: 
					<el-tooltip effect="dark" :content="chartData[index]" placement="top-start">
						<span>{{chartData[index] | ellipsis | getZero}}</span>
					</el-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import create from "@/echart/create";
	import { uuid } from '@/utils/utils';
	export default create({
		name: 'customPieTransverse',
		props: {
		  option: Object,
		  component: Object
		},
		data() {
			return {
				mychart:'mychart_' + uuid(),
				//下列都是配置在请求过来的dataChart中的
				chartName:[],//图例显示的名称数组
				chartData:[],//图例显示的内容数组
				chartPie:[],//设置饼图环形数组，没有就是饼图
				isChartPercent:false,//饼图label是否显示百分比
				isRoseType:'',//是否南丁格尔
				clickType:0,//点击类型，0为没有点击穿透
				clickData:{},
				myChartStyle: { width: "400px", height: "180px" }, //图表样式
			}
		},
		filters:{
			ellipsis(value){
				if (!value) return '';
				if (value.length > 10) {
					return value.slice(0,10) + '...'
				}
				return value
			},
			getZero(value){
				if (!value) {
					return 0
				}
				return value
			}
		},
		computed: {
			
		},
		mounted(){
			this.$bus.$on('clickData',(data)=>{
				this.clickData = data
			})
		},
		beforeDestroy(){
			this.$bus.$off('clickData')
		},
		methods:{
			updateChart(){
				this.getDetail()//先初始化一个信息
				const optionData = this.deepClone(this.dataChart.obj)
				this.chartName = this.dataChart.name
				this.chartPie = this.dataChart.pieStyle
				this.isChartPercent = this.dataChart.isChartPercent
				this.isRoseType = this.dataChart.isRoseType
				this.clickType = this.dataChart.clickType
				
				let emphasis = { //高亮的扇区和标签样式。
				    shadowBlur: 10,
				    shadowOffsetX: 0,
				    shadowColor: 'rgba(0, 0, 0, 0.5)',
					labelLine:{
						show:true,
						lineStyle: {
							color: '#999', // 设置标示线颜色
							width: 2 // 设置标示线宽度
						}
					},
					label:{
						formatter: (params) => {
							if(this.isChartPercent){
								if (params.name !== '') {
									let name = ''
									if (params.name.length > 7) {
										name = params.name.slice(0,7) + '...'
									}else{
										name = params.name
									}
									return `${name}` + `{${params.percent === undefined ? 0 : params.percent}%}`
								}
							}
							return `${params.name}`
						},
						show:true
					},
				}
				var option = MyEcharts.EchartsOption.pie("", "", optionData,this.chartPie,'');
				option.legend.show = false
				option.tooltip.show = false
				option.series[0].labelLine.show = false
				option.series[0].label.show = false
				option.series[0].emphasis = emphasis
				option.series[0].roseType = this.isRoseType
				let mychart = MyEcharts.initChart(option, this.mychart);
				if(Array.isArray(optionData) && optionData.length === 0){
					mychart.clear(); 
				}
				mychart.dispatchAction({
				  type: 'highlight',
				  seriesIndex: 0, // 图表中的第一个系列
				  dataIndex: 0, // 要高亮的数据项的索引
				});
				// 监听鼠标滑入事件
				mychart.on('mouseover', (e) => {
					this.getDetail(e)
					// 鼠标滑入取消整个系列高亮
					mychart.dispatchAction({
						type: 'downplay',
						seriesIndex: e.seriesIndex
					})
					// 然后高亮鼠标悬停的那块
					mychart.dispatchAction({
						type: 'highlight',
						dataIndex: e.dataIndex
					})
				})
				
				// 监听鼠标滑出事件
				mychart.on('mouseout', (e) => {
					// 鼠标滑出取消整个系列所有高亮
					mychart.dispatchAction({
						type: 'downplay',
						seriesIndex: e.seriesIndex
					})
					// 然后高亮鼠标最后悬停的那块
					mychart.dispatchAction({
						type: 'highlight',
						dataIndex: e.dataIndex
					})
				})
				mychart.on('click', (e) => {//点击事件用this.clickData的type区分
					var year = '2023'
					var date = new Date()
					year = date.getFullYear()
					var month = ''
					if(this.clickData.type==="year"){
						year = this.clickData.data
					}
					if(this.clickData.type==="month"){
						month = this.clickData.data
					}
					let targetIndex = e.dataIndex
					if(this.clickType===1){
						let materialTypeId = optionData[targetIndex].materialTypeId
						this.$bus.$emit("showDialog2", {data: {is6j: true, id: "1554758927290363906",params:{materialTypeId:materialTypeId,month:month,year:year}, width: 1201, height: 762}})
					}
					if(this.clickType===2){
						let supplierId = optionData[targetIndex].supplierId
						this.$bus.$emit("showDialog2", {data: {is6j: true, id: "1554759232446951425",params:{supplierId:supplierId,month:month,year:year}, width: 1201, height: 762}})
					}
					if(this.clickType===3){
						switch(e.name) {
						    case '人工费':
						        this.$bus.$emit("showDialog2", {data: { is6j:true,id: "1610194780602654721",params:{}, width: 1201, height: 762}})
						    break;
						    case '机械费':
						        this.$bus.$emit("showDialog2", {data: { is6j:true,id: "1610195473572978689",params:{}, width: 1201, height: 762}})
						    break;
						    case '管理费':
						        this.$bus.$emit("showDialog2", {data: { is6j:true,id: "1610196367748259841",params:{}, width: 1201, height: 762}})
						    break;
						    case '材料费':
						        this.$bus.$emit("showDialog2", {data: { is6j:true,id: "1610196014092935170",params:{}, width: 1201, height: 762}})
						    break;
						    case '其它费用':
						        this.$bus.$emit("showDialog2", {data: { is6j:true,id: "1610182695168663554",params:{}, width: 1201, height: 762}})
						    break;
						} 
					}
					if(this.clickType===4){
						if(e.name == "20岁以下"){
							this.$bus.$emit("showDialog2", {data: {is6j: true,params:{level:1,name:e.name}, id: "1554724794371567617", width: 1202, height: 662}})
						}
						if(e.name == "20-30岁"){
							this.$bus.$emit("showDialog2", {data: {is6j: true,params:{level:2,name:e.name}, id: "1554724794371567617", width: 1202, height: 662}})
						}
						if(e.name == "30-40岁"){
							this.$bus.$emit("showDialog2", {data: {is6j: true,params:{level:3,name:e.name}, id: "1554724794371567617", width: 1202, height: 662}})
						}
						if(e.name == "40-50岁"){
							this.$bus.$emit("showDialog2", {data: {is6j: true,params:{level:4,name:e.name}, id: "1554724794371567617", width: 1202, height: 662}})
						}
						if(e.name == "50岁以上"){
							this.$bus.$emit("showDialog2", {data: {is6j: true,params:{level:5,name:e.name}, id: "1554724794371567617", width: 1202, height: 662}})
						}
					}
					if(this.clickType===5){
						let level = ""
						let name = e.name
						if(e.name == "一般"){
							level = 1
						}
						if(e.name == "正常"){
							level = 2
						}
						if(e.name == "紧急"){
							level = 3
						}
						if(e.name == "严重"){
							level = 4
						}
						this.$bus.$emit("showDialog2", {data: {is6j: true, id: "1554699010621730818",params:{level:level,name:name,year:year,month:month}, width: 1201, height: 762}})
					}
					if(this.clickType===6){
						let name = e.name
						let result = ""
						if(e.name == "合格"){
							result = 0
							this.$bus.$emit("showDialog2", {data: {is6j: true, id: "1554700014532587522",params:{result:result,name:name,year:year,month:month}, width: 1201, height: 762}})
						}
						if(e.name == "现场整改"){
							result = 2
							this.$bus.$emit("showDialog2", {data: {is6j: true, id: "1565570789946843138",params:{result:result,name:name,year:year,month:month}, width: 1201, height: 762}})
						}
						if(e.name == "后续整改"){
							result = 1
							this.$bus.$emit("showDialog2", {data: {is6j: true, id: "1565535515372052481",params:{result:result,name:name,year:year,month:month}, width: 1201, height: 762}})
						}
					}
					if(this.clickType===7){
						let classId = ''
						let data = e.data
						for(let key in data){
							if(data[key] == e.name){
								classId = optionData[targetIndex].classId
							}
						}
						this.$bus.$emit("showDialog", {data: {is6j: true, id: "1659368761422557186",params:{classId}, width: 1201, height: 762}})
					}
					if(this.clickType===8){
						if(e.name == "设备租赁合同"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{contractType:6}, id: "1536181341480185858", width: 1201, height: 762}})
						}
						if(e.name == "劳务分包合同"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{contractType:3}, id: "1536181341480185858", width: 1201, height: 762}})
						}
						if(e.name == "周材租赁合同"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{contractType:5}, id: "1536181341480185858", width: 1201, height: 762}})
						}
						if(e.name == "物资采购合同"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{contractType:4}, id: "1536181341480185858", width: 1201, height: 762}})
						}
						if(e.name == "其它支出合同"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{contractType:7}, id: "1536181341480185858", width: 1201, height: 762}})
						}
						if(e.name == "专业分包合同"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{contractType:2}, id: "1536181341480185858", width: 1201, height: 762}})
						}
					}
					if(this.clickType===9){
						if(e.name == "20岁以下"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{level:1}, id: "1536248532728705026", width: 1202, height: 662}})
						}
						if(e.name == "20-30岁"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{level:2}, id: "1536248532728705026", width: 1202, height: 662}})
						}
						if(e.name == "30-40岁"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{level:3}, id: "1536248532728705026", width: 1202, height: 662}})
						}
						if(e.name == "40-50岁"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{level:4}, id: "1536248532728705026", width: 1202, height: 662}})
						}
						if(e.name == "50岁以上"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{level:5}, id: "1536248532728705026", width: 1202, height: 662}})
						}
					}
					if(this.clickType===10){
						if(e.name == "小学"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"01"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "初中"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"02"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "高中"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"03"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "中专"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"04"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "大专"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"05"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "本科"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"06"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "硕士"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"07"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "博士"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"08"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
						if(e.name == "其他"){
							this.$bus.$emit("showDialog", {data: {is6j: true,params:{educateLevel:"99"}, id: "1536252280377077762", width: 1202, height: 662}})
						}
					}
					if(this.clickType===11){
						this.$bus.$emit("showDialog", {data: {is6j: true,params:{typeName:e.name}, id: "1536235750104920066", width: 1201, height: 762}})
					}
					let time = '3'
					if(this.clickData.type==="time"){
						time = this.clickData.data
					}
					if(this.clickType===12){
						let method = ""
						if(e.name == "一般"){
							method = 1
						}
						if(e.name == "正常"){
							method = 2
						}
						if(e.name == "紧急"){
							method = 3
						}
						if(e.name == "严重"){
							method = 4
						}
						this.$bus.$emit("showDialog", {data: {is6j: true, id: "1541682662916386818",params:{type:time,method:method}, width: 1201, height: 762}})
					}
					if(this.clickType===13){
						let method = ""
						if(e.name == "合格"){
							method = 0
							this.$bus.$emit("showDialog", {data: {is6j: true, id: "1541690727740895233",params:{type:time,method:method}, width: 1201, height: 762}})
						}
						if(e.name == "现场整改"){
							method = 2
							this.$bus.$emit("showDialog", {data: {is6j: true, id: "1541690727740895233",params:{type:time,method:method}, width: 1201, height: 762}})
						}
						if(e.name == "后续整改"){
							method = 1
							this.$bus.$emit("showDialog", {data: {is6j: true, id: "1545285352019881986",params:{type:time,method:method}, width: 1201, height: 762}})
						}
					}
				})
				window.addEventListener("resize", () => {
				    mychart.resize();
				});
				
			},
			getDetail(params){
				this.chartData = []
				let dict
				if(params){
					dict = this.dataChart.obj[params.dataIndex]
				}else{
					dict = this.dataChart.obj[0]
				}
				for(let key in dict){
					this.chartData.push(dict[key])
				}
			},
		}
	});
</script>

<style scoped lang="scss">
	.chart-content{
		flex-direction: row;
		width: 100%;
		height: 100%;
	}
	.chart-top{
		width: 30%;
		height: 100%;
		background: url("../../assets/u7766.png") no-repeat;
		background-size: 100% 100%;
		flex-direction: column;
	}
	.chart-data{
		margin: 10px;
		font-size: 16px;
		span{
			color: #1bcaff;
		}
	}
	.flex-center{
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>