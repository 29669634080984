<template>
  <div class="bim">
    <iframe :src="url" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>
<script>
export default {
  // BIM看板组件
  name: "test2",
  props: {
    option: Object,
    component: Object,
  },
  computed: {
    fontSize() {
      return (this.option.fontSize || 30) + "px";
    },
  },
  mounted() {
    let urlCode = myRequest.get_local_cache("urlCode");
    if (urlCode.server.indexOf("/admin") === -1) {
      urlCode.server = urlCode.server + "/admin";
    }
    this.url =
      this.sandBoxUrl +
      "?server=" +
      urlCode.server +
      "&uuid=" +
      urlCode.uuid +
      "&projectId=" +
      urlCode.projectId +
      "&sid=" +
      urlCode.sid;
  },
};
</script>
<style lang='scss' scoped>
.bim {
  width: 1920px;
  height: 100%;
}
</style>