//基础公共url
var baseUrl = '.'
//基础组件库
export default [{
    "label": '图表',
    "icon": 'icon-bar',
    "children": [{
        "label": '通用型',
        "option": {
            "name": "通用型",
            "title": "通用型",
            "icon": 'icon-bar',
            "img": require('@/assets/text5.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "data": {
                "categories": [
                    "苹果",
                    "三星",
                    "小米",
                    "oppo",
                    "vivo"
                ],
                "series": [{
                    "name": "手机品牌",
                    "data": [
                        1000879,
                        3400879,
                        2300879,
                        5400879,
                        3400879
                    ]
                }]
            },
            "echartFormatter": "(data)=>{\nconsole.log(data);\nreturn {\n            title: {\n\t\t\t\t textStyle: {\n\t\t\t\t\tfontWeight: 'normal',      \n\t\t\t\t\tcolor: '#fff'\n\t\t\t\t},\n                text: 'ECharts 入门示例'\n            },\n            tooltip: {},\n            legend: {\n                data:['销量'],\n\t\t\t\ttextStyle: {\n\t\t\t\t\tfontWeight: 'normal',      \n\t\t\t\t\tcolor: '#fff'\n\t\t\t\t},\n            },\n            xAxis: {\n                data: [\'衬衫\',\'羊毛衫\',\'雪纺衫\',\'裤子\',\'高跟鞋\',\'袜子\']\n            },\n            yAxis: {},\n            series: [{\n                name: '销量',\n                type: 'bar',\n                data: [5, 20, 36, 10, 10, 20]\n            }]\n        };\n}",
            "dataFormatter": "",
            "stylesFormatter": "",
            "url": baseUrl + "/bar",
            "time": 5000,
            "component": {
                "width": 600,
                "height": 300,
                "name": "common",
                "prop": "common",
            },
        }
    }, {
        label: '1实时天气模块',
        option: {
            "name": "1实时天气模块",
            "title": "1实时天气模块",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 800,
                "height": 500,
                "name": "test1",
                "prop": "test1",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '2BIM看板模块',
        option: {
            "name": "2BIM看板模块",
            "title": "2BIM看板模块",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 800,
                "height": 500,
                "name": "test2",
                "prop": "test2",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '3副导航组件',
        option: {
            "name": "3副导航组件",
            "title": "3副导航组件",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 600,
                "height": 50,
                "name": "test3",
                "prop": "test3",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '4主导航组件',
        option: {
            "name": "4主导航组件",
            "title": "4主导航组件",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 650,
                "height": 50,
                "name": "test4",
                "prop": "test4",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '5塔机监测页组件',
        option: {
            "name": "5塔机监测页组件",
            "title": "5塔机监测页组件",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1400,
                "height": 930,
                "name": "test5",
                "prop": "test5",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '6卸料平台监测页组件',
        option: {
            "name": "6卸料平台监测页组件",
            "title": "6卸料平台监测页组件",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1400,
                "height": 644,
                "name": "test6",
                "prop": "test6",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '7升降机监测页组件',
        option: {
            "name": "7升降机监测页组件",
            "title": "7升降机监测页组件",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1400,
                "height": 930,
                "name": "test7",
                "prop": "test7",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '8材料价格趋势',
        option: {
            "name": "8材料价格趋势",
            "title": "8材料价格趋势",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1184,
                "height": 306,
                "name": "test8",
                "prop": "test8",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '9单表分析折线图',
        option: {
            "name": "9单表分析折线图",
            "title": "9单表分析折线图",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1900,
                "height": 220,
                "name": "test9",
                "prop": "test9",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '10视频监控页',
        option: {
            "name": "10视频监控页",
            "title": "10视频监控页",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1900,
                "height": 930,
                "name": "test10",
                "prop": "test10",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: '视频监控页-展厅',
        option: {
            "name": "视频监控页-展厅",
            "title": "视频监控页-展厅",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1900,
                "height": 950,
                "name": "videoHall",
                "prop": "videoHall",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        label: 'AI视频监控',
        option: {
            "name": "AI视频监控",
            "title": "AI视频监控",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1376,
                "height": 650,
                "name": "videoHallAI",
                "prop": "videoHallAI",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },{
        label: '安全帽地图',
        option: {
            "name": "安全帽地图",
            "title": "安全帽地图",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1376,
                "height": 650,
                "name": "customAMap",
                "prop": "customAMap",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },{
        label: '监控2x2',
        option: {
            "name": "监控2x2",
            "title": "监控2x2",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1376,
                "height": 650,
                "name": "dailogVideoTwo",
                "prop": "dailogVideoTwo",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },{
        label: '监控3x3',
        option: {
            "name": "监控3x3",
            "title": "监控3x3",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 1376,
                "height": 650,
                "name": "dailogVideoThree",
                "prop": "dailogVideoThree",
            },
            "option": {
                "fontSize": 20,
            }
        }
    },
    {
        "label": 'datav',
        "option": {
            "name": "datav",
            "title": "datav",
            "icon": 'icon-bar',
            "img": require('@/assets/text6.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "data": {
                "categories": [
                    "苹果",
                    "三星",
                    "小米",
                    "oppo",
                    "vivo"
                ],
                "series": [{
                    "name": "手机品牌",
                    "data": [
                        1000879,
                        3400879,
                        2300879,
                        5400879,
                        3400879
                    ]
                }]
            },
            "dataFormatter": "",
            "echartFormatter": "(data)=>{\n  console.log(data);\n  return {\n    config:{\n\t  data: [66] \n\t}\n  }\n}",
            "url": baseUrl + "/bar",
            "time": 5000,
            "component": {
                "width": 150,
                "height": 200,
                "name": "datav",
                "prop": "datav",
            },
            "option": {
                "is": 'dv-water-level-pond'
            }
        }
    }, {
        "label": '柱形图',
        "option": {
            "name": "柱状图",
            "title": "柱状图",
            "icon": 'icon-bar',
            "img": require('@/assets/bar.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "data": {
                "categories": [
                    "苹果",
                    "三星",
                    "小米",
                    "oppo",
                    "vivo"
                ],
                "series": [{
                    "name": "手机品牌",
                    "data": [
                        1000879,
                        3400879,
                        2300879,
                        5400879,
                        3400879
                    ]
                }]
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "url": baseUrl + "/bar",
            "time": 5000,
            "component": {
                "width": 600,
                "height": 300,
                "name": "bar",
                "prop": "bar",
            },
            "option": {
                "gridX": 90,
                "gridY": 0,
                "gridX2": 0,
                "gridY2": 0,
                "legend": true,
                "nameColor": "#eee",
                "lineColor": "#eee",
                "xNameFontSize": 16,
                "yNameFontSize": 16,
                "labelShow": true,
                "labelShowColor": '#fff',
                "labelShowFontSize": 14,
                "labelShowFontWeight": 300,
                "yAxisInverse": false,
                "xAxisInverse": false,
                "xAxisShow": true,
                "yAxisShow": true,
                "xAxisSplitLineShow": false,
                "yAxisSplitLineShow": false,
                "barWidth": 30,
                "barRadius": 8,
                "barColor": [{
                    "color1": "#83bff6",
                    "color2": "#188df0",
                    "postion": 90,
                    "$index": 0,
                    "_show": true
                }, {
                    "color1": "#23B7E5",
                    "color2": "#564AA3",
                    "postion": 50,
                    "$index": 1,
                    "_show": true
                }],
                "barMinHeight": 2
            }
        }
    }, {
        "label": '折线图',
        "option": {
            "name": "折线图",
            "title": "折线图",
            "icon": 'icon-line',
            "img": require('@/assets/line.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "data": {
                "categories": [
                    "苹果",
                    "三星",
                    "小米",
                    "oppo",
                    "vivo"
                ],
                "series": [{
                    "name": "手机品牌",
                    "data": [
                        1000879,
                        3400879,
                        2300879,
                        5400879,
                        3400879
                    ]
                }]
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "url": baseUrl + "/bar",
            "time": 5000,
            "component": {
                "width": 600,
                "height": 300,
                "name": "line",
                "prop": "line",
            },
            "option": {
                "gridX": 90,
                "gridY": 0,
                "gridX2": 0,
                "gridY2": 0,
                "nameColor": "#eee",
                "lineColor": "#eee",
                "lineWidth": 5,
                "xNameFontSize": 16,
                "yNameFontSize": 16,
                "labelShow": false,
                "labelShowFontSize": 14,
                "labelShowFontWeight": 300,
                "yAxisInverse": false,
                "xAxisInverse": false,
                "xAxisShow": true,
                "yAxisShow": true,
                "xAxisSplitLineShow": false,
                "yAxisSplitLineShow": false,
                "barWidth": 30,
                "barRadius": 8,
                "barColor": [{
                    "color1": "#83bff6",
                    "color2": "#188df0",
                    "postion": 90,
                    "$index": 0,
                    "_show": true
                }, {
                    "color1": "#23B7E5",
                    "color2": "#564AA3",
                    "postion": 50,
                    "$index": 1,
                    "_show": true
                }],
                "symbolSize": 6,
                "smooth": true,
                "barMinHeight": 2
            }
        }
    }, {
        label: '饼图',
        option: {
            "name": "饼图",
            "title": "饼图",
            "icon": 'icon-pie',
            "img": require('@/assets/pie.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "time": 5000,
            "url": baseUrl + "/pie",
            "data": [{
                "name": "苹果",
                "value": 1000879,
                "url": "http://www.baidu.com"
            }, {
                "name": "三星",
                "value": 3400879,
                "url": "http://www.baidu.com"
            }, {
                "name": "小米",
                "value": 2300879,
                "url": "http://www.baidu.com"
            }, {
                "name": "oppo",
                "value": 5400879,
                "url": "http://www.baidu.com"
            }, {
                "name": "大疆",
                "value": 3000,
                "url": "http://www.baidu.com"
            }, {
                "name": "抖音",
                "value": 2000,
                "url": "http://www.baidu.com"
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "resize": false,
            "component": {
                "width": 600,
                "height": 300,
                "name": "pie",
                "prop": "pie",
            },
            "option": {
                "legend": true,
                "labelShow": true,
                "barColor": [
                    {
                        "color1": "#83bff6"
                    },
                    {
                        "color1": "#23B7E5"
                    },
                    {
                        "color1": "rgba(154, 168, 212, 1)"
                    },
                    {
                        "color1": "#188df0"
                    },
                    {
                        "color1": "#564AA3"
                    }
                ]
            }
        }
    }, {
        label: '象形图',
        option: {
            "title": "象形图",
            "name": "象形图",
            "icon": "icon-pictorialBar",
            "img": require('@/assets/bar2.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "time": 5000,
            "url": baseUrl + "/pie",
            "data": [{
                "name": "苹果",
                "value": 1000879,
            }, {
                "name": "三星",
                "value": 3400879,
            }, {
                "name": "小米",
                "value": 2300879,
            }, {
                "name": "oppo",
                "value": 5400879,
            }, {
                "name": "大疆",
                "value": 3000,
            }, {
                "name": "抖音",
                "value": 2000,
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 600,
                "height": 300,
                "name": "pictorialbar",
                "prop": "pictorialbar",
            },
            "option": {
                symbolSize: 30,
                split: 30,
                symbol: '/img/source/source256.png',
                fontSize: '24',
                xAxisShow: false,
                yAxisShow: true,
                color: '#4dffff',
                nameColor: '#564AA3',
                xNameFontSize: '24',
                yNameFontSize: '24',
            }
        }
    }, {
        label: '雷达图',
        option: {
            "title": "雷达图",
            "name": "雷达图",
            "icon": "icon-radar",
            "img": require('@/assets/radar.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "time": 5000,
            "url": baseUrl + "/radar",
            "data": {
                indicator: [{
                    name: '销售',
                    max: 6500
                },
                {
                    name: '管理',
                    max: 16000
                },
                {
                    name: '信息技术',
                    max: 30000
                },
                {
                    name: '客服',
                    max: 38000
                },
                {
                    name: '研发',
                    max: 52000
                },
                {
                    name: '市场',
                    max: 25000
                }
                ],
                series: [{
                    data: [{
                        value: [4300, 10000, 28000, 35000, 50000, 19000],
                        name: '预算分配（Allocated Budget）'
                    },
                    {
                        value: [5000, 14000, 28000, 31000, 42000, 21000],
                        name: '实际开销（Actual Spending）'
                    }
                    ]
                }]
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 970,
                "height": 400,
                "name": "radar",
                "prop": "radar",
            },
            "option": {
                width: '100%',
                height: 600,
                titleShow: true,
                title: '各部门开销',
                subtext: '最近一个月',
                titleColor: '#666',
                tipBackgroundColor: 'rgba(50,50,50,0.7)',
                tipColor: "#fff",
                shape: 'polygon',
                radius: "75%",
                splitNumber: 4,
                radarNameColor: '#fff',
                radarNameSize: 18,
                titleFontSize: 18,
                areaOpacity: 0.3,
                legend: true,
                // labelShow: true,
                "barColor": [{
                    "color1": "#564AA3",
                },
                {
                    "color1": "#188df0",
                }
                ]
            },
        }
    }, {
        label: '散点图',
        option: {
            "title": "散点图",
            "name": "散点图",
            "icon": "icon-scatter",
            "img": require('@/assets/scatter.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "time": 5000,
            "url": baseUrl + "/scatter",
            "data": [{
                "data": [
                    [1, 8.04],
                    [2, 6.95]
                ]
            },
            {
                "data": [
                    [1, 4.04],
                    [2, 3.95]
                ]
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 600,
                "height": 300,
                "name": "scatter",
                "prop": "scatter",
            },
            "option": {
                lineSize: 25,
                barColor: [{
                    postion: 0.2,
                    color1: '#91c7ae'
                },
                {
                    postion: 0.8,
                    color1: '#63869e'
                },
                {
                    postion: 1,
                    color1: '#c23531'
                }]
            }
        }
    }, {
        label: '漏斗图',
        option: {
            "title": "漏斗图",
            "name": "漏斗图",
            "icon": "icon-funnel",
            "img": require('@/assets/funnel.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "time": 5000,
            "url": baseUrl + "/funnel",
            "data": [{
                value: 335,
                name: '直接访问'
            },
            {
                value: 310,
                name: '邮件营销'
            },
            {
                value: 234,
                name: '联盟广告'
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 400,
                "height": 300,
                "name": "funnel",
                "prop": "funnel",
            },
            "option": {
                radius: true,
                "barColor": [{
                    "color1": "#83bff6",
                },
                {
                    "color1": "#23B7E5",
                },
                {
                    "color1": "rgba(154, 168, 212, 1)",
                },
                {
                    "color1": "#188df0",
                },
                {
                    "color1": "#564AA3",
                }
                ]
            }
        }
    }, {
        label: '地图',
        option: {
            "name": "地图",
            "title": "地图",
            "icon": "icon-map",
            "img": require('@/assets/map.png'),
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 800,
                "height": 500,
                "name": "map",
                "prop": "map",
            },
            "option": {
                "borderWidth": 1,
                "scale": 87,
                "type": 0,
                "borderColor": "#0dffff",
                "areaColor": "#061d33",
                "banner": true,
                "bannerTime": 3000,
                "fontSize": 14,
                "zoom": 0.5,
                "empAreaColor": "rgba(35, 183, 229, 0.42)",
                "empColor": "#fff",
                "index": "894edc1d-d2f0-4616-962c-8e61083ada71",
                "mapData": "/map/data?id=1235103352843448322",
                "color": "rgba(13, 255, 255, 1)",
            }
        },
    }]
}, {
    "label": '文字',
    "icon": 'icon-text',
    "children": [{
        "label": '文本框',
        "option": {
            "title": "文本框",
            "name": "文本框",
            "icon": 'icon-text',
            "img": require('@/assets/text.png'),
            "dataType": 0,
            "dataFormatter": "",
            "stylesFormatter": "",
            "child": {
                "index": [],
                "paramName": ""
            },
            "data": {
                value: '文本框'
            },
            "component": {
                "width": 100,
                "height": 40,
                "name": "text",
                "prop": "text"
            },
            "option": {
                "textAlign": "center",
                "fontSize": 26,
                "fontWeight": "normal",
                "color": "#fff"
            },
        },
    }, {
        "label": '跑马灯',
        "option": {
            "title": "跑马灯",
            "name": "跑马灯",
            "icon": 'icon-scroll',
            "img": require('@/assets/text2.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                value: "跑马灯"
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 100,
                "height": 50,
                "name": "text",
                "prop": "text"
            },
            "option": {
                scroll: true,
                speed: 70,
                "textAlign": "center",
                "fontSize": 26,
                "fontWeight": "normal",
                "color": "#fff"
            },
        },
    }, {
        "label": '超链接',
        "option": {
            "title": "超链接",
            "name": "超链接",
            "icon": 'icon-link',
            "img": require('@/assets/text3.png'),
            "top": 0,
            "left": 0,
            "data": {
                "value": "超链接"
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 100,
                "height": 50,
                "name": "text",
                "prop": "text"
            },
            "option": {
                link: true,
                linkHref: 'http://avue.top',
                linkTarget: '_self',
                "textAlign": "center",
                "fontSize": 26,
                "fontWeight": "normal",
                "color": "#fff"
            },
        },
    }, {
        "label": '实时时间',
        "option": {
            "name": "实时时间",
            "title": "实时时间",
            "icon": 'icon-datetime',
            "img": require('@/assets/datetime.png'),
            "top": 78,
            "left": 1604,
            "component": {
                "width": 250,
                "height": 50,
                "name": "datetime",
                "prop": "datetime",
            },
            "option": {
                "format": 'yyyy-MM-dd hh:mm:ss',
                "color": "#fff",
                'textAlign': 'left',
                "fontSize": 24,
                "fontWeight": "normal"
            }
        },
    }]
}, {
    "label": '媒体',
    "icon": 'icon-img',
    "children": [{
        "label": '图片',
        "option": {
            "title": "图片",
            "name": "图片",
            "icon": 'icon-img',
            "img": require('@/assets/img.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                value: ''
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 140,
                "height": 140,
                "name": "img",
                "prop": "img",
            },
            "option": {
                duration: '1000',
            }
        }
    }, {
        "label": '图片框',
        "option": {
            "title": "图片框",
            "name": "图片框",
            "icon": 'icon-img',
            "img": require('@/assets/imgborder.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": "",
            "component": {
                "width": 140,
                "height": 140,
                "name": "imgborder",
                "prop": "imgborder",
            },
            "option": {
                backgroundColor: 'rgba(180, 181, 198, 0.1)',
            }
        }
    }, {
        label: '轮播图',
        option: {
            "title": "轮播图",
            "name": "轮播图",
            "icon": 'icon-banner',
            "img": require('@/assets/swiper.png'),
            "top": 0,
            "left": 0,
            "dataType": 1,
            "dataMethod": 'get',
            "time": 5000,
            "url": baseUrl + "/swiper",
            "data": [
                {
                    value: 'https://img.alicdn.com/tfs/TB1v28TC8v0gK0jSZKbXXbK2FXa-1880-640.jpg',
                }, {
                    value: 'https://img.alicdn.com/tfs/TB1uevcCrj1gK0jSZFuXXcrHpXa-1880-640.jpg',
                }, {
                    value: 'http://www.w3school.com.cn/i/movie.ogg'
                }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 670,
                "height": 370,
                "name": "swiper",
                "prop": "swiper"
            },
            "option": {
                type: '',
                interval: 5000,
                opacity: 100,
                indicator: 'none',
                autoplay: true,
                controls: true,
                loop: true

            },
        }
    }, {
        label: 'iframe',
        option: {
            "title": "iframe",
            "name": "iframe",
            "icon": 'icon-iframe',
            "img": require('@/assets/iframe.png'),
            "top": 0,
            "left": 0,
            "component": {
                "width": 670,
                "height": 370,
                "option": {},
                "name": "iframe",
                "prop": "iframe"
            },
            "dataType": 0,
            "data": {
                value: 'https://avuejs.com'
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "option": {},
        }
    }, {
        label: 'video播放器',
        option: {
            "title": "video",
            "name": "video",
            "icon": 'icon-video',
            "img": require('@/assets/video.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                value: 'http://www.w3school.com.cn/i/movie.ogg'
            },
            "option": {
                autoplay: true,
                controls: true,
                loop: true
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 670,
                "height": 370,
                "option": {},
                "name": "video",
                "prop": "video"
            },
        }
    }, {
        label: 'hls播放器',
        option: {
            "title": "hls",
            "name": "hls",
            "icon": 'icon-video',
            "img": require('@/assets/video.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                value: 'https://open.ys7.com/v3/openlive/E10668433_1_1.m3u8?expire=1664027961&id=363444666731335680&t=346b970f0d5cb5d5b5e4734d966275a61fb9efd5038c7a75b7435423c5716908&ev=100'
            },
            "option": {
                autoplay: true
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 670,
                "height": 370,
                "option": {},
                "name": "clapper",
                "prop": "clapper"
            },
        }
    }, {
        label: 'hls播放器(可变)',
        option: {
            "title": "hls(可变)",
            "name": "hls(可变)",
            "icon": 'icon-video',
            "img": require('@/assets/video.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                value: 'https://open.ys7.com/v3/openlive/E10668433_1_1.m3u8?expire=1664027961&id=363444666731335680&t=346b970f0d5cb5d5b5e4734d966275a61fb9efd5038c7a75b7435423c5716908&ev=100'
            },
            "option": {
                autoplay: true
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 670,
                "height": 370,
                "option": {},
                "name": "clappers",
                "prop": "clappers"
            },
        }
    },
    {
        label: '萤石云播放器(可变)',
        option: {
            "title": "萤石云播放器(可变)",
            "name": "萤石云播放器(可变)",
            "icon": 'icon-video',
            "img": require('@/assets/video.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                value: 'https://open.ys7.com/v3/openlive/E10668433_1_1.m3u8?expire=1664027961&id=363444666731335680&t=346b970f0d5cb5d5b5e4734d966275a61fb9efd5038c7a75b7435423c5716908&ev=100'
            },
            "option": {
                autoplay: true
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 670,
                "height": 370,
                "option": {},
                "name": "yunClapprs",
                "prop": "yunClapprs"
            },
        }
    },
    {
        label: '萤石云播放器(定制)',
        option: {
            "title": "萤石云播放器(定制)",
            "name": "萤石云播放器(定制)",
            "icon": 'icon-video',
            "img": require('@/assets/video.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                value: 'https://open.ys7.com/v3/openlive/E10668433_1_1.m3u8?expire=1664027961&id=363444666731335680&t=346b970f0d5cb5d5b5e4734d966275a61fb9efd5038c7a75b7435423c5716908&ev=100'
            },
            "option": {
                autoplay: true
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 670,
                "height": 370,
                "option": {},
                "name": "yunClapprsAuto",
                "prop": "yunClapprsAuto"
            },
        }
    }]
}, 
{
    "label": '指标',
    "icon": 'icon-flop',
    "children": [{
        "label": '翻牌器',
        "option": {
            "title": "翻牌器",
            "name": "翻牌器",
            "icon": 'icon-flop',
            "img": require('@/assets/flop.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "child": {
                "index": [],
                "paramName": ""
            },
            "data": {
                value: '12345'
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "flop",
                "prop": "flop",
            },
            "option": {
                "type": "img",
                "suffixText": "",
                "suffixTextAlign": "",
                "suffixSplit": '',
                "suffixColor": "",
                "suffixFontSize": '',
                "borderColor": "#fff",
                "borderWidth": 3,
                "backgroundBorder": "/img/border/border1.png",
                "fontSize": 32,
                "fontWeight": "normal",
                "splitx": 0,
                "splity": 0,
                "backgroundColor": "",
                "color": "#fff"
            },
        }
    }, {
        "label": '颜色块',
        "option": {
            "title": "颜色块",
            "name": "颜色块",
            "icon": 'icon-flop',
            "img": require('@/assets/flop2.png'),
            "child": {
                "index": [],
                "paramName": ""
            },
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": [{
                "backgroundColor": '#67C23A',
                "prefixText": "某某某总人数",
                "value": '12345',
                "suffixText": '人',
            }, {
                "backgroundColor": '#409EFF',
                "prefixText": "某某某总人数",
                "value": '12345',
                "suffixText": '人',
            }, {
                "backgroundColor": '#E6A23C',
                "prefixText": "某某某总人数",
                "value": '12345',
                "suffixText": '人',
            }, {
                "backgroundColor": '#F56C6C',
                "prefixText": "某某某总人数",
                "value": '12345',
                "suffixText": '人',
            }, {
                "backgroundColor": '#7232dd',
                "prefixText": "某某某总人数",
                "value": '12345',
                "suffixText": '人',
            }, {
                "backgroundColor": 'blue',
                "prefixText": "某某某总人数",
                "value": '12345',
                "suffixText": '人',
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 888,
                "height": 203,
                "name": "flop",
                "prop": "flop",
            },
            "option": {
                "span": 3,
                "decimals": 2,
                "whole": true,
                "padding": 12,
                "textAlign": 'left',
                "splity": 23,
                "splitx": 17,
                "fontSize": 28,
                "prefixSplity": 4,
                "prefixFontSize": 13,
                "backgroundColor": "#F56C6C",
                "suffixText": '人',
                "suffixInline": true,
                "suffixSplitx": 10,
                "suffixFontSize": 18,
            },
        }
    }, {
        "label": '环形图',
        "option": {
            "title": "环形图",
            "name": "环形图",
            "icon": 'icon-circle',
            "img": require('@/assets/progress.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": {
                "label": "人数增涨",
                "value": 40,
                "data": 80
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 300,
                "name": "progress",
                "prop": "progress"
            },
            "option": {
                "type": "circle",
                "strokeWidth": 25,
                "color": "rgba(154, 168, 212, 1)",
                "fontSize": 40,
                "fontWeight": "normal",
                "suffixColor": "rgba(154, 168, 212, 1)",
                "suffixFontSize": 40,
                "suffixFontWeight": "normal",
                "borderColor": "#564AA3",
                "split": 20,
                "width": 400,
                "height": 80
            },
        }
    }, {
        "label": '进度条',
        "option": {
            "title": "进度条",
            "name": "进度条",
            "icon": 'icon-progress',
            "img": require('@/assets/progress2.png'),
            "top": 0,
            "left": 0,
            "data": {
                "label": "人数增涨",
                "value": 40,
                "data": 80
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 400,
                "height": 100,
                "name": "progress",
                "prop": "progress"
            },
            "option": {
                "type": "line",
                "color": "#fff",
                "fontSize": 24,
                "suffixColor": "#fff",
                "strokeWidth": 13,
                "fontWeight": "normal",
                "borderColor": "#564AA3"
            },
        }
    }, {
        label: '仪表盘',
        option: {
            "title": "仪表盘",
            "icon": 'icon-gauge',
            "img": require('@/assets/gauge.png'),
            "name": "仪表盘",
            "dataType": 1,
            "dataMethod": 'get',
            "time": 5000,
            "url": baseUrl + "/gauge",
            "data": {
                min: 1,
                max: 10,
                label: '名称',
                value: 4,
                unit: '%'
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 390,
                "height": 314,
                "name": "gauge",
                "prop": "gauge"
            },
            "option": {
                "lineSize": 15,
                "axisLabelShow": false,
                "axisLabelFontSize": 25,
                "nameFontSize": 20,
                "valueFontSize": 30,
                "nameColor": "",
                "lineColor": "#eee",
                "barColor": [
                    {
                        "color1": "rgba(154, 168, 212, 1)",
                        "postion": "0.2",
                    },
                    {
                        "color1": "#23B7E5",
                        "postion": "0.8",
                    },
                    {
                        "color1": "#564AA3",
                        "postion": "1",
                    }
                ]
            },
        },
    }, {
        label: '字符云',
        option: {
            "title": "字符云",
            "name": "字符云",
            "icon": "icon-wordCloud",
            "img": require('@/assets/wordcloud.png'),
            "top": 725,
            "left": 3780,
            "dataType": 1,
            "dataMethod": 'get',
            "data": [{
                name: '三星',
                value: '1234'
            }, {
                name: '小米',
                value: '1234'
            }, {
                name: '华为',
                value: '1234'
            }, {
                name: 'oppo',
                value: '1234'
            }, {
                name: '抖音',
                value: '1234'
            }, {
                name: '快手',
                value: '1234'
            }, {
                name: '淘宝',
                value: '1234'
            }, {
                name: '百度',
                value: '1234'
            }, {
                name: '京东',
                value: '1234'
            }, {
                name: '天猫',
                value: '1234'
            }, {
                name: '字符1',
                value: '1234'
            }, {
                name: '字符1',
                value: '1234'
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "url": baseUrl + "/wordCloud",
            "time": 5000,
            "component": {
                "name": "wordcloud",
                "prop": "wordcloud",
                "width": 600,
                "height": 300,
            },
            option: {
                minFontSize: 30,
                maxFontSize: 80,
                split: 30,
                rotate: true,
            }
        }
    }]
}, {
    "label": '表格',
    "icon": 'icon-table',
    "children": [{
        "label": '表格',
        "option": {
            "title": "表格",
            "name": "表格",
            "icon": 'icon-table',
            "img": require('@/assets/table.png'),
            "top": 0,
            "left": 0,
            "dataType": 0,
            "data": [{
                "type1": '数据1',
                "type2": '数据1'
            }, {
                "type1": '数据2',
                "type2": '数据2'
            }, {
                "type1": '数据3',
                "type2": '数据3'
            }, {
                "type1": '数据4',
                "type2": '数据4'
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 700,
                "height": 350,
                "name": "table",
                "prop": "table",
            },
            "option": {
                "columnShow": true,
                "columnViews": true,
                "headerBackground": "rgba(8, 90, 111, 0.8)",
                "headerColor": "rgba(154, 168, 212, 1)",
                "headerTextAlign": "center",
                "nthColor": "rgba(14, 86, 241, 0.08)",
                "bodyBackground": "rgba(0, 0, 0, 0.01)",
                "bodyColor": "rgba(154, 168, 212, 1)",
                "bodyTextAlign": "center",
                "align": "center",
                "headerAlign": "center",
                "header": false,
                "scrollSpeed": 1,
                "fontSize": 15,
                "count": 4,
                "index": true,
                "showControl": true,
                "showSelection": false,
                "column": [{
                    "label": "车牌号",
                    "prop": "type1"
                }, {
                    "label": "驾驶员",
                    "prop": "type2"
                }, {
                    "label": "里程",
                    "prop": "type3"
                }, {
                    "label": "油耗",
                    "prop": "type4"
                }],
            }
        }
    }, {
        label: '选项卡',
        option: {
            "title": "选项卡",
            "name": "选项卡",
            "icon": "icon-tabs",
            "img": require('@/assets/tabs.png'),
            "child": {
                "index": [],
                "paramName": ""
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "dataType": 0,
            "data": [
                {
                    "label": "选项卡1",
                    "value": "1"
                },
                {
                    "label": "选项卡2",
                    "value": "2"
                }
            ],
            "component": {
                "width": 356.9128919860628,
                "height": 46.209059233449096,
                "name": "tabs",
                "prop": "tabs"
            },
            "option": {
                "type": 'tabs',
                "color": "#eee",
                "empColor": "#4dffff",
                "fontSize": 20,
                "split": 8,
                "empBackgroundImage": "/img/banner/banner5.png",
                "backgroundImage": "/img/banner/banner4.png"
            },
        },
    }]
}, {
    label: '二次开发',
    icon: 'el-icon-info',
    children: [{
        label: '自定义组件',
        option: {
            "name": "自定义组件",
            "title": "自定义组件",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 800,
                "height": 500,
                "name": "test",
                "prop": "test",
            },
            "option": {
                "fontSize": 20,
            }
        }
    }, {
        label: '定时器',
        option: {
            "name": "定时器",
            "title": "定时器",
            "icon": 'icon-datetime',
            "img": require('@/assets/text4.png'),
            "echartFormatter": "",
            "component": {
                "width": 100,
                "height": 100,
                "name": "time",
                "prop": "time",
            },
            option: {
                time: 0
            }
        }
    }, {
        label: '滚动选项卡',
        option: {
            "name": "滚动选项卡",
            "title": "滚动选项卡",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            data: [{
                text: '领导调研',
                list: [
                    "https://img.alicdn.com/tfs/TB1v28TC8v0gK0jSZKbXXbK2FXa-1880-640.jpg",
                    "https://img.alicdn.com/tfs/TB1uevcCrj1gK0jSZFuXXcrHpXa-1880-640.jpg"
                ]
            }, {
                text: '先进事件',
                list: [
                    "https://img.alicdn.com/imgextra/i1/O1CN01NAnI6W1Vf1kZScscd_!!6000000002679-0-tps-2880-1070.jpg",
                    "https://img.alicdn.com/imgextra/i2/O1CN011FMQT21RF8u1nHsem_!!6000000002081-0-tps-2880-1070.jpg"
                ]
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 800,
                "height": 400,
                "name": "imgTabs",
                "prop": "imgTabs",
            },
            "option": {
                direction: 'horizontal',
                interval: 3000,
                time: 5000,
                autoplay: true
            }
        }
    }, {
        label: '滚动列表',
        option: {
            "name": "滚动列表",
            "title": "滚动列表",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                name: 'smallwei',
                age: '28',
                color: 'green',
                img: 'https://avuejs.com/images/logo.png',
                desc: '前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发',
                tag: ['前端']
            }, {
                name: 'smallwei',
                age: '28',
                color: 'red',
                img: 'https://avuejs.com/images/logo.png',
                desc: '前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发',
                tag: ['前端']
            }, {
                name: 'smallwei',
                age: '28',
                color: 'red',
                img: 'https://avuejs.com/images/logo.png',
                desc: '前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发,前端搬砖神器,让数据驱动视图,更加贴合企业开发',
                tag: ['前端', '前端', '前端'],
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 800,
                "height": 500,
                "name": "imgList",
                "prop": "imgList",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
            }
        }
    },
    {
        label: '日期选择',
        option: {
            "name": "日期选择",
            "title": "日期选择",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                label: "1",
                value: 1
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "datePicker",
                "prop": "datePicker",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
                fontSize: 20,
                format: 'yyyy-MM-dd',
                datePickerSize: 'large',
                datePickerType: 'daterange',
                datePickerDefaultSection: 'year',
                dateParamFormat: 'yyyy-MM',//传出的参数格式
            }
        }
    },
    {
        label: '企业简介',
        option: {
            "name": "企业简介",
            "title": "企业简介",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataFormatter": "",
            "stylesFormatter": "",
            "data": [
                {
                    'title': '慧筑云-帮助企业提升信息化应用水平，全面整合内外资源，合理规划，充分调度，达到开源节流，降本增效的目的。           慧筑云-国内首推SaaS云共享模式，提供资源共享、智慧建造和智慧建管三大服务。其中资源共享云提供找人、找材料、找设备、找项目、找资金共享服务，提高客户的粘性，形成行业大数据，不断提高平台 增值服务能力。智慧管理云涵盖建筑施工行业ERP系统、协同办公系统、智慧工地集成系统、BIM轻量化应用系统。企业及工地只需采购硬件服务，即可连接云平台，组成智慧建造全场景应用。智慧建管实现了审批流程数字化、数据存档结构化、监督管理智能化，大幅度提高了工程建造全流程的信息化程度，简化流程，提高工作效率，现已对接多地政府相关数字化平台。',
                },
                {
                    'title': 'asdfasd',
                },
                {
                    'title': 'asdfas',
                },
                {
                    'title': 'asdfafs',
                },
                {
                    'title': 'asdfafs',
                },
                {
                    'title': 'asdfafs',
                },
                {
                    'title': 'asdfafs',
                },
                {
                    'title': 'asdfafs',
                },
                {
                    'title': 'asdfafs',
                },
                {
                    'title': 'asdfafs',
                },
                {
                    'title': 'asdfafs',
                },
            ],
            "component": {
                "width": 280,
                "height": 200,
                "name": "companyDetail",
                "prop": "companyDetail",
            },
            "option": {
                "textAlign": "center",
                "fontSize": 26,
                "fontWeight": "normal",
                "color": "#fff"
            }
        }
    },
    {
        label: '滚动词云组件',
        option: {
            "name": "滚动词云组件",
            "title": "滚动词云组件",
            "icon": 'icon-bar',
            "img": require('@/assets/wordclouds.jpg'),
            "dataFormatter": "",
            "stylesFormatter": "",
            "data": [
                {
                    name: "你好啊",
                    light: true
                },
                {
                    name: "你好啊",
                    light: false
                },
                {
                    name: "你好啊",
                    light: false
                },
                {
                    name: "你好啊",
                    light: false
                },
                {
                    name: "你好啊",
                    light: true
                },
                {
                    name: "你好啊",
                    light: false
                },
            ],
            "component": {
                "width": 558,
                "height": 262,
                "name": "wordClouds",
                "prop": "wordClouds",
            },
            "option": {
                "maxSpeed": 0.03,
            }
        }
    },
    {
        label: '三级联动',
        option: {
            "name": "三级联动",
            "title": "三级联动",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                label: "1",
                value: 1
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "three",
                "prop": "three",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
                fontSize: 20,

            }
        }
    },
    {
        "label": '3d饼图',
        "option": {
            "name": "3d饼图",
            "title": "3d饼图",
            "icon": 'icon-bar',
            "img": require('@/assets/text5.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "data": [{
                name: '林地面积统计',
                value: 10000,
                itemStyle: {
                    color: 'rgba(1,1,1,0.0)',
                }
            }, {
                name: '草地面积统计',
                value: 82116,
                itemStyle: {
                    color: 'rgba(1,1,1,0.0)',
                }
            },],
            "echartFormatter": "(data)=>{\nconsole.log(data);\nreturn {\n            title: {\n\t\t\t\t textStyle: {\n\t\t\t\t\tfontWeight: 'normal',      \n\t\t\t\t\tcolor: '#fff'\n\t\t\t\t},\n                text: 'ECharts 入门示例'\n            },\n            tooltip: {},\n            legend: {\n                data:['销量'],\n\t\t\t\ttextStyle: {\n\t\t\t\t\tfontWeight: 'normal',      \n\t\t\t\t\tcolor: '#fff'\n\t\t\t\t},\n            },\n            xAxis: {\n                data: [\'衬衫\',\'羊毛衫\',\'雪纺衫\',\'裤子\',\'高跟鞋\',\'袜子\']\n            },\n            yAxis: {},\n            series: [{\n                name: '销量',\n                type: 'bar',\n                data: [5, 20, 36, 10, 10, 20]\n            }]\n        };\n}",
            "dataFormatter": "",
            "stylesFormatter": "",
            "time": 50000,
            "component": {
                "width": 600,
                "height": 300,
                "name": "pie3d",
                "prop": "pie3d",
            },
        }
    },
    {
        label: '物料搜索框',
        option: {
            "name": "物料搜索框",
            "title": "物料搜索框",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "data": {
                '金额': [{
                    label: "1",
                    value: 1,
                    id: 1
                }],
                "频次": [{
                    label: "2",
                    value: 2,
                    id: 2
                }]
            },
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "materialSearch",
                "prop": "materialSearch",
            },
            "option": {
                fontSize: 20,
            }
        }
    },
    {
        label: '树',
        option: {
            "name": "树",
            "title": "树",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 1,
            "dataMethod": 'get',
            "data": [
                {
                    "id": 1,
                    "label": "一级 1",
                    "children": [
                        {
                            "id": 4,
                            "label": "二级 1-1",
                            "children": [
                                {
                                    "id": 9,
                                    "label": "三级 1-1-1"
                                },
                                {
                                    "id": 10,
                                    "label": "三级 1-1-2"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": 2,
                    "label": "一级 2",
                    "children": [
                        {
                            "id": 5,
                            "label": "二级 2-1"
                        },
                        {
                            "id": 6,
                            "label": "二级 2-2"
                        }
                    ]
                },
                {
                    "id": 3,
                    "label": "一级 3",
                    "children": [
                        {
                            "id": 7,
                            "label": "二级 3-1"
                        },
                        {
                            "id": 8,
                            "label": "二级 3-2"
                        }
                    ]
                }
            ],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "tree",
                "prop": "tree",
            },
            "option": {
                fontSize: 20,
                "labelKey": 'label',
            }
        }
    },

    {
        label: 'input',
        option: {
            "name": "input",
            "title": "input",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": '',
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 200,
                "height": 35,
                "name": "input",
                "prop": "input",
            },
            "option": {
                "placeholder": '请输入',
            }
        }
    },
    {
        label: 'inputSearch',
        option: {
            "name": "inputSearch",
            "title": "inputSearch",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "data": '',
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 200,
                "height": 35,
                "name": "inputSearch",
                "prop": "inputSearch",
            },
            "option": {
                "placeholder": '请输入',
            }
        }
    },
    {
        label: '日历',
        option: {
            "name": "日历",
            "title": "日历",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                label: "1",
                value: 1
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "year",
                "prop": "year",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
                fontSize: 20,
                format: 'yyyy',
                datePickerSize: 'large',
                datePickerType: 'year',
                datePickerDefaultSection: 'year',
                dateParamFormat: 'yyyy',//传出的参数格式
            }
        }
    },
    {
        label: '日历天',
        option: {
            "name": "日历天",
            "title": "日历天",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                label: "1",
                value: 1
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "day",
                "prop": "day",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
                fontSize: 20,
                format: 'yyyy-MM-dd',
                datePickerSize: 'large',
                datePickerType: 'date',
                datePickerDefaultSection: 'date',
                dayParamFormat: 'yyyy-MM-dd',//传出的参数格式
            }
        }
    },
    {
        label: '默认日期选择器',
        option: {
            "name": "默认日期选择器",
            "title": "默认日期选择器",
            "icon": 'icon-date',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                label: "1",
                value: 1
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 122,
                "height": 35,
                "name": "defaultDate",
                "prop": "defaultDate",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
                fontSize: 20,
                format: 'yyyy-MM-dd',
                datePickerSize: 'large',
                defaultDateParamFormat: 'yyyy-MM-dd',//传出的参数格式
                intercept: false, // 是否只可选择当前日期之前的数据
                defaultValue: true
            }
        }
    },
    {
        label: '日历月',
        option: {
            "name": "日历月",
            "title": "日历月",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                label: "1",
                value: 1
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "month",
                "prop": "month",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
                fontSize: 20,
                format: 'yyyy-MM',
                datePickerSize: 'large',
                datePickerType: 'month',
                datePickerDefaultSection: 'month',
                dateParamFormat: 'yyyy-MM',//传出的参数格式
            }
        }
    },
    {
        label: '日历月设备',
        option: {
            "name": "日历月设备",
            "title": "日历月设备",
            "icon": 'icon-bar',
            "img": require('@/assets/text4.png'),
            "dataType": 0,
            "data": [{
                label: "1",
                value: 1
            }],
            "dataFormatter": "",
            "stylesFormatter": "",
            "component": {
                "width": 300,
                "height": 100,
                "name": "monthSB",
                "prop": "monthSB",
            },
            "option": {
                borderImageSource: '/img/border/border1.png',
                step: 1,
                marginBottom: 20,
                hoverStop: true,
                fontSize: 20,
                format: 'yyyy-MM',
                datePickerSize: 'large',
                datePickerType: 'month',
                datePickerDefaultSection: 'month',
                dateParamFormat: 'yyyy-MM',//传出的参数格式
            }
        }
    },
	{
	    label: '自定义图例饼图（大）',
	    option: {
	        "name": "自定义图例饼图（大）",
	        "title": "自定义图例饼图（大）",
	        "icon": 'icon-bar',
	        "img": require('@/assets/text4.png'),
	        "dataType": 0,
	        "data": [],
	        "dataFormatter": "",
	        "stylesFormatter": "",
	        "component": {
	            "width": 1080,
	            "height": 500,
	            "name": "customPie",
	            "prop": "customPie",
	        },
	        "option": {
	            "fontSize": 20,
	        }
	    }
	},{
	    label: '自定义图例饼图（小）',
	    option: {
	        "name": "自定义图例饼图（小）",
	        "title": "自定义图例饼图（小）",
	        "icon": 'icon-bar',
	        "img": require('@/assets/text4.png'),
	        "dataType": 0,
	        "data": [],
	        "dataFormatter": "",
	        "stylesFormatter": "",
	        "component": {
	            "width": 600,
	            "height": 600,
	            "name": "customPieTransverse",
	            "prop": "customPieTransverse",
	        },
	        "option": {
	            "fontSize": 20,
	        }
	    }
	},{
	    label: '问号弹出框',
	    option: {
	        "name": "问号弹出框",
	        "title": "问号弹出框",
	        "icon": 'icon-bar',
	        "img": require('@/assets/text4.png'),
	        "dataType": 0,
	        "data": [],
	        "dataFormatter": "",
	        "stylesFormatter": "",
	        "component": {
	            "width": 100,
	            "height": 100,
	            "name": "popupQuestion",
	            "prop": "popupQuestion",
	        },
	        "option": {
	            "fontSize": 20,
	        }
	    }
	},{
	    label: '扩展table组件',
	    option: {
	        "name": "扩展table组件",
	        "title": "扩展table组件",
	        "icon": 'icon-bar',
	        "img": require('@/assets/text4.png'),
	        "dataType": 0,
	        "data": [],
	        "dataFormatter": "",
	        "stylesFormatter": "",
	        "component": {
	            "width": 1376,
	            "height": 650,
	            "name": "detailTable",
	            "prop": "detailTable",
	        },
	        "option": {
	            "fontSize": 20,
	        }
	    }
	},{
	    label: '安全帽监控',
	    option: {
	        "name": "安全帽监控",
	        "title": "安全帽监控",
	        "icon": 'icon-bar',
	        "img": require('@/assets/text4.png'),
	        "dataType": 0,
	        "data": [],
	        "dataFormatter": "",
	        "stylesFormatter": "",
	        "component": {
	            "width": 1376,
	            "height": 650,
	            "name": "safetyVideo",
	            "prop": "safetyVideo",
	        },
	        "option": {
	            "fontSize": 20,
	        }
	    }
	},
    ]
}]