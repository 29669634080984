<template>
  <div>
    <div class="container">
      <div class="left_container">
        <!-- 左上模块 -->
        <div class="left1">
          <!-- 在线/离线图标 -->
          <Chartimg :option="optin"></Chartimg>
          <!-- 标题-升降机信息 -->
          <div class="tjxx_title1">
            <div class="echart-text">
              <span>升降机信息</span>
            </div>
          </div>
          <!-- 内容-升降机机信息 -->
          <div class="taji_text">
            <p>
              设备编号：<i>{{ sjjxxData.elevator_no }}</i>
            </p>
            <p>
              塔机名称：<i>{{ sjjxxData.elevator_name }}</i>
            </p>
          </div>
          <!-- 标题-司机信息 -->
          <div class="tjxx_title2">
            <div class="echart-text">
              <span>司机信息</span>
            </div>
          </div>
          <!-- 内容-司机信息 -->
          <div class="siji_text">
            <p>
              司机姓名：<i>{{ sjjxxData.driver_name }}</i>
            </p>
            <p>
              识别类型：<i>{{ sjjxxData.recognition_type }}</i>
            </p>
          </div>
        </div>
        <!-- 左下模块 -->
        <div class="left2">
          <div class="scroll_board">
            <dv-scroll-board
              :config="config"
              @click="handleSjjlb"
              class="scroll_board_body"
            />
          </div>
        </div>
      </div>
      <div class="right_container">
        <div class="right1">
          <span>{{ option1[0].value }}</span>
          <span>{{ option1[1].value }}</span>
          <span>{{ option1[2].value }}</span>
          <span>{{ option1[3].value }}</span>
        </div>
        <div class="right2">
          <!-- 高度 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[0].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[0].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[0].value }}</div>
              <div class="unit">{{ btmInfo[0].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[0].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[0].style"></div>
            <div class="status-text">{{ btmInfo[0].statusText }}</div>
          </div>
          <!-- -------------------------- -->
          <!-- 重量 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[1].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[1].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[1].value }}</div>
              <div class="unit">{{ btmInfo[1].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[1].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[1].style"></div>
            <div class="status-text">{{ btmInfo[1].statusText }}</div>
          </div>
          <!-- -------------------------- -->
          <!-- 风速 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[2].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[2].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[2].value }}</div>
              <div class="unit">{{ btmInfo[2].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[2].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[2].style"></div>
            <div class="status-text">{{ btmInfo[2].statusText }}</div>
          </div>
          <!-- -------------------------- -->
          <!-- 吊重 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[3].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[3].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[3].value }}</div>
              <div class="unit">{{ btmInfo[3].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[3].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[3].style"></div>
            <div class="status-text">{{ btmInfo[3].statusText }}</div>
          </div>
          <!-- -------------------------- -->
          <!-- 边矩比 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[4].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[4].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[4].value }}</div>
              <div class="unit">{{ btmInfo[4].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[4].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[4].style"></div>
            <div class="status-text">{{ btmInfo[4].statusText }}</div>
          </div>
          <!-- -------------------------- -->
          <!-- 转角 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[5].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[5].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[5].value }}</div>
              <div class="unit">{{ btmInfo[5].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[5].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[5].style"></div>
            <div class="status-text">{{ btmInfo[5].statusText }}</div>
          </div>
          <!-- -------------------------- -->
          <!-- 倾角 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[6].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[6].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[6].value }}</div>
              <div class="unit">{{ btmInfo[6].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[6].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[6].style"></div>
            <div class="status-text">{{ btmInfo[6].statusText }}</div>
          </div>
          <!-- -------------------------- -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chartimg from "./chartimg.vue";
export default {
  // 升降机监测页组件
  name: "test7",
  components: { Chartimg },
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      optin: {
        data: [
          {
            style:
              "width: 70px;right:5px; top: 25px;color:#4e5052; font-size: 18px; ",
            value: "离线",
            url: "/img/source/source173.svg",
            // title: "在册人数",
            spin: false,
          },
        ],
      },
      config: {},
      elevator_no: "", // 获取塔机信息接口的参数
      sjjxxData: [
        {
          elevator_no: "",
          elevator_name: "",
          driver_name: "",
          recognition_type: "",
        },
      ], // 升降机信息数据
      virtualData: [["", "", ""]],
      option1: [
        {
          title: "吊装循环次数",
          value: "0",
          style: "color: #2cf314",
        },
        {
          title: "报警次数",
          value: "0",
          // style: "color: #f71214",
          style: "color: #2cf314",
        },
        {
          title: "超载次数",
          value: "0",
          // style: "color: #f71214",
          style: "color: #2cf314",
        },
        {
          title: "超载率",
          value: "0",
          // style: "color: #f7951f",
          style: "color: #2cf314",
        },
      ],
      btmInfo: [
        {
          title: "高度",
          value: 0,
          unit: "米",
          style: "background-color: #1ff021",
          statusText: "高度",
          icon: "/img/source/sjj_高度.svg",
          percentage: 0,
        },
        {
          title: "重量",
          value: 0,
          unit: "吨",
          style: "background-color: #1ff021",
          statusText: "重量",
          icon: "/img/source/sjj_重量.svg",
          percentage: 0,
        },
        {
          title: "倾斜度1",
          value: 0,
          unit: "度",
          style: "background-color: #1ff021",
          statusText: "倾斜度",
          icon: "/img/source/sjj_倾斜度.svg",
          percentage: 0,
        },
        {
          title: "倾斜度2",
          value: 0,
          unit: "度",
          style: "background-color: #1ff021",
          statusText: "前门锁状态",
          icon: "/img/source/sjj_倾斜度.svg",
          percentage: 0,
        },
        {
          title: "人数",
          value: 0,
          unit: "人",
          style: "background-color: #1ff021",
          statusText: "人数",
          icon: "/img/source/sjj_人数.svg",
          percentage: 0,
        },
        {
          title: "速度",
          value: 0,
          unit: "米/秒",
          style: "background-color: #1ff021",
          statusText: "速度",
          icon: "/img/source/sjj_速度.svg",
          percentage: 0,
        },

        {
          title: "方向",
          value: 0,
          unit: "",
          style: "background-color: #1ff021",
          statusText: "后门锁状态",
          icon: "/img/source/sjj_方向.svg",
          percentage: 0,
        },
      ],
      staticData: [
        {
          "elevator_no": "S1000001",
          "elevator_name": "中型升降机#1",
          "elevator_status": "在线",
          "real_time_speed_direction": 1,
          "real_time_height": 23,
          "real_time_weight": 2.5,
          "real_time_gradient1": 0.01,
          "real_time_gradient2": 0.01,
          "real_time_number_of_people": 1,
          "real_time_speed": 3.9,
          "heightPercentage": 0.02,
          "weightPercentage": 0.03,
          "tilt_percentage1": 0.05,
          "tilt_percentage2": 0.05,
          "people_percentage": 0.11,
          "speed_percentage": 0.72,
          "system_state": "000001000101",
          "hoisting_count": 5,
          "sos_count": 10,
          "driver_name": "王小平",
          "recognition_type": "人脸",
          "overload_time": 12,
          "overload_rate": "10%"
        },
        {
          "elevator_no": "S1000002",
          "elevator_name": "中型升降机#2",
          "elevator_status": "离线",
          "real_time_speed_direction": 2,
          "real_time_height": 28,
          "real_time_weight": 4.4,
          "real_time_gradient1": 0.02,
          "real_time_gradient2": 0.03,
          "real_time_number_of_people": 3,
          "real_time_speed": 4.5,
          "heightPercentage": 0.01,
          "weightPercentage": 0.02,
          "tilt_percentage1": 0.03,
          "tilt_percentage2": 0.03,
          "people_percentage": 0.14,
          "speed_percentage": 0.80,
          "system_state": "010000000010",
          "hoisting_count": 8,
          "sos_count": 7,
          "driver_name": "徐峥",
          "recognition_type": "指纹",
          "overload_time": 33,
          "overload_rate": "32%"
        },
        {
          "elevator_no": "S1000003",
          "elevator_name": "中型升降机#3",
          "elevator_status": "在线",
          "real_time_speed_direction": 1,
          "real_time_height": 55,
          "real_time_weight": 6.3,
          "real_time_gradient1": 0.10,
          "real_time_gradient2": 0.22,
          "real_time_number_of_people": 5,
          "real_time_speed": 4.9,
          "heightPercentage": 0.12,
          "weightPercentage": 0.21,
          "tilt_percentage1": 0.05,
          "tilt_percentage2": 0.05,
          "people_percentage": 0.33,
          "speed_percentage": 0.81,
          "system_state": "010101000000",
          "hoisting_count": 13,
          "sos_count": 21,
          "driver_name": "赵昌杰",
          "recognition_type": "人脸",
          "overload_time": 19,
          "overload_rate": "22%"
        },
        {
          "elevator_no": "S1000004",
          "elevator_name": "中型升降机#4",
          "elevator_status": "在线",
          "real_time_speed_direction": 2,
          "real_time_height": 34,
          "real_time_weight": 2.9,
          "real_time_gradient1": 0.11,
          "real_time_gradient2": 0.32,
          "real_time_number_of_people": 8,
          "real_time_speed": 4.1,
          "heightPercentage": 0.10,
          "weightPercentage": 0.13,
          "tilt_percentage1": 0.09,
          "tilt_percentage2": 0.09,
          "people_percentage": 0.20,
          "speed_percentage": 0.99,
          "system_state": "000110000100",
          "hoisting_count": 22,
          "sos_count": 55,
          "driver_name": "赵昌杰",
          "recognition_type": "人脸",
          "overload_time": 19,
          "overload_rate": "31%"
        },
      ],
    };
  },
  created() {
    this.getTjlb();
  },
  methods: {
    // 升降机列表
    getTjlb() {
      let _self = this;
      try {
        // let url = myRequest.getRequestUrlWithAnyParam2(
        //   "/api/homepage/elevator/list"
        // );
        // axios.get(url).then((res) => {
        let tjData = [];
        //   if (res.status === 200 && res.data.data !== undefined) {
        //     let data = res.data.data;
            let data = this.staticData;
            let elevator_no = []; // 保存塔机列表的设备编号
            data.forEach((item) => {
              elevator_no.push(item.elevator_no);
              tjData.push([
                item.elevator_no,
                item.elevator_name,
                item.elevator_status,
              ]);
            });
            this.getSjjxx(0);
          // } else {
          //   tjData = this.virtualData;
          // }
          _self.config = {
            rowNum: 13,
            headerBGC: "rgba(8, 90, 111, 0.2)",
            oddRowBGC: "rgba(14, 86, 241, 0.08)", //奇
            evenRowBGC: "", //偶
            header: ["设备编号", "升降机名称", "在线/离线"],
            data: tjData,
          };
        // });
      } catch (err) {
        _self.config = {
          rowNum: 13,
          headerBGC: "rgba(8, 90, 111, 0.2)",
          oddRowBGC: "rgba(14, 86, 241, 0.08)", //奇
          evenRowBGC: "", //偶
          header: ["设备编号", "升降机名称", "在线/离线"],
          data: this.virtualData,
        };
      }
    },
    // 塔机信息
    async getSjjxx(index) {
      let _self = this;
      // let url = myRequest.getRequestUrlWithAnyParam(
      //   "/api/homepage/elevator/info",
      //   `&elevator_no=${id}`
      // );
      // const res = await axios.get(url);
      // if (res.status === 200) {
        // let data = res.data.data;
        let data = this.staticData[index]
        this.sjjxxData = data;

        let status = _self.getStatus(data.system_state);
        let lock_direction = data.real_time_speed_direction;
        // real_time_speed_direction	：门锁方向 -	0停止 1上 2下
        _self.btmInfo[0].value = data.real_time_height;
        _self.btmInfo[1].value = data.real_time_weight;
        _self.btmInfo[2].value = data.real_time_gradient1; //倾斜度1
        _self.btmInfo[3].value = data.real_time_gradient2; //倾斜度2
        _self.btmInfo[4].value = data.real_time_number_of_people;
        _self.btmInfo[5].value = data.real_time_speed; //速度
        _self.btmInfo[6].value =
          lock_direction == 0 ? "停止" : lock_direction == 1 ? "上" : "下"; //后门锁状态

        // ------------- 颜色
        _self.btmInfo[0].style = status.height;
        _self.btmInfo[1].style = status.weight;
        _self.btmInfo[2].style = status.gradient1; //倾斜度
        _self.btmInfo[3].style = status.beforeLock; //前门锁
        _self.btmInfo[4].style = status.people;
        _self.btmInfo[5].style = status.speed; //速度
        _self.btmInfo[6].style = status.afterLock; //后门锁
        // ------------- 百分比
        _self.btmInfo[0].percentage = data.heightPercentage * 100 + "%";
        _self.btmInfo[1].percentage = data.weightPercentage * 100 + "%";
        _self.btmInfo[2].percentage = data.tilt_percentage1 * 100 + "%";
        _self.btmInfo[3].percentage = data.tilt_percentage2 * 100 + "%";
        _self.btmInfo[4].percentage = data.people_percentage * 100 + "%";
        _self.btmInfo[5].percentage = data.speed_percentage * 100 + "%";

        // ------------ 超载率和次数未返回
        _self.option1[0].value = data.hoisting_count;
        _self.option1[1].value = data.sos_count;
        _self.option1[2].value = data.overload_time;
        _self.option1[3].value = data.overload_rate;

        // 获取升降机状态
        this.optin.data[0].value = data.elevator_status;
        if (data.elevator_status == "离线") {
          this.optin.data[0].spin = false;
          this.optin.data[0].style =
            "width: 70px;right: 10px; top: 25px;color:rgba(78, 80, 82); font-size: 18px; ";
        } else {
          this.optin.data[0].spin = true;
          this.optin.data[0].style =
            "width: 70px;right: 10px; top: 25px;color:rgba(31, 240, 33); font-size: 18px; ";
        }
      // }
    },
    handleSjjlb(row) {
      this.getSjjxx(row.rowIndex);
    },
    getStatus(res) {
      //  system_state说明
      // 	0~1位重量,00正常,01预警,10报警
      // 	2~3位高度限位,00正常,01预警,10报警
      // 	4~5位超速,00正常,01预警,10报警
      // 	6~7位人数,00正常,01预警,10报警
      // 	8~9位倾斜,00正常,01预警,10报警
      // 	10位前门锁状态,0正常,1异常
      // 	11位后门锁状态,0正常,1异常
      
        console.log(res);
      let status = {
        weight: this.getStatusColor(res.slice(0, 2)),
        height: this.getStatusColor(res.slice(2, 4)),
        speed: this.getStatusColor(res.slice(4, 6)),
        people: this.getStatusColor(res.slice(6, 8)),
        gradient1: this.getStatusColor(res.slice(8, 10)),
        beforeLock: this.getStatusColor(res.slice(10, 11)),
        afterLock: this.getStatusColor(res.slice(11, 12)),
      };
      return status;
    },
    getStatusColor(code) {
      console.log(code);
      if (code == "00" || code == "0") {
        return 'background-color: #1ff021';
      } else if (code == "01") {
        return 'background-color: #dbda2c';
      } else if (code == "10" || code == "1") {
        return 'background-color: #d9360b';
      }
    }
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 1387px;
  height: 922px;
  display: flex;
  .left_container {
    width: 437px;
    height: 922px;
    // background-color: rgba(255, 192, 203, 0.4);
    .left1 {
      overflow: hidden;
      position: relative;
      width: 434px;
      height: 372px;
      // background-color: rgba(255, 206, 68, 0.4);
      .tjxx_title1 {
        width: 170px;
        height: 35px;
        margin: 7px auto 0;
        color: #fff;
        text-align: center;
        background: url("/img/source/标题框.png") center 0 no-repeat;
        background-size: 100% 100%;
        .echart-text {
          font-size: 16px;
          line-height: 35px;
        }
      }
      .tjxx_title2 {
        width: 170px;
        height: 35px;
        margin: 162px auto 0;
        color: #fff;
        text-align: center;
        background: url("/img/source/标题框.png") center 0 no-repeat;
        background-size: 100% 100%;
        .echart-text {
          font-size: 16px;
          line-height: 35px;
        }
      }
      .taji_text {
        position: absolute;
        top: 50px;
        left: 20px;
        padding: 2px 0 0 6px;
        width: 460px;
        height: 145px;
        p {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 2px;
          i {
            font-style: normal;
            color: #e8a36a;
          }
        }
      }
      .siji_text {
        position: absolute;
        top: 244px;
        left: 20px;
        padding: 2px 0 0 6px;
        width: 460px;
        height: 80px;
        p {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 2px;
          i {
            font-style: normal;
            color: #e8a36a;
          }
        }
      }
    }
    .left2 {
      width: 434px;
      height: 549px;
      padding-top: 46px;
      // background-color: rgba(255, 0, 0, 0.4);
      .scroll_board {
        width: 407px;
        margin: auto;
        height: 100%;
      }
    }
  }
  .right_container {
    position: relative;
    width: 950px;
    height: 922px;
    .right1 {
      display: flex;
      position: absolute;
      top: 62px;
      left: 0px;
      width: 950px;
      height: 50px;
      span {
        flex: 1;
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        text-align: center;
        color: #2cf314;
      }
    }
    .right2 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 950px;
      height: 230px;
      // background-color: #ffce44;
      .item_box {
        margin: 0 10px;
        .row1,
        .row2,
        .row4 {
          display: flex;
          margin-bottom: 10px;
        }
        .row1 {
          margin-left: 5px;
          .title1 {
            color: #3fc2ff;
            font-weight: 700;
            font-size: 20px;
          }
          .title_icon {
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
              margin-left: 25px;
            }
          }
        }
        .row2 {
          margin-left: 5px;
          font-size: 28px;
          font-weight: 700;
          color: #fff;
          .unit {
            margin-left: 10px;
            // margin-top: 8px;
            font-size: 16px;
            color: #00b8de;
            line-height: 40px;
          }
        }
        .row3 {
          width: 100%;
          margin-left: 5px;
          margin-top: -10px;
          .progress {
            position: relative;
            width: 90px;
            height: 3px;
            border-radius: 3px;
            background-color: #fff;
            .progress_bar {
              width: 80%;
              height: 3px;
              border-radius: 3px;
              background-color: #5f54a6;
            }
            .pervalue {
              position: absolute;
              top: -150%;
              right: -27%;
              font-size: 12px;
              color: #5eb0e8;
            }
          }
        }
        .row4 {
          width: 110px;
          height: 35px;
          margin-top: 20px;
        }
        .status-text {
          width: 110px;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.dv-scroll-board .header {
  font-size: 12px;
}
</style>