<template>
	<div>
		<el-form-item label="默认值">
			<avue-input v-model="main.activeOption.nullYNss"></avue-input>
		</el-form-item>
	</div>
</template>

<script>
	import { dicOption } from '@/option/config'
	export default {
	  data () {
	    return {
	      dicOption: dicOption
	    }
	  },
	  inject: ["main"]
	}
</script>

<style>
</style>