<template>
  <div>
    <div>
      <ul class="szWork-nav">
        <li
          v-for="(item, index) in navList"
          :key="index"
          @click="currentIndex = index"
        >
          <a :href="item.path">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  // 副导航组件
  name: "test3",
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      navList: [
        { title: "综合设备", path: "1466950338711023618" },
        { title: "环境检测", path: "1426004324638638082" },
        { title: "塔机监测", path: "1426006523200520194" },
        { title: "卸料平台监测", path: "1426007918725152769" },
        { title: "智能水电", path: "1426014126827749378" },
        { title: "升降机监测", path: "1426019598951628801" },
        { title: "视频监控", path: "1426016206795685890" },
      ],
    };
  },
  computed: {
    fontSize() {
      return (this.option.fontSize || 30) + "px";
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.szWork-nav {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  box-sizing: border-box;
  font-size: 15.2px;
  line-height: 33px;
  li {
    background-image: url("/img/border/border1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 0px;
    color: rgb(5, 246, 249);
    cursor: pointer;
    margin: 0 3px;
    padding: 0 8px;
    list-style: none;
    a {
      font-size: 16px;
      color: #39c9bd;
      &.active {
        color: rgb(242, 118, 7);
      }
    }
  }
}
</style>