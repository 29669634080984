<template>
  <div>
    <el-table
    :data="tableData"
    style="width: 100%;height: 180px;">
    <el-table-column
      prop="orderNo"
      label="领料单据"
      >
    </el-table-column>
    <el-table-column
      prop="receiverName"
      label="领料人"
      width="180"
      >
    </el-table-column>
    <el-table-column
      prop="outerDate"
      label="领料日期"
      width="130"
      >
    </el-table-column>
    <el-table-column
      prop="locationName"
      label="物料区域"
      width="180"
      >
    </el-table-column>
    <el-table-column
      prop="pickingStatusDesc"
      label="领料状态"
      width="110"
      >
    </el-table-column>
    <el-table-column
      label="领料出库"
      width="110"
      >
      <template slot-scope="scope">
        <el-button @click="cellClick(scope.row)" type="text">领料出库</el-button>
      </template>
    </el-table-column>
  </el-table>
  </div>
</template>

<script>
import { listwaitPickApply} from '@/api/erp';
import create from "../../create";
export default create({
  name: "tablePicking",
  data () {
    return {
      headerHeight: '',
      height: '',
      scrollCheck: "",
	  current: 1, // 当前页码
	  total: 0, // 总条数
	  size: 10, // 每页的数据条数
	  extentParam: {},
    input: '',
    orderNo: '',
    geOuterDate: '',
    leOuterDate: '',
    pickingStatus: '',
    tableData: []
    };
  },
  watch: {
    scrollSpeed () {
      this.setTime();
    },
    scroll: {
      handler () {
        this.setTime();
      },
    },
	dataChart() {
      setTimeout(() => {
		  if(this.dataChart&&this.dataChart.length>0&&this.option.pageShow) {

			   this.total=this.dataChart[0]._total
		  }

        this.setTime();
      }, this.scrollTime)
	}
  },
  computed: {
    scrollTime () {
      return this.option.scrollTime
    },
    scrollSpeed () {
      return this.option.scrollSpeed || 1
    },
    scroll () {
      return this.option.scroll
    },
    cellHeight () {
      return parseInt((this.height - this.headerHeight) / this.option.count)
    }
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created () {
    
    this.getList()
    this.$nextTick(() => {
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      // setTimeout(() => {
      //   this.setTime();
      // }, this.scrollTime)
      

    })
    window.addEventListener('message', function(e) {
      
      this.tableData = e.data
      console.log('自定义传值', e)
      
    })
  },
  mounted() {
  },
  methods: {
    getList(){
        listwaitPickApply(    window.$glob.mainToken.BladeAuth,window.$glob.mainToken.Authorization,window.$glob.mainToken.SwitchToCompanyId
       ).then((response) => {
           this.tableData = response.data.data
        })

    },
    handleClick(row) {
        console.log(row,987654321);
      },
	  handleSizeChange(val) {
		  console.log(`每页 ${val} 条`);
		  this.current = 1;
		  this.size = val;
	  },
	  //当前页改变时触发 跳转其他页
	  handleCurrentChange(val) {
		  console.log(`当前页: ${val}`);
		  this.current = val;
		  this.updateData({...this.extentParam, current: this.current});
	  },
	  mouseEnter(){
		  this.option.scroll = true
		  this.setTime()
	  },
	  mouseLeave(){
		  this.option.scroll = false
		  clearInterval(this.scrollCheck)
	  },
    cellClick (row, column, cell, event) {
      this.updateClick(row);
      this.clickFormatter && this.clickFormatter({
        type: column,
        item: cell,
		row,
        data: this.dataChart
      }, this.getItemRefs());
    },
    setTime () {
      if (this.option.count < this.dataChart.length) {
		  clearInterval(this.scrollCheck);
		  this.height = parseInt(this.$el.clientHeight);
		  this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
		  const table = this.$refs.table
		  const divData = table.bodyWrapper
		  const speed = this.scrollSpeed
		  let top = 0
		  if (this.scroll) {
			this.scrollCheck = setInterval(() => {
			  top = top + speed
			  divData.scrollTop += speed;
			  if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
				divData.scrollTop = 0
			  }
			  if (top >= this.cellHeight && this.scrollTime) {
				divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
				clearInterval(this.scrollCheck);
				setTimeout(() => {
				  this.setTime()
				}, this.scrollTime)
			  }
			}, 20)
		  } else {
			//divData.scrollTop = 0
		  }
	  }
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign: column.type == 'index' ? 'center' : this.option.bodyTextAlign,
        backgroundColor: rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      }
    },
    rowStyle ({ rowIndex }) {
      return {
        backgroundColor: 'transparent'
      }
    },
    headerRowStyle () {
      return {
        backgroundColor: this.option.headerBackground
      }
    },
    headerCellStyle ({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
      }
    }
  }
});
</script>
<style scoped lang='scss'>
	/deep/ .el-pagination .btn-prev {
	    padding-right: 5px;
	    margin-right: 5px;
	    margin-left: 5px;
		background-color: transparent;
		color: #fff;
		background-image: url(../../../../public/img/1.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	/deep/ .el-pagination .btn-next {
	    padding-left: 5px;
		margin-right: 5px;
		margin-left: 5px;
		background-color: transparent;
		color: #fff;
		background-image: url(../../../../public/img/1.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	/deep/ .el-pager li {
	    padding: 0 4px;
	    font-size: 13px;
	    min-width: 35.5px;
	    height: 28px;
	    line-height: 28px;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    text-align: center;
		margin-right: 5px;
		margin-left: 5px;
		background-color: transparent;
		color: #fff;
		background-image: url(../../../../public/img/1.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	/deep/ .el-pager li.active {
		color: #409EFF;
		cursor: default;
	}

  /deep/ .el-table {
    background-image: url(../../../../public/img/table_bg.png);
    background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
  }
  /deep/ .el-table th {
      background-color: transparent !important;
      color: #fff !important;
  }
  /deep/ .el-table td {
      background-color: transparent !important;
      color: #fff !important;
      border-bottom: none !important;
      padding: 0px !important;
  }
  // /deep/ .el-table td:first-child {
     
  //     color: #409EFF !important;
  // }
  /deep/ .el-table tr {
      background-color: transparent !important;
  }

/deep/ .el-table th.is-leaf {
    border-bottom: none !important;
}
/deep/ .el-table {
    background-color: transparent !important;
}
/deep/ .el-table__expanded-cell {
    background-color: transparent !important;
}
/deep/ .el-input__inner {
  background-image: url(../../../../public/img/1.png);
    background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
    border-color: transparent !important;
    background-color: transparent !important;
    color: #fff;
}
/deep/ .el-checkbox__inner {
  background-image: url(../../../../public/img/1.png);
    background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
  background-color: transparent !important;
    color: #fff !important;
    border-color: transparent !important;
}
/deep/ .el-table::before {display: none;}
/deep/ .el-button--text {padding: 0px !important;}
/deep/ .el-table .cell {line-height: 30px !important;}
/deep/ .el-table__body-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 130px;
}
</style>

