<template>
  <div class="chartimg">
    <div
      class="avue-draggable avue-draggable--click"
      datatype="0"
      v-for="(item, index) in option.data"
      :style="item.style"
      :key="index"
    >
      <slot></slot>
      <div class="avue-echart-img" style="position: relative">
        <img
          draggable="false"
          class="avue-echart-img avue-echart-img--rotate"
          style="opacity: 1; width: 100%; height: 100%"
          :style="item.spin ? 'animation-duration: 10s' : ''"
          :src="item.url"
        />
        <div class="echart-img-text">
          <div v-if="item.inner" class="echart-img__title">
            {{ item.title }}
          </div>
          <div v-html="item.value"></div>
        </div>
      </div>
      <div class="echart-img-title" v-if="!item.inner">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
//动态旋转图
export default {
  name: "chartimg",
  data() {
    return {};
  },
  props: {
    setStyle: {
      type: String,
      default: "",
    },
    option: {
      type: Object,
      default: {
        data: [
          {
            style: "width: 100px; height: 76px;left: 10px;",
            value: "文本", // 中间文字
            url: "/img/source/source219.svg", // 旋转图片路径
            title: "", // 图片下方文字
            inner: false, // 当未true时，图片下方文字会变成中间
            spin: false, // 是否开启旋转
          },
          {
            style: "width: 100px; height: 76px;left: 110px;",
            value: "文本",
            url: "/img/source/source219.svg",
            title: "",
            inner: false,
            spin: false, // 是否开启旋转
          },
        ],
      },
    },
  },
  mounted() {
    // let _self = this;
    // console.log(_self.option);
  },
};
</script>

<style lang='scss' scoped>
.chartimg {
  position: absolute;
  right: 20px;
  .echart-img-title {
    text-align: center;
    white-space: nowrap;
    color: rgb(57, 201, 189);
  }
  .echart-img-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-60%);
    margin: 0 auto;
    text-align: center;
    white-space: nowrap;
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    .echart-img__title {
      color: rgb(57, 201, 189);
      // font-size: 18px;
    }
  }
}
</style>
