<template>
  <el-row class="vs-left mflex">
    <el-col>
      <border-1 :option="{ title: '设备列表' }">
        <div class="scrollbar"
             style="height: 100%; overflow-y: auto">
          <el-tree :data="cameraList"
                   :props="defaultProps"
                   @node-click="handleNodeClick"
                   node-key="id"></el-tree>
        </div>
      </border-1>
    </el-col>
    <el-col>
      <border-1>
        <el-row>
          <el-col :span="8">
            <el-button type="primary"
                       @click="handleOptClick(0)"
                       plain>1X1</el-button>
          </el-col>
          <el-col :span="8">
            <el-button type="primary"
                       @click="handleOptClick(1)"
                       plain>2X2</el-button>
          </el-col>
          <el-col :span="8">
            <el-button type="primary"
                       @click="handleOptClick(2)"
                       plain>3X3</el-button>
          </el-col>
        </el-row>
      </border-1>
    </el-col>
  </el-row>
</template>

<script>
import border1 from './border1.vue'

export default {
  name: '',
  props: {},
  components: { border1 },
  data() {
    return {
      cameraList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeClickCount: 0,
      timer: null
    }
  },
  mounted() {
    let _self = this
    // let _cache = request.get_local_cache('urlCode')
    // axios.get('http://218.61.160.75:9228' +
    // axios
    //   .get(
    //     _cache.server +
    //       '/api/bim/sandbox/monitor/data?' +
    //       'uuid=' +
    //       _cache.uuid +
    //       '&id=' +
    //       '3d5a999b734248949ba91a8e18ddd72c'
    //   )
    //   .then(function (response) {
    //     if (response.data.code === 200) {
    //       let _url = request.getRequestUrl('/api/homepage/getProjectList')
    //       let urlCode = request.get_local_cache('urlCode')
    //       let project_id = urlCode.projectId
    //       axios.post(_url).then(function (response2) {
    //         let res = response2.data.data
    //         if (response2.data.code === '200') {
    //           for (let i = 0; i < res.length; i++) {
    //             if (res[i].id === project_id) {
    //               for (const child of response.data.data.children) {
    //                 if (res[i].simple === child.name) {
    //                   if (child.children.length > 0) {
    //                     for (const child1 of child.children) {
    //                       _self.cameraList.push({
    //                         id: child1.cameraUuid,
    //                         name: child1.name,
    //                         url: child1.url,
    //                         children: []
    //                       })
    //                     }
    //                   }
    //                   break
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       })
    //     } else {
    //       console.error(response.data.message)
    //     }
    //   })
    //   .catch(function (error) {
    //     console.error(error)
    //   })
  },
  methods: {
    handleNodeClick(data) {
      if (data.url !== undefined) {
        this.$emit('trans-data', data)
      }
    },
    handleOptClick(data) {
      this.$emit('trans-data2', data)
    }
  }
}
</script>
<style scoped lang="scss">
.vs-left {
  width: 100%;
  height: 100%;
  padding: 5px 0 5px 5px;
  flex-flow: row wrap;
  align-content: space-between;

  .el-col {
    height: 49.5%;
    margin-bottom: 8px;
  }

  .el-tree {
    background-color: #00000000;
    color: #fff;

    .el-tree-node {
      border: 1px solid #ddd;
    }

    .el-tree-node__content {
      height: 38px;
    }

    .el-tree-node__content > .el-tree-node__label {
      color: #fff;
    }

    .el-tree-node__content:hover {
      background-color: #00000000;
      color: #409eff;
    }

    .el-tree-node:focus > .el-tree-node__content {
      background-color: #00000000;
      color: #409eff;
    }

    .el-tree-node.is-current > .el-tree-node__content {
      color: #409eff;
      background-color: #00000000;
    }
  }
}
</style>
