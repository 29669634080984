<template>
  <div :class="b()">
    <el-table ref="table"
              @cell-click="cellClick"
              :data="dataChart"
              :height="height"
              :border="option.border"
              @mouseenter.native="mouseLeave"
              @mouseleave.native="mouseEnter"
              :cell-class-name="tableCellClassName"
              :cellStyle="cellStyle"
              :row-style="rowStyle"
              @selection-change="selectChange"
              :header-row-style="headerRowStyle"
              :header-cell-style="headerCellStyle">
      <el-table-column
          type="selection"
          v-if="option.showSelection"
          width="55">
      </el-table-column>
      <el-table-column type="index"
                       label="序号"
                       header-align="center"
                       align="center"
                       @click.native.stop
                       v-if="option.index"
                       width="60">
        <span slot-scope="{$index}">{{ $index + 1 }}</span>
      </el-table-column>
      <template v-for="(item,index) in option.column">
        <el-table-column v-if="item.hide!==true && item.edit !== true"
                         :show-overflow-tooltip="false"
                         :key='index'
                         @click.native.stop
                         :prop="item.prop"
                         :label="item.label"
                         :width="item.width">
        </el-table-column>
        <el-table-column v-if="item.edit === true && item.hide!==true && item.editType === 'select'"
                         :show-overflow-tooltip="false"
                         :key='index'
                         :prop="item.prop"
                         :label="item.label"
                         :width="item.width">
          <template slot-scope="scope">
            <el-select v-model="scope.row[item.prop]" placeholder="请选择"
                       :prop="'dataChart.' + scope.$index + '.'+[item.prop]"
                       @change="changeBinding(item.changeEvent,scope)">
              <el-option
                  v-for="items in dicOption[item.dicType] || scope.row[item.dynamicSelect]"
                  :key="items.value"
                  :label="items.label"
                  :value="items.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column v-if="item.edit === true && item.hide!==true && item.editType === 'input'"
                         :show-overflow-tooltip="false"
                         :key='index'
                         :prop="item.prop"
                         :label="item.label"
                         :width="item.width">
          <template slot-scope="scope">
            <el-input v-model="scope.row[item.prop]"
                      :prop="'dataChart.' + scope.$index + '.'+[item.prop]"
                      placeholder=""
                      @blur="blurBinding(item.blurEvent,scope,  scope.$index)"
                      @click.native.stop
                      @input="inputBinding(item.inputEvent, scope,  scope.$index, 'amount', 6)"
            ></el-input>
          </template>
        </el-table-column>
      </template>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          v-if="option.showControl"
          width="200"
      >
        <template slot-scope="scope">
          <el-button type="text">{{ option.controlText }}</el-button>
          <el-button type="text" @click.native.stop="deleteBinding( option.deleteEvent,scope)">{{ option.secondControlText }}</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div class="block" style="margin-top:15px;" v-show="option.pageShow">
      <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="current"
                     :page-size="size"
                     layout="  prev, pager, next "
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import create from "../../create";
import {dicOption} from '@/option/config'
import {getCalculatedFlowBatchList} from '@/api/erp'
import {accAdd, accDiv, accMul, accSub} from "@/utils/utils";
import {secureState} from '@/utils/utils'

export default create({
  name: "table",
  data() {
    return {
      currentClickIndex:null,
      totals:0,
      amounts:0,
      ids:[],
      selectedItems:[],
      dicOption: dicOption,
      headerHeight: '',
      height: '',
      scrollCheck: "",
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
      extentParam: {}
    };
  },
  watch: {
    scrollSpeed() {
      this.setTime();
    },
    scroll: {
      handler() {
        this.setTime();
      },
    },
    dataChart(val) {
      setTimeout(() => {
        if (this.dataChart && this.dataChart.length > 0 && this.option.pageShow) {
          this.total = this.dataChart[0]._total
        }

        this.setTime();
      }, this.scrollTime)

      this.$nextTick(() => {
        if(val && val.length>0){
          val.forEach((tableItem) => {
            if(tableItem.select === '1'){
              this.$refs.table.toggleRowSelection(tableItem, true)
            }
			if(tableItem.safetyHelmet === 1){//safetyHelmet是安全帽的判断
				if(tableItem.location){
					tableItem.locationList = tableItem.location
					let point = tableItem.location.mapLatitude + ',' + tableItem.location.mapLongitude
					var url = 'https://api.map.baidu.com/reverse_geocoding/v3/?ak=6bvwwj0ZMOaZfDlzr1NWytnfhGsB9WfV&output=json&coordtype=wgs84ll&location=' + point;
					this.$jsonp(url).then((response) =>{
						if(response.status === 0){
							tableItem.location = response.result.formatted_address
						}
					})
				}
				if(tableItem.alarmType){
					tableItem.alarmType = secureState(tableItem.alarmType)
				}
			}
          })
        }
      })
    }
  },
  computed: {
    scrollTime() {
      return this.option.scrollTime
    },
    scrollSpeed() {
      return this.option.scrollSpeed || 1
    },
    scroll() {
      return this.option.scroll
    },
    cellHeight() {
      return parseInt((this.height - this.headerHeight) / this.option.count)
    }
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      // setTimeout(() => {
      //   this.setTime();
      // }, this.scrollTime)
    })
  },
  mounted() {
  },
  methods: {

//删除
    handleProjectDelete(scope) {
      let currentIndex = scope.row.index
      let materielId = scope.row.materielId
      let deleteObj = {currentIndex:currentIndex,materielId:materielId}
      let deleteArray = []
      let localDeleteArray = JSON.parse(localStorage.getItem('localDeleteArrayxm'))
      var deleteId = []
      if (scope.row && scope.row.materielId) {
        deleteId = [scope.row.materielId]
      } else {
        deleteId = this.ids
      }
      this.$confirm('是否确认删除数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.dataChart =  this.dataChart.filter((item) => !deleteId.some((ele) => ele === item.materielId))
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            if(localDeleteArray === null || localDeleteArray.length === 0){
              deleteArray.push(deleteObj)
              localStorage.setItem('localDeleteArrayxm',JSON.stringify(deleteArray))
            }else{
              deleteArray = localDeleteArray
              deleteArray.push(deleteObj)
              localStorage.setItem('localDeleteArrayxm',JSON.stringify(deleteArray))
            }
          })
          .catch(() => {})
    },


    //删除
    handleDelete(scope) {
      let currentIndex = scope.row.index
      let materielId = scope.row.materielId
      let deleteObj = {currentIndex:currentIndex,materielId:materielId}
      let deleteArray = []
      let localDeleteArray = JSON.parse(localStorage.getItem('localDeleteArray'))
      var deleteId = []
      if (scope.row && scope.row.materielId) {
        deleteId = [scope.row.materielId]
      } else {
        deleteId = this.ids
      }
      this.$confirm('是否确认删除数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.dataChart =  this.dataChart.filter((item) => !deleteId.some((ele) => ele === item.materielId))
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            if(localDeleteArray === null || localDeleteArray.length === 0){
              deleteArray.push(deleteObj)
              localStorage.setItem('localDeleteArray',JSON.stringify(deleteArray))
            }else{
              deleteArray = localDeleteArray
              deleteArray.push(deleteObj)
              localStorage.setItem('localDeleteArray',JSON.stringify(deleteArray))
            }
          })
          .catch(() => {})
    },
    tableCellClassName({row,column,rowIndex,columnIndex}){
      row.index = rowIndex
      column.index = columnIndex
    },
    methodAdd(arg1,arg2){
      return accAdd(arg1,arg2)
    },
    methodDiv(arg1,arg2){
      return accDiv(arg1,arg2)
    },
    methodMul(arg1,arg2){
      return accMul(arg1,arg2)
    },
    methodSub(arg1,arg2){
      return accSub(arg1,arg2)
    },
    selectChange(rowsData) {
      this.ids = rowsData.map((item) => {
        return item.id
      })
      this.selectedItems = rowsData
    },
    /**
     * 绑定change事件
     */
    changeBinding(methodName, scope) {
      if (typeof (methodName) !== 'undefined') {
        this[methodName](scope)
      }
    },
    /**
     * 绑定blur事件
     */
    blurBinding(methodName, scope, index) {
      if (typeof (methodName) !== 'undefined') {
        this[methodName](scope, index)
      }
    }
    ,
    /**
     * 绑定input事件
     */
    inputBinding(methodName, scope, index, variable, digital) {
      if (typeof (methodName) !== 'undefined') {
        this[methodName](scope, index,variable, digital)
      }
    },

    /**
     * 绑定删除按钮事件
     */
    deleteBinding(methodName, scope) {
      if (typeof (methodName) !== 'undefined') {
        this[methodName](scope)
      }
    },


    editModifyStatus(scope) {
      let row = scope.row
      scope.row.amount = accDiv(Number(row.flowAmount) , scope.row.multiple)
      scope.row.total = accMul(Number(row.flowAmount), Number(row.outerPrice))
    },

    changeUnit(scope) {
      let row = scope.row
      let multiple = 1
      scope.row.materialBiBoardUnitVOList.forEach((item) => {
        if (item.value == row.unitCode) {
          multiple = item.multiple
        }
      })
      let rate = accDiv(Number(multiple), Number(row.multiple))
      scope.row.flowAmount = accMul(Number(row.flowAmount), rate)
      scope.row.outerPrice = accDiv(Number(row.outerPrice), rate)
      scope.row.remainingQuantity = accMul(Number(row.remainingQuantity), rate)
      scope.row.availableQuantity = accMul(Number(row.availableQuantity), rate)
      scope.row.multiple = multiple
      console.log(scope.row)
    }
    ,

    //限制 8整数 4位小数 / 2位小数
    changeInput(scope, name, digits) {
      this.selectionIds = []
      let row = scope.row
      // eslint-disable-next-line no-useless-escape
      scope.row[name] = row[name].replace(/[^\d.]/g, '') //清除非 数字和小数点的字符
      scope.row[name] = row[name].replace(/\.{2,}/g, '.') //清除第二个小数点
      scope.row[name] = row[name].replace(/^\./g, '') //验证第一个字符是数字而不是字符
      scope.row[name] = row[name].replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      scope.row[name] = digits == 6 ? row[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3') : row[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, '$1$2.$3') // 判断 保留4 / 2位小数
      scope.row[name] = row[name].indexOf('.') > 0 ? row[name].split('.')[0].substring(0, 8) + '.' + row[name].split('.')[1] : row[name].substring(0, 8) //限制只能输入8位正整数
    }
    ,
    calDetailData(scope,index, name,digits) {
      this.selectionIds = []
      let row = scope.row
      scope.row[name] = row[name].replace(/[^\d.]/g, '') //清除非 数字和小数点的字符
      scope.row[name] = row[name].replace(/\.{2,}/g, '.') //清除第二个小数点
      scope.row[name] = row[name].replace(/^\./g, '') //验证第一个字符是数字而不是字符
      scope.row[name] = row[name].replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      scope.row[name] = digits == 6 ? row[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3') : row[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, '$1$2.$3') // 判断 保留4 / 2位小数
      scope.row[name] = row[name].indexOf('.') > 0 ? row[name].split('.')[0].substring(0, 8) + '.' + row[name].split('.')[1] : row[name].substring(0, 8) //限制只能输入8位正整数




      let detailIndex = scope.$index
      let amount = row.amount
      if (amount > row.availableQuantity) {
        this.$message.warning('输入数量不能大于可用数量，已变更为为最大数量')
        this.dataChart[detailIndex].amount = row.availableQuantity
        amount = row.availableQuantity
      }
      // 自动计算批次问题
      let param = {
        materielId: row.materielId,
        storeId: row.storeId,
        amount: amount
      }
      // 获取批次
      getCalculatedFlowBatchList(param).then((res) => {
        this.dataChart[detailIndex].total = res.data.data.total
        this.dataChart[detailIndex].price = res.data.data.price
        this.dataChart[detailIndex].noTaxTotal = res.data.data.noTaxTotal
        this.dataChart[detailIndex].noTaxPrice = res.data.data.noTaxPrice
        this.dataChart[detailIndex].rateMoney = res.data.data.rateMoney
        this.dataChart[detailIndex].flowList = res.data.data.innerBatchList
        this.dataChart[detailIndex].locationName = res.data.data.locationName
      })
    }
    ,
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.current = 1;
      this.size = val;
    }
    ,
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.updateData({...this.extentParam, current: this.current});
    }
    ,
    mouseEnter() {
      this.option.scroll = true
      this.setTime()
    }
    ,
    mouseLeave() {
      this.option.scroll = false
      clearInterval(this.scrollCheck)
    }
    ,
    cellClick(row, column, cell, event) {
      this.currentClickIndex = row.index
      this.updateClick(row);
      this.clickFormatter && this.clickFormatter({
        type: column,
        item: cell,
        row,
        data: this.dataChart
      }, this.getItemRefs());
    }
    ,
    setTime() {
      if (this.option.count < this.dataChart.length) {
        clearInterval(this.scrollCheck);
        this.height = parseInt(this.$el.clientHeight);
        this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
        const table = this.$refs.table
        const divData = table.bodyWrapper
        const speed = this.scrollSpeed
        let top = 0
        if (this.scroll) {
          this.scrollCheck = setInterval(() => {
            top = top + speed
            divData.scrollTop += speed;
            if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
              divData.scrollTop = 0
            }
            if (top >= this.cellHeight && this.scrollTime) {
              divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
              clearInterval(this.scrollCheck);
              setTimeout(() => {
                this.setTime()
              }, this.scrollTime)
            }
          }, 20)
        } else {
          //divData.scrollTop = 0
        }
      }
    }
    ,
    cellStyle({row, column, rowIndex, columnIndex}) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign: column.type == 'index' ? 'center' : this.option.bodyTextAlign,
        backgroundColor: rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      }
    }
    ,
    rowStyle({rowIndex}) {
      return {
        backgroundColor: 'transparent'
      }
    }
    ,
    headerRowStyle() {
      return {
        backgroundColor: this.option.headerBackground
      }
    }
    ,
    headerCellStyle({row, column, rowIndex, columnIndex}) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
      }
    }
  }
});
</script>
<style scoped lang='scss'>
/deep/ .el-pagination .btn-prev {
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

/deep/ .el-pagination .btn-next {
  padding-left: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

/deep/ .el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

/deep/ .el-pager li.active {
  color: #409EFF;
  cursor: default;
}

/deep/ .el-input__inner {
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  border-color: transparent !important;
  background-color: transparent !important;
  color: #fff;
}

/deep/ .el-checkbox__inner {
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important;
}

</style>

