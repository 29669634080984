<template>
    <div>
        <div style="height: 550px;">
            <el-col span="8">
                <div>
                    <el-tabs type="border-card" v-model="activeName" @tab-click="switchProjectStatus">
                        <el-tab-pane label="在建项目" name="first">
                            <el-tree :data="runningCompanyTreeList" node-key="id" ref="runningTree" :expand-on-click-node="false" default-expand-all :props="defaultProps" @node-click="selectCompany">
                            </el-tree>
                        </el-tab-pane>
                        <el-tab-pane label="竣工项目" name="second">
                            <el-tree :data="overCompanyTreeList" node-key="id" ref="overTree" :expand-on-click-node="false" default-expand-all :props="defaultProps" @node-click="selectCompany">
                            </el-tree>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
            <el-col span="16">
                <el-form :model="queryParams" ref="queryForm" inline size="small">
                    <basic-input @enterQuery="handleQuery" labelSet="项目名称" propSet="projectName" :value.sync="queryParams.projectName" placeholder="请输入项目名称" />
                    <query-search-btn @click="handleQuery"></query-search-btn>
                </el-form>
                <div style="height: 415px;padding: 20px; overflow-y: auto">
                    <el-table :data="projectList" v-loading="loading" size="small">
                        <el-table-column label="#" type="index" align="center" width="80">
                        </el-table-column>
                        <el-table-column prop="id" label="选择" align="center" width="80">
                            <template slot-scope="scope">
                                <el-radio v-model="radioValue" :label="scope.row.id"><i></i></el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deptName" align="center" label="项目名称" />
                    </el-table>
                </div>
            </el-col>
        </div>
    </div>
</template>

<script>
import { getDept, getDeptAuthTree, getDeptAuthProject } from "@/api/system/dept"
import { mapGetters } from "vuex";
import QuerySearchBtn from "./QuerySearchBtn.vue";
import BasicInput from "./BasicInput.vue";

export default {
    components: { BasicInput, QuerySearchBtn },
    name: "TopOrgSwitch",
    data() {
        return {
            activeName: 'first',
            title: "",
            defaultProps: {
                children: "children",
                label: "deptName"
            },
            projectStatus: 1,
            runningCompanyTreeList: [],
            overCompanyTreeList: [],
            queryParams: {
                projectName: ""
            },
            selectedCompany: 0,
            projectList: [],
            radioValue: 0,
            selectedProjectId: 0,
            userDeptList: [{
                ancestors: "0",
                deptCategory: 0,
                deptName: "慧筑云",
                fullName: "慧筑云",
                id: "1",
                isDeleted: 0,
                parentId: "0",
                projectStatus: "",
                remark: "",
                sort: 1,
                tenantId: "000000"
            }],
        };
    },
    computed: {
        ...mapGetters(["tagWel", "tag"]),
    },
    created() {
        getDeptAuthTree(1).then((response) => {
            this.runningCompanyTreeList = response.data.data;
            this.overCompanyTreeList = response.data.data;
        });
        // this.initUserDept()
    },
    methods: {
        handleCommand(command) {
            let _self = this;
            let company = _self.userDeptList.filter(p => p.id === command);
            if (company.length > 0) {
                _self.title = company[0].deptName;
                _self.selectedProjectId = company[0].id;
                _self.$store.commit("SET_DEPT_SELECTED", _self.selectedProjectId);
            } else {
                _self.selectedProjectId = command;
                _self.$store.commit("SET_DEPT_SELECTED", command);
            }
            getDept(command).then((response) => {
                this.title = response.data.data.deptName
            })
            const tagUrl = this.tagWel.value;
            _self.$router.push({ path: tagUrl })
        },
        /**
         * 在建项目/竣工项目切换
         */
        switchProjectStatus(data) {
            this.radioValue = 0
            // 在建项目
            if (data.index == 0) {
                this.projectStatus = 1
                if (this.$refs.runningTree.getCurrentNode() != null) {
                    this.$refs.runningTree.setCurrentKey(null)
                    this.selectedCompany = 0
                }
                // 竣工项目
            } else if (data.index == 1) {
                this.projectStatus = 2
                if (this.$refs.overTree.getCurrentNode() != null) {
                    this.$refs.overTree.setCurrentKey(null)
                    this.selectedCompany = 0
                }
            }
            // 执行项目查询
            //this.handleQuery()
        },
        /**
         * 选中树形节点（公司）
         */
        selectCompany(data) {
            this.radioValue = 0
            if (data.deptCategory == 6) {
                this.selectedCompany = 0
                return;
            } else {
                if (this.userDeptList.length > 1 && data.id == 1) {
                    this.selectedCompany = 0
                    return;
                }
                this.selectedCompany = data.id
            }
            this.handleQuery()
        },
        /**
         * 查询项目
         */
        handleQuery() {
            this.loading = true
            this.radioValue = 0
            getDeptAuthProject(this.selectedCompany, this.queryParams.projectName, this.projectStatus).then((response) => {
                this.projectList = response.data.data
                this.loading = false
            })
        },

    }
}
</script>