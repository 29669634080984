<template>
  <div :class="b()" ref="main" style="display: flex; flex-direction: column;">
        <div class="tree-search-content" style="display: flex; align-items: center; flex-shrink: 0;">
            <div style="width: 80px; color: #fff; font-size: 16px; font-weight: 500; flex-shrink: 0;">计划名称:</div>
            <el-input placeholder="输入关键字进行过滤" v-model="filterText"  style="border: 1px solid #07DBFF; background-color: #1A245A; width: 246px;"></el-input>
        </div>
        
        <div style="overflow-y: auto; flex: 1; margin-top:10px">
            <el-tree ref="tree" 
            :data="dataChart" 
            :props="defaultProps" 
            node-key="id" 
            @node-click="handleNodeClick" 
            default-expand-all
            :filter-node-method="filterNode"
            :expand-on-click-node="isExpandOnClickNode"></el-tree>
        </div> 
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "tree",
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
        defaultProps: {
            children: 'children',
            label: 'label'
        },
        isExpandOnClickNode: false,
        filterText: ''
    };
  },
  created() {
    this.defaultProps.label = this.option.labelKey
  },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
  computed: {


  },

  methods: {
    filterNode(value, data) {
        if (!value) return true;
        return data[this.defaultProps.label].indexOf(value) !== -1;
    },
    handleNodeClick(val) {
      this.updateClick({
        value: val.id
      })
      this.clickFormatter &&
        this.clickFormatter(
          {
            data: val
          },
          this.getItemRefs()
        )
        console.log(val);
    }
  },

});
</script>

<style scope lang="scss">
    .el-tree {
        background: unset;
        color: #fff;
        /deep/ .el-tree-node__content  {
            background-color: unset;
        }
    }
</style>
<style scope>
    .el-tree-node:focus>.el-tree-node__content {
        background-color: unset;
    }
    .el-tree-node.is-current>.el-tree-node__content {
        background-color: unset;
    }
    .el-tree-node__content:hover {
        background-color: unset;
    }
    .el-tree-node__label {
        font-size: 14px;
    }
</style>
<style>
.tree-search-content .el-input__inner {
    background-color: #1A245A !important;
    border-color: #1A245A !important;
    height: 35px;
    line-height: 35px;
}
</style>


