<!-- 自定义配置 -->
<template>
    <div>

    </div>
</template>

<script>
    export default {
        inject: ["main"]
    }
</script>

<style>
</style>