<template>
  <div class="container">
    <left-side
      class="left"
      @trans-data="transData"
      @trans-data2="transData2"
    ></left-side>
    <right-side class="right" ref="vsMain"></right-side>
  </div>
</template>
<script>
import LeftSide from "./videoComps/LeftSide.vue";
import RightSide from "./videoComps/RightSide.vue";
export default {
  // 视频监控组件
  name: "test10",
  components: { LeftSide, RightSide },
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {};
  },
  methods: {
    transData(val) {
      this.$refs.vsMain.addVideo(val);
    },
    transData2(val) {
      this.$refs.vsMain.changeLayout(val);
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 1900px;
  height: 930px;
  display: flex;
  .left {
    width: 296px;
    height: 100%;
    margin-right: 8px;
    // background-color: yellow;
  }
  .right {
    flex: 1;
    height: 100%;
    // background-color: red;
  }
}
/deep/ .el-tree-node__content:hover {
  background-color: unset;
}
/deep/ .el-tree-node.is-current > .el-tree-node__content {
  color: #409eff;
  background-color: unset;
}
/deep/ .el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background: #0d2562 !important;
  border-color: #409eff !important;
}
</style>