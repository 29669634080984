<template>
  <div class="vs-main">

    <el-row id="layoutOne"
            class="vs-main"
            v-show="layout === 0"
            :gutter="5"
            type="flex">
      <el-col :span="24"
              style="height: 100%">
        <border-3>
          <div :id="hid"></div>
        </border-3>
      </el-col>
    </el-row>
    <el-row id="layoutFour"
            class="vs-main"
            v-show="layout === 1"
            :gutter="5"
            type="flex">
      <el-col :span="12"
              style="height: 50%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer2"></video>
        </border-3>
      </el-col>
      <el-col :span="12"
              style="height: 50%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer3"></video>
        </border-3>
      </el-col>
      <el-col :span="12"
              style="height: 50%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer4"></video>
        </border-3>
      </el-col>
      <el-col :span="12"
              style="height: 50%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer5"></video>
        </border-3>
      </el-col>
    </el-row>
    <el-row id="layoutNine"
            class="vs-main"
            v-show="layout === 2"
            :gutter="5"
            type="flex">
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer6"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer7"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer8"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer9"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer10"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer11"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer12"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer13"></video>
        </border-3>
      </el-col>
      <el-col :span="8"
              style="height: 33%">
        <border-3>
          <video class="video-js vjs-default-skin"
                 id="videoPlayer14"></video>
        </border-3>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import border3 from './border3.vue'
import videojs from 'video.js'
import 'videojs-contrib-hls'
import { uuid } from '@/utils/utils'

export default {
  components: { border3 },
  data() {
    return {
      allVideoPlayerSet: new Set(),
      videoPlayerStatus: [],
      videoList: [],
      // 布局 (0:1x1;1:4x4;2:3x3)
      layout: 0,
      reload: true,
      hid: 'main_' + uuid()
    }
  },
  methods: {
    addVideo(val) {
      if (this.layout === 0) {
        if (this.videoList.length > 0) {
          this.videoList[0].src(val.url)
          this.videoList[0].play()
        }
      } else if (this.layout === 1) {
        let _changeIndex = 0
        for (let i = 0; i < this.videoPlayerStatus.length; i++) {
          if (!this.videoPlayerStatus[i]) {
            this.videoPlayerStatus[i] = true
            this.videoList[i].src(val.url)
            this.videoList[i].play()
            _changeIndex = i
            break
          }
        }
        if (_changeIndex === this.videoPlayerStatus.length - 1) {
          for (let i = 0; i < this.videoPlayerStatus.length; i++) {
            this.videoPlayerStatus[i] = false
          }
        }
      } else {
        let _changeIndex = 0
        for (let i = 0; i < this.videoPlayerStatus.length; i++) {
          if (!this.videoPlayerStatus[i]) {
            this.videoPlayerStatus[i] = true
            this.videoList[i].src(val.url)
            this.videoList[i].play()
            _changeIndex = i
            break
          }
        }
        if (_changeIndex === this.videoPlayerStatus.length - 1) {
          for (let i = 0; i < this.videoPlayerStatus.length; i++) {
            this.videoPlayerStatus[i] = false
          }
        }
      }
    },
    changeLayout(val) {
      this.layout = val
    }
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.reload = true
      setTimeout(() => {
        new Clappr.Player({
          parentId: '#' + this.hid,
          source:
            'http://218.61.160.75:6713/mag/hls/c6e6ba54796c41d0b6a3c4fa6e50eead/1/live.m3u8',
          autoPlay: true,
          mute: true,
          height: '100%',
          width: '100%'
        })
      })
      console.log(this.hid, 99999)
    })
    // 初始化视频列表
    this.videoPlayerStatus.length = 0
    if (this.layout === 0) {
      // let player = videojs('videoPlayer1', {
      //   autoplay: false,
      //   preload: true,
      //   aspectRatio: '16:9',
      //   fluid: true,
      //   liveui: true,
      //   poster:
      //     'https://i-1-lanrentuku.qqxzb-img.com/2020/8/4/2fd4a87b-d1f6-48fe-8361-1619d22eeff0.jpg?imageView2/2/w/1024/',
      //   sources: [
      //     {
      //       src: 'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-uni4934e7b/c4d93960-5643-11eb-a16f-5b3e54966275.m3u8',
      //       type: 'application/x-mpegURL'
      //     }
      //   ]
      // })
      // this.videoList.push(player)
      // this.videoPlayerStatus.push(false)
      // this.allVideoPlayerSet.add(player)
    } else if (this.layout === 1) {
      for (let i = 2; i < 6; i++) {
        let player = videojs('videoPlayer' + i, {
          autoplay: false,
          preload: true,
          aspectRatio: '16:9',
          fluid: true,
          liveui: true,
          poster:
            'https://i-1-lanrentuku.qqxzb-img.com/2020/8/4/2fd4a87b-d1f6-48fe-8361-1619d22eeff0.jpg?imageView2/2/w/1024/',
          sources: [
            {
              src: 'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-uni4934e7b/c4d93960-5643-11eb-a16f-5b3e54966275.m3u8',
              type: 'application/x-mpegURL'
            }
          ]
        })
        this.videoList.push(player)
        this.videoPlayerStatus.push(false)
        this.allVideoPlayerSet.add(player)
      }
    } else {
      for (let i = 6; i < 15; i++) {
        let player = videojs('videoPlayer' + i, {
          autoplay: false,
          preload: true,
          aspectRatio: '16:9',
          fluid: true,
          liveui: true,
          poster:
            'https://i-1-lanrentuku.qqxzb-img.com/2020/8/4/2fd4a87b-d1f6-48fe-8361-1619d22eeff0.jpg?imageView2/2/w/1024/',
          sources: [
            {
              src: 'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-uni4934e7b/c4d93960-5643-11eb-a16f-5b3e54966275.m3u8',
              type: 'application/x-mpegURL'
            }
          ]
        })
        this.videoList.push(player)
        this.videoPlayerStatus.push(false)
        this.allVideoPlayerSet.add(player)
      }
    }
  },
  beforeDestroy() {
    if (this.allVideoPlayerSet.size > 0) {
      for (const player of this.allVideoPlayerSet) {
        player.dispose()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.vs-main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-flow: row wrap;
  align-content: space-between;
  .el-row {
    padding: 0;
    margin: 0;
  }

  .el-col {
    height: 48%;
  }

  .video-js {
    margin-top: 2px;
    width: 100%;
    height: 100%;
    .vjs-big-play-button {
      display: none !important;
    }
  }
}
</style>
