<template>
  <div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; height: 460px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="brand" label="品牌"> </el-table-column>
      <el-table-column prop="materielLocationName" label="物料区域">
      </el-table-column>
      <el-table-column prop="unitDesc" label="单位">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.unitCode"
            placeholder="请选择"
            @change="changeUnit(scope)"
          >
            <el-option
              v-for="item in scope.row.unitList"
              :key="item.unit"
              :label="item.unitDesc"
              :value="item.unit"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="批次号" width="220">
      </el-table-column>
      <el-table-column prop="flowAmount" label="数量">
        <template slot-scope="scope">
          <el-input
            placeholder=""
            v-model="scope.row.flowAmount"
            @blur="editModifyStatus(scope, scope.$index)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="outerPrice" label="批次单价（元）">
      </el-table-column>
      <el-table-column prop="availableQuantity" label="可用数量">
      </el-table-column>
      <el-table-column prop="remainingQuantity" label="库存数量">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { listgetInnerBatch, listwaitPickListCount } from "@/api/erp";
import create from "../../create";
import { accDiv, accMul } from "@/utils/utils2";
export default create({
  name: "tableBatch",
  data() {
    return {
      selectionIds: [],
      flowSelection: [],
      headerHeight: "",
      height: "",
      scrollCheck: "",
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
      extentParam: {},
      input: "",
      storeId: "",
      materielId: "",
      orderId: "",
      bladeAuthInfo: "",
      AuthorizationInfo: "",
      SwitchToCompanyIdInfo: "",
      unitList: [],
      ignoreRequestData: [],
      tableData: [],
      multipleSelection: [],
      checkRow: {},
      tableDataFlowList: [],
      amountNum: 0,
    };
  },
  watch: {
    scrollSpeed() {
      this.setTime();
    },
    scroll: {
      handler() {
        this.setTime();
      },
    },
    dataChart() {
      setTimeout(() => {
        if (
          this.dataChart &&
          this.dataChart.length > 0 &&
          this.option.pageShow
        ) {
          this.total = this.dataChart[0]._total;
        }

        this.setTime();
      }, this.scrollTime);
    },
  },
  computed: {
    scrollTime() {
      return this.option.scrollTime;
    },
    scrollSpeed() {
      return this.option.scrollSpeed || 1;
    },
    scroll() {
      return this.option.scroll;
    },
    cellHeight() {
      return parseInt((this.height - this.headerHeight) / this.option.count);
    },
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.getList();
    this.$nextTick(() => {
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(
        this.$refs.table.$refs.headerWrapper.clientHeight
      );
      // setTimeout(() => {
      //   this.setTime();
      // }, this.scrollTime)
    });
  },
  mounted() {},
  methods: {
    getList() {
      let _self = this;
      let query = window.location.search.substring(1);

      //let attachVal = window.attachVal

      query = query.split("&");
      for (let i = 0; i < query.length; i++) {
        let ele = query[i];
        let pair = ele.split("=");
        if (pair[0] && pair[0] === "amountNum") {
          this.amountNum = decodeURI(pair[1]);
          console.log(this.amountNum, 666555444);
        }
        if (pair[0] && pair[0] === "orderId") {
          this.orderId = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "storeId") {
          this.storeId = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "materielId") {
          this.materielId = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "Blade-Auth") {
          this.bladeAuthInfo = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "Authorization") {
          this.AuthorizationInfo = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "SwitchToCompanyId") {
          this.SwitchToCompanyIdInfo = decodeURI(pair[1]);
        }
        if (pair[0] && pair[0] === "ignoreRequestData") {
          const ignoreRequestDataStr = decodeURIComponent(pair[1]);
          this.ignoreRequestData = JSON.parse(ignoreRequestDataStr);
        }
      }
      this.checkRow = JSON.parse(localStorage.getItem("checkRow"));
      this.tableDataFlowList =
        JSON.parse(localStorage.getItem("tableDataFlowList")) || [];
      let batchMultipleSelection =
        JSON.parse(localStorage.getItem("batchMultipleSelection")) || [];
      listgetInnerBatch(
        this.bladeAuthInfo,
        this.AuthorizationInfo,
        this.SwitchToCompanyIdInfo
      ).then((response) => {
        _self.tableData = response.data.data.map((item) => {
          item.unitList = _self.checkRow.unitList;
          item.select = 0;
          for (let j in _self.tableDataFlowList) {
            if (
              item.innerDetailId === _self.tableDataFlowList[j].innerDetailId
            ) {
              item.flowAmount = _self.tableDataFlowList[j].flowAmount;
              item.select = 1;
            } else if (item.select == 0) {
              item.flowAmount = "";
            }
          }

          for (let i = 0; i < batchMultipleSelection.length; i++) {
            const batchItem = batchMultipleSelection[i];
            if (item.innerDetailId === batchItem.innerDetailId) {
              item.flowAmount = batchItem.flowAmount;
              item.select = 1;
            }
          }
          return item;
        });

        _self.selectionIds = _self.tableData.map((item) => {
          if (item.select == 1) {
            return item.id;
          } else {
            _self.flowSelection = [];
          }
        });

        _self.$nextTick(() => {
          if (_self.selectionIds.length > 0) {
            let checkedData = [];
            _self.selectionIds.forEach((id) => {
              _self.tableData.forEach((tableItem) => {
                if (tableItem.id && id == tableItem.id) {
                  _self.$refs.multipleTable.toggleRowSelection(tableItem, true);
                  checkedData.push(tableItem);
                }
              });
            });
            // localStorage.setItem(
            //   "batchMultipleSelection",
            //   JSON.stringify(checkedData)
            // );
          }
        });
      });
    },
    //单位改变
    changeUnit(scope) {
      let row = scope.row;
      let multiple = 1;

      this.tableData.forEach((item) => {
        item.materialBiBoardUnitVOList.forEach((items) => {
          if (items.unit == row.unitCode) {
            multiple = items.multiple;
          }
        });
      });
      let rate = accDiv(Number(multiple), Number(row.multiple));
      scope.row.flowAmount = accMul(Number(row.flowAmount), rate);
      scope.row.outerPrice = accDiv(Number(row.outerPrice), rate);
      scope.row.remainingQuantity = accMul(Number(row.remainingQuantity), rate);
      scope.row.availableQuantity = accMul(Number(row.availableQuantity), rate);
      scope.row.multiple = multiple;
    },
    editModifyStatus(scope) {
      let row = scope.row;
      if (row.flowAmount > row.availableQuantity) {
        this.$message.warning("输入数量不能大于可用数量，已变更为为最大数量");
        return;
      }
      scope.row.amount = accDiv(Number(row.flowAmount), scope.row.multiple);
      scope.row.total = accMul(Number(row.flowAmount), Number(row.outerPrice));
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.current = 1;
      this.size = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.updateData({ ...this.extentParam, current: this.current });
    },
    mouseEnter() {
      this.option.scroll = true;
      this.setTime();
    },
    mouseLeave() {
      this.option.scroll = false;
      clearInterval(this.scrollCheck);
    },
    cellClick(row, column, cell, event) {
      this.updateClick(row);
      this.clickFormatter &&
        this.clickFormatter(
          {
            type: column,
            item: cell,
            row,
            data: this.dataChart,
          },
          this.getItemRefs()
        );
    },
    setTime() {
      if (this.option.count < this.dataChart.length) {
        clearInterval(this.scrollCheck);
        this.height = parseInt(this.$el.clientHeight);
        this.headerHeight = parseInt(
          this.$refs.table.$refs.headerWrapper.clientHeight
        );
        const table = this.$refs.table;
        const divData = table.bodyWrapper;
        const speed = this.scrollSpeed;
        let top = 0;
        if (this.scroll) {
          this.scrollCheck = setInterval(() => {
            top = top + speed;
            divData.scrollTop += speed;
            if (
              divData.clientHeight + divData.scrollTop ==
              divData.scrollHeight
            ) {
              divData.scrollTop = 0;
            }
            if (top >= this.cellHeight && this.scrollTime) {
              divData.scrollTop = divData.scrollTop - (top - this.cellHeight);
              clearInterval(this.scrollCheck);
              setTimeout(() => {
                this.setTime();
              }, this.scrollTime);
            }
          }, 20);
        } else {
          //divData.scrollTop = 0
        }
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign:
          column.type == "index" ? "center" : this.option.bodyTextAlign,
        backgroundColor:
          rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      };
    },
    rowStyle({ rowIndex }) {
      return {
        backgroundColor: "transparent",
      };
    },
    headerRowStyle() {
      return {
        backgroundColor: this.option.headerBackground,
      };
    },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign:
          column.type == "index" ? "center" : this.option.headerTextAlign,
      };
    },
  },
});
</script>
<style scoped lang='scss'>
/deep/ .el-pagination .btn-prev {
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-pagination .btn-next {
  padding-left: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-pager li.active {
  color: #409eff;
  cursor: default;
}

/deep/ .el-table {
  background-image: url(../../../../public/img/table_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
/deep/ .el-table th {
  background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-table td {
  background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-table tr {
  background-color: transparent !important;
}
/deep/ .el-table td {
  border-bottom: none !important;
}
/deep/ .el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/ .el-table {
  background-color: transparent !important;
}
/deep/ .el-table__expanded-cell {
  background-color: transparent !important;
}
/deep/ .el-input__inner {
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  border-color: transparent !important;
  background-color: transparent !important;
  color: #fff;
}
/deep/ .el-checkbox__inner {
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important;
}
/deep/ .el-table::before {
  display: none;
}
/deep/ .el-table__body-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 386px;
}
</style>

