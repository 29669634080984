<template>
  <div class="cus-datepicker-container">
    <el-date-picker
      v-model="dateVal"
      prefix-icon="none"
      :size="option.datePickerSize"
      type="daterange"
      :clearable="false"
      :format="option.format"
      :value-format="option.dayParamFormat"
      :picker-options="pickerOptions"
      popper-class="layoutPopperClass"
      @change="dateChange"
      range-separator="至"
      :unlink-panels="true"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      placeholder="请选择"
      class="date-style"
    >
    </el-date-picker>
  </div>
</template>

<script>
/**
@description: 日期选择器
@createTime: 2022-05-10
@create: lixu
*/
import moment from "moment";
import create from "../../create";
export default create({
  name: "day",
  props: {
    option: Object,
    component: Object,
  },
  components: {},
  data() {
    return {
      dateVal: [],
    };
  },
  computed: {},
  watch: {
    dateVal: {
      handler(val) {
        if (!val || val.length == 0) {
          this.handleClick();
        }
      },
      deep: true,
    },
    "option.defaultValue": {
      handler(n) {
        let nowMoth = moment().format("YYYY-MM") + "-01";
        let nowDate = moment().format("YYYY-MM-DD");
        this.dateVal = n ? [nowMoth, nowDate] : "";
      },
      immediate: true,
    },
    "option.intercept": {
      handler(n) {
        let disabledDate = {
          // 返回禁用时间
          disabledDate(time) {
            return time.getTime() > Date.now(); // 可选历史天、可选当前天、不可选未来天
            // return time.getTime() > Date.now() - 8.64e7;  // 可选历史天、不可选当前天、不可选未来天
            // return time.getTime() < Date.now() - 8.64e7;  // 不可选历史天、可选当前天、可选未来天
            // return time.getTime() < Date.now(); // 不可选历史天、不可选当前天、可选未来天
          },
        };
        this.pickerOptions = n ? disabledDate : {};
      },
      immediate: true,
    }
  },
  mounted() {
    this.dateTimeRangeUpdateClick({ value: this.dateVal });
  },
  created() {},
  methods: {
    handleClick() {
      this.dateTimeRangeUpdateClick({ value: this.dateVal });
    },
    /* 日期选择器选定日期 */
    dateChange() {
      this.handleClick();
    },
  },
});
</script>
<style scoped lang="scss">
/deep/.input {
  background-color: unset !important;
}
/deep/ .el-input__inner {
  /*background: -webkit-linear-gradient(*/
  /*top left,*/
  /*rgba(0, 25, 83, 0.8),*/
  /*rgba(0, 3, 38, 0.8)*/
  /*);*/
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  /*border: 1px solid #2580dc !important;*/
  border: 1px solid transparent !important;
  color: #fff !important;
  width: 300px;
  .el-range-input {
    /*background: unset !important ;*/
    /*background: -webkit-linear-gradient(*/
    /*top left,*/
    /*rgba(0, 25, 83, 0.8),*/
    /*rgba(0, 3, 38, 0.8)*/
    /*);*/
    background-color: unset !important;
    background-image: url("../../../assets/Select-box.png") !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #c0c4cc !important;
    width: 45%;
  }
  .el-range-separator {
    color: #fff;
    height: unset;
  }
  .el-range__close-icon {
    width: 0;
  }
}
.date-style {
  width: 100%;
  height: 100%;
  /deep/.input {
    background-color: unset !important;
  }
  /deep/ .el-input__inner {
    background-color: #0b2255 !important;
  }
}
.layoutPopperClass {
  /deep/ .input {
    background-color: unset !important;
  }
}
.select-style {
  // border: 1px solid #2580dc !important;
  height: 32px !important;
  margin-right: 15px;
  /deep/ .input {
    background-color: unset !important;
  }
  /deep/ .el-input__inner {
    width: 105px !important;
    height: 32px;
    border-color: #07dbff !important; // 取消蓝边框
    background-color: #0b2255 !important;
  }
  /deep/ .el-input__icon {
    line-height: 29px !important;
  }
}
</style>
<style lang="scss">
.cus-datepicker-container {
  display: flex;
}
.cus-datepicker-container input {
  background-color: transparent !important;
}
// bim日期下拉样式
.layoutPopperClass {
  /deep/ .el-input__inner {
    background-color: #0b2255 !important;
  }
  background: -webkit-linear-gradient(
    top left,
    rgba(0, 25, 83, 0.8),
    rgba(0, 3, 38, 0.8)
  );
  border: 1px solid #2580dc;
  .el-date-range-picker__header {
    color: #c0c4cc;
    .el-date-picker__header-label {
      color: #c0c4cc;
    }
    .el-picker-panel__icon-btn {
      color: #c0c4cc !important;
    }
  }
  .el-date-table {
    .el-date-table__row {
      .in-range {
        div {
          background-color: #4978a9 !important;
        }
      }
    }
  }
  .el-month-table {
    .in-range {
      div {
        background-color: #4978a9 !important;
      }
    }
  }
  .el-picker-panel__content {
    .el-date-table {
      tr {
        th {
          color: #c0c4cc !important;
        }
      }
      .el-date-table__row {
        .prev-month {
          color: #fff !important;
        }
        .available {
          color: #c0c4cc !important;
        }
      }
    }
  }
  .el-picker-panel__footer {
    .popper__arrow {
      background-color: #1dd1f2 !important;
    }
  }
  .input {
    background-color: unset !important;
  }
  .el-range-input {
    background: unset !important;
    width: 45%;
  }
}
</style>
