<template>
	<div :class="b()" :style="{fontSize:fontSize,color:'#fff'}">
		<vue-scroll :ops="ops">
			<div class="table-header flex-row center">
				<div class="table-column">{{title[0]}}</div>
				<div class="table-column">{{title[1]}}</div>
				<div class="table-column">{{title[2]}}</div>
				<div class="table-column">{{title[3]}}</div>
			</div>
			<div style="width: 100%;height: 511px;">
				<div class="table-list margin-top" v-for="item in tableDate">
					<div @click="onCell(item)">
						<div class="table-content margin-top flex-row center">
							<div class="table-column">{{item.name}}{{item.userRosterId | ellipsis}}</div>
							<div class="table-column">{{item.creater}}</div>
							<div :class="[{'online':item.isState,'not-online':!item.isState},'table-column']">{{item.isState?'在线':'不在线'}}</div>
							<div class="table-column">
								<el-tooltip class="item" effect="dark" content="实时监控" placement="top-start">
									<div class="margin-left online el-icon-shipin" @click="onVideo(item)"></div>
								</el-tooltip>
								<el-tooltip v-if="type === 1" class="item" effect="dark" content="今日轨迹" placement="top-start">
									<div class="margin-left online el-icon-locus-full" @click="onTrajectory(item.toDateMapLattice)"></div>
								</el-tooltip>
								<el-tooltip v-if="type === 1" class="item" effect="dark" content="昨日轨迹" placement="top-start">
									<div class="margin-left online el-icon-lishiguiji" @click="onYesterdayTrajectory(item.historyMapLattice)"></div>
								</el-tooltip>
							</div>
						</div>
						<div class="table-content margin-top flex-row">
							<div :class="[{'online':item.isState,'not-online':!item.isState},'margin-left','el-icon-s-custom']"></div>
							<div :class="[{'online':item.isState,'not-online':!item.isState},'margin-left','el-icon-dianliang_huaban']">{{item.battery}}</div>
							<div :class="[{'online':item.isState,'not-online':!item.isState},'margin-left','el-icon-wendu']">{{item.temperature}}°C</div>
							<div :class="[{'online':item.isState,'not-online':!item.isState},'margin-left','el-icon-location']"></div>
						</div>
						<div class="line margin-top"></div> 
					</div>
				</div>
			</div>
		</vue-scroll>
	</div>
</template>

<script>
	import create from "@/echart/create";
	import vueScroll from "vuescroll";
	export default  create({ 
		name: 'detailTable',
		components:{
			vueScroll
		},
		props: {
		  option: Object,
		  component: Object
		},
		data() {
			return {
				isState:false,
				title:[],
				ops: {
				    mode: 'native',
				    bar: {
				      showDelay: 500,
				      onlyShowBarOnScroll: false,//是否只有滚动的时候才显示滚动条
				      keepShow: false,
				      background: '#1AB063',
				      opacity: 0.2,
				      hoverStyle: false,
				      specifyBorderRadius: false,
				      minSize: false,
				      size: '6px',
				      disable: false,
					  'overflow-x': 'hidden',
					}
				},
				tableDate:[],
				type:1,
			}
		},
		filters:{
			ellipsis(value){
				if (!value) return '';
				if (value.length > 7) {
					return '***' + value.substring(value.length - 6)
				}
				return value
			}
		},
		watch:{
			dataChart(val) {
				this.$nextTick(() => {
					this.title = val.title
					val.obj.forEach((tableItem) => {
						if(tableItem.name){
							this.tableDate.push(tableItem)
						}
					})
					this.type = val.type
					this.$bus.$emit('userInfoList',this.tableDate)
				})
			}
		},
		computed: {
		  fontSize() {
		    return (this.option.fontSize || 30) + 'px'
		  }
		},
		mounted(){
			
		},
		beforeDestroy(){
			
		},
		methods: {
			onCell(item){
				this.$bus.$emit('userInfo',item)
			},
			onVideo(item){
				this.$bus.$emit('showVideo',item)
			},
			onTrajectory(list){
				let data = []
				list.forEach((item) =>{
					let temp = {lng:item.mapLongitude,lat:item.mapLatitude}
					data.push(temp)
				})
				this.$bus.$emit('onTrajectory',data)
			},
			onYesterdayTrajectory(list){
				let data = []
				list.forEach((item) =>{
					let temp = {lng:item.mapLongitude,lat:item.mapLatitude}
					data.push(temp)
				})
				this.$bus.$emit('onYesterdayTrajectory',data)
			},
		},
	})
</script>

<style scoped lang="scss">
	.table-pages{
		color: white;
	}
	.table-header{
		width: 100%;
		height: 41px;
		font-weight: bold;
		font-size: 16px;
	}
	.flex-row{
		display: flex;
		flex-direction: row;
	}
	.table-column{
		width: 25%;
	}
	.table-list{
		width: 100%;
		font-size: 14px;
	}
	.table-content{
		width: 100%;
	}
	.center{
		text-align: center;
	}
	.online{
		color:greenyellow;
	}
	.not-online{
		color: darkred;
	}
	.margin-left{
		margin-left: 10px;
	}
	.line {
		width: 100%;
		height: 0px;
		border-bottom: white 1px dashed;
	} 
	.margin-top{
		margin-top: 10px;
	}
</style>