<template>
	<div :class="b()" :style="{fontSize:fontSize,color:'#fff'}">
		<div class="chart-content flex-center">
			<div :ref="id" :id="mychart" :style="myChartStyle"></div>
			<div class="chart-top flex-center">
				<div class="chart-data" v-for="(item,index) in chartName">{{item}}: 
					<el-tooltip effect="dark" :content="chartData[index]" placement="top-start">
						<span>{{chartData[index] | ellipsis | getZero}}</span>
					</el-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import create from "@/echart/create";
	import { uuid } from '@/utils/utils';
	export default create({
		name: 'customPie',
		props: {
		  option: Object,
		  component: Object
		},
		data() {
			return {
				mychart:'mychart_' + uuid(),
				//下列都是配置在请求过来的dataChart中的
				chartName:[],//图例显示的名称数组
				chartData:[],//图例显示的内容数组
				chartPie:[],//设置饼图环形数组，没有就是饼图
				isChartPercent:false,//饼图label是否显示百分比
				isRoseType:'',//是否南丁格尔

				clickData:{},
				myChartStyle: { width: "700px", height: "400px" }, //图表样式
			}
		},
		filters:{
			ellipsis(value){
				if (!value) return '';
				if (value.length > 10) {
					return value.slice(0,10) + '...'
				}
				return value
			},
			getZero(value){
				if (!value) {
					return 0
				}
				return value
			}
		},
		computed: {
			
		},
		mounted(){
			this.$bus.$on('clickData',(data)=>{
				this.clickData = data
			})
		},
		beforeDestroy(){
			this.$bus.$off('clickData')
		},
		methods:{
			updateChart(){
				this.getDetail()//先初始化一个信息
				const optionData = this.deepClone(this.dataChart.obj)
				this.chartName = this.dataChart.name
				this.chartPie = this.dataChart.pieStyle
				this.isChartPercent = this.dataChart.isChartPercent
				this.isRoseType = this.dataChart.isRoseType
				
				let emphasis = { //高亮的扇区和标签样式。
				    shadowBlur: 10,
				    shadowOffsetX: 0,
				    shadowColor: 'rgba(0, 0, 0, 0.5)',
					labelLine:{
						show:true,
						lineStyle: {
							color: '#999', // 设置标示线颜色
							width: 2 // 设置标示线宽度
						}
					},
					label:{
						formatter: (params) => {
							if(this.isChartPercent){
								if (params.name !== '') {
									let name = ''
									if (params.name.length > 9) {
										name = params.name.slice(0,9) + '...'
									}else{
										name = params.name
									}
									return `${name}` + `{${params.percent === undefined ? 0 : params.percent}%}`
								}
							}
							return `${params.name}`
						},
						show:true
					},
				}
				var option = MyEcharts.EchartsOption.pie("", "", optionData,this.chartPie,'');
				option.legend.show = false
				option.tooltip.show = false
				option.series[0].labelLine.show = false
				option.series[0].label.show = false
				option.series[0].emphasis = emphasis
				option.series[0].roseType = this.isRoseType
				let mychart = MyEcharts.initChart(option, this.mychart);
				if(Array.isArray(optionData) && optionData.length === 0){
					mychart.clear(); 
				}
				mychart.dispatchAction({
				  type: 'highlight',
				  seriesIndex: 0, // 图表中的第一个系列
				  dataIndex: 0, // 要高亮的数据项的索引
				});
				// 监听鼠标滑入事件
				mychart.on('mouseover', (e) => {
					this.getDetail(e)
					// 鼠标滑入取消整个系列高亮
					mychart.dispatchAction({
						type: 'downplay',
						seriesIndex: e.seriesIndex
					})
					// 然后高亮鼠标悬停的那块
					mychart.dispatchAction({
						type: 'highlight',
						dataIndex: e.dataIndex
					})
				})
				
				// 监听鼠标滑出事件
				mychart.on('mouseout', (e) => {
					// 鼠标滑出取消整个系列所有高亮
					mychart.dispatchAction({
						type: 'downplay',
						seriesIndex: e.seriesIndex
					})
					// 然后高亮鼠标最后悬停的那块
					mychart.dispatchAction({
						type: 'highlight',
						dataIndex: e.dataIndex
					})
				})
				window.addEventListener("resize", () => {
				    mychart.resize();
				});
				
			},
			getDetail(params){
				this.chartData = []
				let dict
				if(params){
					dict = this.dataChart.obj[params.dataIndex]
				}else{
					dict = this.dataChart.obj[0]
				}
				for(let key in dict){
					this.chartData.push(dict[key])
				}
			},
		}
	});
</script>

<style scoped lang="scss">
	.chart-content{
		flex-direction: row;
		width: 100%;
		height: 100%;
	}
	.chart-top{
		width: 30%;
		height: 100%;
		background: url("../../assets/u7766.png") no-repeat;
		background-size: 100% 100%;
		flex-direction: column;
	}
	.chart-data{
		margin: 10px;
		font-size: 16px;
		span{
			color: #1bcaff;
		}
	}
	.flex-center{
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>