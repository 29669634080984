<!-- 通用形配置 -->
<template>
  <div>
      <div></div>
      <el-form-item label="labelKey">
      <avue-input v-model="main.activeOption.labelKey"></avue-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  inject: ["main"]
}
</script>

<style>
</style>