<template>
  <div class="Flexible"
       :style="{fontSize:fontSize,color:'#fff'}">
    <!-- <h2>gg自定义组件</h2><br />
    <h3>我是参数:{{option}}</h3><br />
    <h3>data:{{dataChart}}</h3><br />
    <h3>params:{{(dataAxios.config || {}).params}}</h3><br /> -->
    <div class="flexbox-left">
      <div class="video-list">
        <div class="videotop">
          <div class="text">{{title.leftTitle}}</div>
          <img src="../../../public/hallVideoImg/titicon@2x.png"
               alt="">
        </div>
        <div style="height: 20px;"></div>
        <div class="videolistbox">
          <ul>
            <li v-for="(item, index) in aiboxs"
                @click="changeaiPoint(item.deviceId)">{{item.deviceName}}</li>
          </ul>
        </div>
        <div style="height: 2px;"></div>
        <div class="videolistbox">
          <ul>
            <li v-for="(item, index) in rtsplist"
                @click="clickhandleitem(item, index)">{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flexbox-right">
      <div class="videotop">
        <div class="text">{{title.rightTitle}}</div>
        <img src="../../../public/hallVideoImg/titicon@2x.png"
             alt="">
      </div>
      <div class="video-box">
        <div class="videoborder videoborder2"
             v-for="(i,index) in 4">
          <video autoplay
                 muted
                 style=" width:100%; height:100%;"
                 :id="'videoElement' + i"
                 :key="index"></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { videotoken, aiBoxInfoPage } from '@/api/video'
import flvjs from 'flv.js'
export default {
  name: 'videoHallAI',
  props: {
    option: Object,
    component: Object
  },
  data() {
    return {
      videoNum: 2,
      title: {
        leftTitle: '监控列表',
        rightTitle: '视频监控',
        controlTit: '控制台'
      },
      flvPlayer: null,
      inputvalue: '',
      devicename: '60',
      url: '',
      list: [],
      count: 1, // 当前点击标识
      flvPlayerList: [],
      datalist: [],
      aiboxs: [],
      rtsplist: [],
      deviceId: '',
      token: ''
    }
  },
  computed: {
    fontSize() {
      return (this.option.fontSize || 30) + 'px'
    }
  },
  methods: {
    addvideoPlaye(num) {
      // this.flvPlayerList=[]
      this.videoNum = num
    },
    changeaiPoint(deviceId) {
      this.deviceId = deviceId
      // axios.get(`/proxy/icvs/stream.flv?idx=0&stream=0&resType=IV&puid=201115203860404623&random=ai&token=${this.token}`).then((res) => {console.log('有：',res)})
      // axios.get(`/proxy/icvs/stream.flv?idx=0&stream=0&resType=IV&puid=${deviceId}&random=ai&token=${this.token}`).then((res) => {console.log('没有：',res)})
      this.rtsplist = [
        {
          name: '视频点位01',
          rtsp: `https://aicam.huizhuyun.com/icvs/stream.flv?idx=0&stream=0&resType=IV&puid=${deviceId}&random=ai&token=${this.token}`
        },
        {
          name: '视频点位02',
          rtsp: `https://aicam.huizhuyun.com/icvs/stream.flv?idx=1&stream=0&resType=IV&puid=${deviceId}&random=ai&token=${this.token}`
        },
        {
          status: 1,
          name: '视频点位03',
          rtsp: `https://aicam.huizhuyun.com/icvs/stream.flv?idx=2&stream=0&resType=IV&puid=${deviceId}&random=ai&token=${this.token}`
        },
        {
          status: 0,
          name: '视频点位04',
          rtsp: `https://aicam.huizhuyun.com/icvs/stream.flv?idx=3&stream=0&resType=IV&puid=${deviceId}&random=ai&token=${this.token}`
        }
      ]
    },
    clickhandleitem(data, index) {
      let ip = data.ipAddress
      let admin = data.videoname
      let password = data.videopas

      this.url = this.rtsplist[index].rtsp
      console.log(this.flvPlayerList, 'flvPlayerList')
      if (this.flvPlayerList.length >= Math.pow(this.videoNum, 2)) {
        this.destoryVideo(this.flvPlayerList[0])
        this.flvPlayerList.shift()
      }
      this.createVideo()
      console.log(index)
      this.count >= Math.pow(this.videoNum, 2) ? (this.count = 1) : this.count++
    },
    createVideo() {
      console.log(this.count, 'videoElement' + this.count)
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById('videoElement' + this.count)
        this.flvPlayer = flvjs.createPlayer(
          {
            type: 'flv', // => 媒体类型 flv 或 mp4，m3u8
            isLive: true, // => 是否为直播流
            hasAudio: false, // => 是否开启声音
            url: this.url, // => 视频流地址
            stashInitialSize: 128 // 减少首桢显示等待时长
          },
          {
            enableWorker: false, //不启用分离线程
            enableStashBuffer: false, //关闭IO隐藏缓冲区
            reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
            autoCleanupSourceBuffer: true, //自动清除缓存
            fixAudioTimestampGap: false //false才会音视频同步
          }
        )

        this.flvPlayer.attachMediaElement(videoElement)
        // this.flvPlayer.load();

        if (this.url !== '' && this.url !== null) {
          this.flvPlayer.load()
          this.flvPlayer.play()
        }
      }

      //定时方法是为了用户离开页面视频是实时播放的,暂停按钮无用
      // setInterval(function() {
      //   // console.log(videoElement.buffered,"idididid");
      //   if (videoElement.buffered.length > 0) {
      //     const end = videoElement.buffered.end(0); // 视频结尾时间
      //     const current = videoElement.currentTime; //  视频当前时间
      //     const diff = end - current; // 相差时间
      //     // console.log(diff);
      //     const diffCritical = 4; // 这里设定了超过4秒以上就进行跳转
      //     const diffSpeedUp = 1; // 这里设置了超过1秒以上则进行视频加速播放
      //     const maxPlaybackRate = 4; // 自定义设置允许的最大播放速度
      //     let playbackRate = 1.0; // 播放速度
      //     if (diff > diffCritical) {
      //       //  this.flvPlayer.load();
      //       // console.log("相差超过4秒，进行跳转");
      //       videoElement.currentTime = end - 1.5;
      //       playbackRate = Math.max(1, Math.min(diffCritical, 16));
      //     } else if (diff > diffSpeedUp) {
      //       // console.log("相差超过1秒，进行加速");
      //       playbackRate = Math.max(1, Math.min(diff, maxPlaybackRate, 16));
      //     }
      //     videoElement.playbackRate = playbackRate;
      //     if (videoElement.paused) {
      //       videoElement.play();
      //     }
      //   }
      //  if (videoElement.buffered.length) {
      //   let end = this.flvPlayer.buffered.end(0);//获取当前buffered值
      //   let diff = end - this.flvPlayer.currentTime;//获取buffered与currentTime的差值
      //   if (diff >= 0.5) {//如果差值大于等于0.5 手动跳帧 这里可根据自身需求来定
      //     this.flvPlayer.currentTime = this.flvPlayer.buffered.end(0);//手动跳帧
      //  }
      // }
      // }, 1000);

      this.flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) => {
        // alert("网络波动,正在尝试连接中...");
        if (this.flvPlayer) {
          // this.reloadVideo(this.flvPlayer);
        }
        // errType是 NetworkError时，对应errDetail有：Exception、HttpStatusCodeInvalid、ConnectingTimeout、EarlyEof、UnrecoverableEarlyEof
        // errType是 MediaError时，对应errDetail是MediaMSEError   或MEDIA_SOURCE_ENDED
      })
      this.flvPlayerList.push(this.flvPlayer)
    },

    reloadVideo(flvPlayer) {
      this.destoryVideo(flvPlayer)
      this.createVideo()
    },
    destoryVideo(flvPlayer) {
      flvPlayer.pause()
      flvPlayer.unload()
      flvPlayer.detachMediaElement()
      flvPlayer.destroy()
      flvPlayer = null
    }
  },
  mounted() {
    if (this.$route.query.projectId) {
      aiBoxInfoPage()
        .then((res) => {
          this.aiboxs = res.data.data.records
          if (res.data.data.records[0].id) {
            this.deviceId = res.data.data.records[0].deviceId
            if (this.aiboxs.length > 0) {
              videotoken({
                user: 'admin',
                epid: 'system',
                fixaddr: '1',
                address: '127.0.0.1',
                port: '9988',
                password: ''
              })
                .then((res) => {
                  this.token = res.data.token
                  this.changeaiPoint(this.deviceId)
                  for (var i = 0; i < 4; i++) {
                    this.clickhandleitem(this.rtsplist[i], i)
                  }
                })
                .catch((err) => {
                  console.log(err)
                  loading.close()
                })
            }
          }
        })
        .catch((err) => {
          this.aiboxs = []
          this.rtsplist = []
          loading.close()
        }) // 初始化视频列表
    } else {
      this.aiboxs = []
      this.rtsplist = []
    }

    //
  }
}
</script>
<style scoped lang="scss">
ul,
li {
  list-style: none;
}
button {
  /*消除button的默认样式*/ /*这种写法是对所有的button标签同时生效*/
  margin: 0px;
  padding: 0px; /*自定义边框*/
  border: 0px; /*消除默认点击蓝色边框效果*/
  outline: none;
}
.Flexible {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.flexbox-left {
  width: 26%;
  height: 100%;
  // border: 1px solid red;
  margin-right: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 auto;
  .video-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
    flex: 1 1 auto;
    .videotop {
      display: flex;
      align-items: center;
      width: 100%;
      height: 41px;
      flex: 0 0 auto;
      background: url('../../../public/hallVideoImg/titBgs@2x.png') no-repeat;
      background-size: 100% 100%;
      .text {
        font-size: 25px;
        margin: 0 10px 0 40px;
      }
      img {
        width: 103px;
        height: 17px;
      }
    }
    .videolistbox {
      width: 100%;
      flex: 1 1 auto;
      background: url('../../../public/hallVideoImg/tablebg@2x.png') no-repeat;
      background-size: 100% 100%;
      overflow-y: scroll;
      margin-bottom: 18px;
      ul {
        margin: 4px 20px;
      }
      li {
        font-size: 16px;
        height: 41px;
        line-height: 41px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
  .controlbox {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .videotop {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      flex: 0 0 auto;
      background: url('../../../public/hallVideoImg/titBgs@2x.png') no-repeat;
      background-size: 100% 100%;
      margin-bottom: 20px;
      .text {
        font-size: 25px;
        margin: 0 10px 0 40px;
      }
      img {
        width: 103px;
        height: 17px;
      }
    }
    .controls {
      width: 100%;
      flex: 1 1 auto;
      background: url('../../../public/hallVideoImg/tablebg@2x.png') no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      .controlsitem {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px;
        .button {
          width: 125px;
          height: 41px;
          background: url('../../../public/hallVideoImg/controlBtnbg@2x.png')
            no-repeat;
          background-size: 100% 100%;
          color: #ffffff;
        }
        .switch__input {
          width: 40px;
          height: 20px;
        }
      }
      .btnbg {
        position: relative;
        text-align: center;
        line-height: 195px;
        width: 195px;
        height: 195px;
        background: url('../../../public/hallVideoImg/controlicon@2x.png')
          no-repeat;
        background-size: 100% 100%;
        // .btnimg leftbtn
        .btnimg {
          width: 50px;
          height: 50px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .leftbtn {
          top: 74px;
          left: 10px;
        }
        .topbtn {
          top: 10px;
          left: 74px;
        }
        .rightbtn {
          top: 74px;
          left: 136px;
        }
        .bottombtn {
          top: 136px;
          left: 74px;
        }
      }
      .controlsitembtn {
        width: 140px;
        height: 195px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        line-height: 16px;
        img {
          width: 16px;
          height: 16px;
          margin: 0 10px;
        }
      }
    }
  }
}
.flexbox-right {
  height: 100%;
  flex: 1 1 auto;
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  .videotop {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 41px;
    background: url('../../../public/hallVideoImg/titBgL@2x.png') no-repeat;
    background-size: 100% 100%;
    flex: 0 0 auto;
    margin-bottom: 20px;
    .text {
      font-size: 25px;
      margin: 0 10px 0 40px;
    }
    img {
      width: 103px;
      height: 17px;
    }
    .rightbtn {
      position: absolute;
      top: 7px;
      right: 10px;
      img {
        width: 26px;
        height: 26px;
        margin: 0 10px;
      }
    }
  }
  .video-box {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoborder {
      background: url('../../../public/hallVideoImg/videoBg@2x.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 1px;
      video {
        width: 100%;
        height: 100%;
      }
    }
    .videoborder1 {
      width: 100%;
      height: 100%;
    }
    .videoborder2 {
      width: 49.3%;
      height: 47.2%;
    }
    .videoborder3 {
      width: 33.14%;
      height: 32.8%;
    }
    .videoborder4 {
      width: 24.76%;
      height: 24.6%;
    }
  }
}
</style>