<template>
  <div :class="b()" ref="main">
	<el-popover
	    placement="top-start"
	    width="500"
	    trigger="hover">
		<div id="text-wrapper" v-html="dataChart.value">
		</div>
	    <div class="el-icon-question button-color" slot="reference"></div>
	</el-popover>
  </div>
</template>

<script>
import create from "../../create";
export default create({
	data() {
	  return {
		  
	  };
	},
	name: "popupQuestion",
	watch: {
		dataChart(newValue, oldValue) {
			const replacedText = newValue.value.replace(/\[/g, '<').replace(/\]/g, '>');
			this.dataChart.value = replacedText
			console.log(this.dataChart.value)
		}
	},
	methods: {
		ellipsis(value){
			if (!value) return '';
			if (value.length > 10) {
				return value.slice(0,10) + '...'
			}
			return value
		},
	},
	mounted() {
		
	}

});
</script>
<style>
	.el-popover--plain {
		white-space: pre-wrap !important;
		color: #fff;
	}
	.el-popover{
		background: url("../../../assets/dialog-bg.png") no-repeat !important;
		background-size: 100% 100% !important;
		border:none !important;
	}
	.el-popover__title{
		color: #fff;
	}
</style>
<style scope lang="scss">
	.button-color{
		color: #fff;
	}
	#text-wrapper{
		color: #fff;
	}
</style>


