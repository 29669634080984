<template>
  <div class="content">
      <div class="pj-select">
        <el-input class="search" v-model="searchValue" placeholder="请输入公司名称" suffix-icon="el-icon-search" clearable>
          <!--<div style="height: 100%; padding-right: 5px; display: flex;" slot="suffix"><i class="el-icon-search" style="margin: auto"></i></div>-->
        </el-input>
        <div class="list">
          <p v-for="(company, index) in showCompanyList" :key="index"  class="cell">
            <img  src="../../assets/pj-item-pre.png" alt="">
            <a style="cursor:pointer" @click="viewBord(company.id)">{{company.name}}</a>
          </p>
        </div>

      </div>
  </div>
</template>

<script>
import { getCompanyListByCreateUser } from '@/api/erp';
import {setStore, getStore} from '@/utils/store'

export default {
  data() {
    return {
      bordTypeList: [
        //'1528973677142634498', // 项目综合看板
        //'1529743932630659074', // 项目经营看板
        //'1529743541931241474', // 项目劳务看板
        //'1528976646164606977', // 项目设备看板
        //'1528967431748481026', // 项目物料看板
        //'1541597388928610306', // 项目质量安全看板
        '1602477232083853314', //新经营看板
        '1606115436984950786', //新总览看板
        '1550003900990517250', // 公司综合看板
        '1544859298851614722', // 公司经营看板
        '1544127550178095105', // 公司劳务看板
        '1549955992480018433', // 公司设备看板
        '1550028163495653377', // 公司物料看板
          '1648883738009931777', // 公司物料看板（可操作）
        '1543878624673951746', // 公司质量安全看板
        '1641393692679868417', //仓库看板
      ],
      companyList: [],
      bordType: 0,
      createUser: null,
      searchValue: ''
    }
  },
  created() {
    // 获取URL参数
    var query = this.$route.query;
    // URL参数中看板类型和用户ID不存在时提示错误信息
    if (query == null || query == undefined || query.createUser == null || query.createUser == undefined || query.bordType == null || query.bordType == undefined) {
      this.$message({ showClose: false, message: "参数错误，请从正确渠道访问本页面！", type: "error" })
      return
    } else {
      // 获取用户ID
      this.createUser = query.createUser
      // 获取看板类型
      this.bordType = query.bordType
      // 如果看板类型是数字
      if (this.bordType.match("^\\d+$")) {
        // 转换看板类型为数值
        const type = parseInt(this.bordType)
        // 如果看板类型在看板List里不存在
        if (this.bordTypeList.length <= type) {
          this.$message({ showClose: false, message: "您要查看的看板不存在，请确认后重新选择！", type: "error" })
          return
        } else {
          // 查看客户端是否已经有看板访问记录
          const obj = getStore({name: 'huizhuyun_bibord_default_company_' + this.createUser + '_' + this.bordType})
          // 如果有直接跳转到对应项目的看板
          if (obj) {
            this.viewBord(obj)
          }
        }
        // 如果看板类型不是数字，提示错误消息
      } else {
        this.$message({ showClose: false, message: "参数错误，请从正确渠道访问本页面！", type: "error" })
        return
      }
      // 根据用户ID获取用户项目列表
      getCompanyListByCreateUser({"createUser": this.createUser, "deptName": ""}).then((response) => {
        if (response.status == 200 && response.data.code == 200) {
          this.companyList = response.data.data
          // 如果该用户没有参与任何项目，提示错误信息
          if (this.companyList.length == 0) {
            this.$message({ showClose: false, message: "您没有参与到任何项目，请向公司管理人员确认！", type: "error" });
            return
          }
        } else {
          // 接口错误提示
          this.$message({ showClose: false, message: "服务连接失败，请重试或联系管理员！", type: "error" })
          return
        }
      })
    }

  },
  computed: {
      showCompanyList() {
          return this.companyList.filter(item=> item.name.includes(this.searchValue));
      }
  },
  methods: {
    /**
     * @param 项目ID
     * 看板跳转处理
     */
    viewBord(projectId) {
      // 根据看板类型获取看板ID
      const bordTypeSend = this.bordTypeList[this.bordType]
      // 将项目ID存储到本地Storage
      setStore({name: 'huizhuyun_bibord_default_company_' + this.createUser + '_' + this.bordType, content: projectId})
      // 跳转到指定看板
      this.$router.replace({path: '/view/' + bordTypeSend, query: {companyId: projectId, createUser: this.createUser}})
    }
  }
}
</script>

<style lang="scss" scope>
  .content {
    height: 100%;
    background-image: url("../../assets/pj-bg.png");
    background-size: 100% 100%;
    display: flex;


    .pj-select {
      margin: auto;
      width: 363px;
      height: 72vh;
      background-image: url("../../assets/pj-select-bg.png");
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      .search {
        width: 80%;
        margin: 30px;
        background-image: url("../../assets/pj-search-bg.png");
        background-size: 100% 100%;
      }
      .list {
        flex: 1;
        padding: 0 22px;
        overflow-y: scroll;
        .cell {
          min-height: 40px;
          display: flex;
          align-items: flex-start;
          img {
            width: 25px;
            height: 25px;
          }
          a {
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }



  }
</style>
<style>
  .pj-select .search input {
    background-color: transparent !important;
  }
</style>
