<template>
  <div class="Flexible" :style="{fontSize:fontSize,color:'#fff'}">
    <!-- <h2>gg自定义组件</h2><br />
    <h3>我是参数:{{option}}</h3><br />
    <h3>data:{{dataChart}}</h3><br />
    <h3>params:{{(dataAxios.config || {}).params}}</h3><br /> -->
	<div class="flexbox-left">
		<div class="video-list">
			<div class="videotop">
				<div class="text">{{title.leftTitle}}</div>
				<img src="../../../public/hallVideoImg/titicon@2x.png" alt="">
			</div>
			<div class="videolistbox">
				<ul>
					<li v-for="item in videolist" @click="videoPlaye(item)">{{item.name}}<span style="float: right;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #B0B1B1;" v-if="item.onLineStatus==0">离线</span><span style="float: right;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #00FFF6;" v-else>在线</span></li>
				</ul>
			</div>
		</div>
		<div class="controlbox">
			<div class="videotop">
				<div class="text">{{title.controlTit}}</div>
				<img src="../../../public/hallVideoImg/titicon@2x.png" alt="">
			</div>
			<div class="controls">
				<div class="controlsitem">
					<div>清晰度</div>
					<img class="switch__input" src="../../../public/hallVideoImg/switch@2x.png" alt="">
				</div>
				<div class="controlsitem">
					<div>云台速度</div>
					<div style="width: 200px;">
						<el-progress color="#00FFF6" :percentage="50"></el-progress>
					</div>
					
				</div>
				<div class="controlsitem">
					<div class="btnbg">
						云控制台
						<div class="btnimg leftbtn"><img src="../../../public/hallVideoImg/iconleft@2x.png" alt=""></div>
						<div class="btnimg topbtn"><img src="../../../public/hallVideoImg/iconup@2x.png" alt=""></div>
						<div class="btnimg rightbtn"><img src="../../../public/hallVideoImg/iconright@2x.png" alt=""></div>
						<div class="btnimg bottombtn"><img src="../../../public/hallVideoImg/icondownB@2x.png" alt=""></div>
					</div>
					<div class="controlsitembtn">
						<div><img src="../../../public/hallVideoImg/controladd@2x.png" alt="">变倍<img src="../../../public/hallVideoImg/controlsub@2x.png" alt=""></div>
						<div><img src="../../../public/hallVideoImg/controladd@2x.png" alt="">光圈<img src="../../../public/hallVideoImg/controlsub@2x.png" alt=""></div>
						<div><img src="../../../public/hallVideoImg/controladd@2x.png" alt="">聚焦<img src="../../../public/hallVideoImg/controlsub@2x.png" alt=""></div>
					</div>
				</div>
				<div class="controlsitem" style="padding:10px 68px;">
					<button class="button">视频回放</button>
					<button class="button">裁剪</button>
				</div>
			</div>
		</div>
	</div>
	<div class="flexbox-right">
		<div class="videotop">
			<div class="text">{{title.rightTitle}}</div>
			<img src="../../../public/hallVideoImg/titicon@2x.png" alt="">
			<div class="rightbtn">
				<img @click="addvideoPlaye(1)" :style="videoNum !=1?'opacity:0.3;':'opacity:1;'" src="../../../public/hallVideoImg/viconx1@2x.png" alt="">
				<img @click="addvideoPlaye(2)" :style="videoNum !=2?'opacity:0.3;':'opacity:1;'" src="../../../public/hallVideoImg/viconx2@2x.png" alt="">
				<img @click="addvideoPlaye(3)" :style="videoNum !=3?'opacity:0.3;':'opacity:1;'" src="../../../public/hallVideoImg/viconx3@2x.png" alt="">
				<img @click="addvideoPlaye(4)" :style="videoNum !=4?'opacity:0.3;':'opacity:1;'" src="../../../public/hallVideoImg/viconx4@2x.png" alt="">
			</div>
		</div>
		<div class="video-box">
			<video id="my-player" class="video-js vjs-default-skin vjs-big-play-centered" style="width: 100%;height: 100%;" controls preload="auto"  data-setup='{}'>    
			     <source id="source" src="http://218.61.160.75:6713/mag/hls/c6e6ba54796c41d0b6a3c4fa6e50eead/1/live.m3u8"  type="application/x-mpegURL">
			</video>
			<!-- <div :class="videoNum==1?'videoborder videoborder1':videoNum==2?'videoborder videoborder2':videoNum==3?'videoborder videoborder3':'videoborder videoborder4'" v-for="num in 16"
			v-show='videoNum*videoNum>=num'>
				<!-- <video
				  class="video-js vjs-default-skin vjs-big-play-button"
				  :ref="'videoPlayer'+num"
				  :id="'videoPlayer'+num"
				></video> -->
				
			</div> -->
			
		</div>
	</div>
  </div>
</template>
<script>
import videojs from "video.js";
import "videojs-contrib-hls";
import { getvideoPage } from '@/api/video'
export default {
  name: 'videoHall',
  props: {
	  option: Object,
	    component: Object,
	  },
	  data() {
	    return {
			player:"",
			allVideoPlayerSet: new Set(),
			videoPlayerStatus: [],
			videoList: [],
			videoNum:2,
			title:{
				leftTitle:'监控点列表',
				rightTitle:'实时环境',
				controlTit:'控制台'
			},
			videolist:[],
			count: 1,
		};
	  },
  computed: {
    fontSize () {
      return (this.option.fontSize || 30) + 'px'
    }
  },
  methods: {
	  getVideo() {
	  this.player = videojs(
	            // videoEleId,
	            'my-player',
	            {
	              language: 'zh-CN',
	              // width: '1000',
	              // height: '600',
	              // poster: '',
	              // controls: true,
	              // autoplay: false,
	              // techOrder: ['html5', 'flash'],
	              // loop: false,
	              // muted: false,
	              // preload: 'metadata',
	              // poster: this.poster || '',
	            },
	            function onPlayerReady() {
	              var myPlayer = this
	              this.play()
	              //在回调函数中，this代表当前播放器，
	              //可以调用方法，也可以绑定事件。
	              /**
	               * 事件events    绑定事件用on    移除事件用off
	               */
	              this.on('suspend', function () {
	                //延迟下载
	                console.log('延迟下载')
	              })
	              this.on('loadstart', function () {
	                //客户端开始请求数据
	                console.log('客户端开始请求数据')
	              })
	              this.on('progress', function () {
	                //客户端正在请求数据
	                console.log('客户端正在请求数据')
	              })
	              this.on('abort', function () {
	                //客户端主动终止下载（不是因为错误引起）
	                console.log('客户端主动终止下载')
	              })
	              this.on('error', function () {
	                //请求数据时遇到错误
	                console.log('请求数据时遇到错误')
	              })
	              this.on('stalled', function () {
	                //网速失速
	                console.log('网速失速')
	              })
	              this.on('play', function () {
	                //开始播放
	                console.log('开始播放')
	              })
	              this.on('pause', function () {
	                //暂停
	                console.log('暂停')
	              })
	              this.on('loadedmetadata', function () {
	                //成功获取资源长度
	                console.log('成功获取资源长度')
	              })
	              this.on('loadeddata', function () {
	                //渲染播放画面
	                console.log('渲染播放画面')
	              })
	              this.on('waiting', function () {
	                //等待数据，并非错误
	                console.log('等待数据')
	              })
	              this.on('playing', function () {
	                //开始回放
	                console.log('开始回放')
	              })
	              this.on('canplay', function () {
	                //可以播放，但中途可能因为加载而暂停
	                console.log('可以播放，但中途可能因为加载而暂停')
	              })
	              this.on('canplaythrough', function () {
	                //可以播放，歌曲全部加载完毕
	                console.log('可以播放，歌曲全部加载完毕')
	              })
	              this.on('seeking', function () {
	                //寻找中
	                console.log('寻找中')
	              })
	              this.on('seeked', function () {
	                //寻找完毕
	                console.log('寻找完毕')
	              })
	              this.on('timeupdate', function () {
	                //播放时间改变
	              //   console.log('播放时间改变')
	              })
	              this.on('ended', function () {
	                //播放结束
	                console.log('播放结束')
	              })
	              this.on('ratechange', function () {
	                //播放速率改变
	              //   console.log('播放速率改变')
	              })
	              this.on('durationchange', function () {
	                //资源长度改变
	              //   console.log('资源长度改变')
	              })
	              this.on('volumechange', function () {
	                //音量改变
	              //   console.log('音量改变')
	              })
	            }
	          )
	  
	  　　
	  	},
		destroyVideo() {
		        if (this.player != null) {
		          this.player.dispose()
		          this.player = null
		        }
		    },
	 videoPlaye(item){
		 // if (this.flvPlayerList.length >= Math.pow(this.videoNum,2)) {
		 //   this.destoryVideo(this.flvPlayerList[0]);
		 //   this.flvPlayerList.shift();
		 // }
		 // let player = videojs("videoPlayer"+this.count, {
		 //      preload: true,
		 //      aspectRatio: "12:7",
		 //      fluid: true,
		 //      liveui: true,
			//   bigPlayButton: true, //是否显示播放按钮
			//  autoplay:true,//自动播放
			//  controls: true,//播放控件
		 //      poster:
		 //        "https://i-1-lanrentuku.qqxzb-img.com/2020/8/4/2fd4a87b-d1f6-48fe-8361-1619d22eeff0.jpg?imageView2/2/w/1024/",
		 //      sources: [
		 //        {
		 //          src: 'http://218.61.160.75:6713/mag/hls/c6e6ba54796c41d0b6a3c4fa6e50eead/1/live.m3u8',
		 //          type: "application/x-mpegURL",
		 //        },
		 //      ],
		 //    })
		 //    this.videoList.push(player);
		 //    this.videoPlayerStatus.push(false);
		 //    this.allVideoPlayerSet.add(player);
		 var player = videojs("videoPlayer"+this.count);        
		         player.src({
		           src: 'http://218.61.160.75:6713/mag/hls/c6e6ba54796c41d0b6a3c4fa6e50eead/1/live.m3u8',
		           type: 'application/x-mpegURL',
		         });
		         player.load();
		         player.play();
		 this.count >= Math.pow(this.videoNum,2) ? (this.count = 1) : this.count++;
	 },
	addvideoPlaye(num){
		this.videoNum=num
		this.videoPlayerStatus.length = 0;
		console.log(Math.pow(num,2))
		this.videoList.length = 0;
		this.videoPlayerStatus.length = 0;
		
		setTimeout(()=>{
		for(let i=1;i<=Math.pow(num,2);i++){
			let player = videojs("videoPlayer"+i, {
		         autoplay: true,
		         preload: true,
		         aspectRatio: "13:8",
		         fluid: true,
		         liveui: true,
		         poster:
		           "https://i-1-lanrentuku.qqxzb-img.com/2020/8/4/2fd4a87b-d1f6-48fe-8361-1619d22eeff0.jpg?imageView2/2/w/1024/",
		         sources: [
		           {
		             src: "http://218.61.160.75:6713/mag/hls/c6e6ba54796c41d0b6a3c4fa6e50eead/1/live.m3u8",
		             type: "application/x-mpegURL",
		           },
		         ],
		       });
		       this.videoList.push(player);
		       this.videoPlayerStatus.push(false);
		       this.allVideoPlayerSet.add(player);
		}},1000)
	},
  },
  mounted() {
	  this.getVideo();
    // 初始化视频列表
    this.videoPlayerStatus.length = 0;
	// 初始化视频列表
	getvideoPage({projectName:'慧筑云协同办公'}).then((res)=>{
		console.log(res.data.data.children[0].children)
		this.videolist=res.data.data.children[0].children
	})
	// for(let i=1;i<=4;i++){
	// 	let player = videojs("videoPlayer"+i, {
 //             autoplay: false,
 //             preload: true,
 //             aspectRatio: "13:8",
 //             fluid: true,
 //             liveui: true,
 //             poster:
 //               "https://i-1-lanrentuku.qqxzb-img.com/2020/8/4/2fd4a87b-d1f6-48fe-8361-1619d22eeff0.jpg?imageView2/2/w/1024/",
 //             sources: [
 //               {
 //                 src: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-uni4934e7b/c4d93960-5643-11eb-a16f-5b3e54966275.m3u8",
 //                 type: "application/x-mpegURL",
 //               },
 //             ],
 //           });
 //           this.videoList.push(player);
 //           this.videoPlayerStatus.push(false);
 //           this.allVideoPlayerSet.add(player);
	// }
    
  },
  beforeDestroy() {
    if (this.allVideoPlayerSet.size > 0) {
      for (const player of this.allVideoPlayerSet) {
        player.dispose();
      }
    }
  },
}
</script>
<style scoped lang="scss">
	ul,li{
		list-style: none;
	}
	button{ /*消除button的默认样式*/ /*这种写法是对所有的button标签同时生效*/ margin: 0px; padding: 0px; /*自定义边框*/ border: 0px; /*消除默认点击蓝色边框效果*/ outline: none; }
	.Flexible{
		display: flex;
		justify-content: space-between;
	}
	.flexbox-left{
		width:24%;
		height: 100%;
		// border: 1px solid red;
		margin-right: 18px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex:0 0 auto;
		.video-list{
			display: flex;
			flex-direction: column;
			width:100%;
			height:506px;
			margin-bottom: 20px;
			.videotop{
				display: flex;
				align-items: center;
				width:100%;
				height:41px;
				flex:0 0 auto;
				background: url('../../../public/hallVideoImg/titBgs@2x.png') no-repeat;
				background-size: 100% 100%;
				margin-bottom: 20px;
				.text{
					margin: 0 10px 0 40px;
				}
				img{
					width: 103px;
					height: 17px;
				}
			}
			.videolistbox{
				width:100%;
				flex:1 1 auto;
				background: url('../../../public/hallVideoImg/tablebg@2x.png') no-repeat;
				background-size: 100% 100%;
				overflow-y: scroll;
				ul{
					margin: 20px;
				}
				li{
					font-size: 16px;
					height: 41px;
					line-height: 41px;
					border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				}
			}
		}
		.controlbox{
			width:100%;
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.videotop{
				display: flex;
				align-items: center;
				width: 100%;
				height:40px;
				flex: 0 0 auto;
				background: url('../../../public/hallVideoImg/titBgs@2x.png') no-repeat;
				background-size: 100% 100%;
				margin-bottom: 20px;
				.text{
					margin: 0 10px 0 40px;
				}
				img{
					width: 103px;
					height: 17px;
				}
			}
			.controls{
				width:  100%;
				flex: 1 1 auto;
				background: url('../../../public/hallVideoImg/tablebg@2x.png') no-repeat;
				background-size: 100% 100%;
				font-size: 16px;
				.controlsitem{
					display: flex;
					justify-content: space-between;
					padding:10px 30px;
					.button{
						width:125px;
						height:41px;
						background: url('../../../public/hallVideoImg/controlBtnbg@2x.png') no-repeat;
						background-size: 100% 100%;
						color: #FFFFFF;
					}
					.switch__input{
						width:40px;
						height: 20px;
					}
				}
				.btnbg{
					position:relative;
					text-align: center;
					line-height:195px ;
					width: 195px;
					height: 195px;
					background: url('../../../public/hallVideoImg/controlicon@2x.png') no-repeat;
					background-size: 100% 100%;
					// .btnimg leftbtn
					.btnimg{
						width: 50px;
						height: 50px;
						position: absolute;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.leftbtn{top:74px;left: 10px;}
					.topbtn{top:10px;left:74px;}
					.rightbtn{top:74px;left:136px;}
					.bottombtn{top:136px;left:74px;}
				}
				.controlsitembtn{
					width: 140px;
					height: 195px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					line-height: 16px;
					img{
						width:16px;
						height: 16px;
						margin: 0 10px;
					}
				}
			}
		}
	}
	.flexbox-right{
		height: 100%;
		flex: 1 1 auto;
		// border: 1px solid red;
		display: flex;
		flex-direction: column;
		.videotop{
			position: relative;
			display: flex;
			align-items: center;
			width:100%;
			height:40px;
			background: url('../../../public/hallVideoImg/titBgL@2x.png') no-repeat;
			background-size: 100% 100%;
			flex:0 0 auto;
			margin-bottom: 20px;
			.text{
				margin: 0 10px 0 40px;
			}
			img{
				width: 103px;
				height: 17px;
			}
			.rightbtn{
				position: absolute;
				top: 7px;
				right: 10px;
				img{
					width: 26px;
					height: 26px;
					margin: 0 10px;
				}
			}
		}
		.video-box{
			width:100%;
			flex: 1 1 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.videoborder{
				background: url('../../../public/hallVideoImg/videoBg@2x.png') no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				padding: 1px;
				video{
					width: 100%;
					height: 100%;
				}
			}
			.videoborder1{
				width: 100%;
				height: 100%;
			}
			.videoborder2{
				width: 49.8%;
				height: 49.4%;
			}
			.videoborder3{
				width: 33.14%;
				height: 32.8%;
			}
			.videoborder4{
				width: 24.76%;
				height: 24.6%;
			}
			
		}
	
	}
</style>