<!-- 通用形配置 -->
<template>
    <div>
      <div></div>
      <el-form-item label="placeholder">
      <avue-input v-model="main.activeOption.placeholder"></avue-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  inject: ["main"]
}
</script>

<style>
</style>