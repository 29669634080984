<template>
  <div>
    <!-- 单个窗口 -->
    <div class="clapprs-container"
         v-if="reload">
      <!-- 使用窗口数amount遍历播放容器 -->
      <div v-for="(item) in amount"
           :key="item"
           :id="hid"
           :style="styleSizeName"
           ref="play"
           :class="{...showShade(item), ...clapprsItem,'current-window': item == currentIndex}"
           @click="handleClick(item)">
        <i class="close-icon"
           @click="closePlay(item)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from '@/utils/utils'
import create from '../../create'
import { getvideoPage, getProjectName } from '@/api/video'
export default create({
  name: 'clappers',
  data() {
    return {
      hid: 'main_' + uuid(),
      reload: true,
      config: {},
      amount: 4,
      playList: [],
      containerList: [],
      currentIndex: ''
    }
  },
  computed: {
    autoplay() {
      return this.option.autoplay
    },
    /* 遮罩样式 */
    showShade() {
      return function (item) {
        let res
        switch (this.amount) {
          case 1:
            if (!this.playList[0]) res = { shade1: true }
            break
          case 4:
            if (!this.playList[item - 1]) res = { shade4: true }
            break
          case 9:
            if (!this.playList[item - 1]) res = { shade9: true }
            break
          case 16:
            if (!this.playList[item - 1]) res = { shade16: true }
            break
        }
        return res
      }
    },
    /* 窗口样式 */
    clapprsItem() {
      let res
      switch (this.amount) {
        case 1:
          res = { 'clapprs-item1': true }
          break
        case 4:
          res = { 'clapprs-item4': true }
          break
        case 9:
          res = { 'clapprs-item9': true }
          break
        case 16:
          res = { 'clapprs-item16': true }
          break
      }
      return res
    }
  },
  watch: {},
  mounted() {
    let that = this
    this.getProjectNameData()
    this.$bus.$on('changeAmount', function (params) {
      if (params && params.amount) that.changeWindow(params.amount)
    })
    this.$bus.$on('playMonitoring', function (params) {
      if (params.row) {
        that.handlePlay(params)
      }
    })
  },
  beforeDestroy() {
    this.$bus.$off('changeAmount')
    this.$bus.$off('playMonitoring')
  },
  methods: {
    /* 获取项目名称 */
    async getProjectNameData() {
      const res = await getProjectName()
      if (res.data.code == 200) {
        this.projectName = res.data.data.simple
        this.initPlayer()
      }
    },
    /* 初始化时加载4窗口的画面 */
    async initPlayer() {
      // 获取4个窗口div对象
      this.containerList = this.$refs['play']
      // projectName: '中核通辽铀业'
      getvideoPage({ projectName: this.projectName }).then((res) => {
        if (res.data.code == 200) {
          // 获取监控播放地址url数组
          const urlList = res.data.data.children[0].children
            .splice(0, 4)
            .map((item) => {
              return item.url
            })
          // 遍历url数组，播放4个窗口画面
          urlList.forEach((url, index) => {
            const player = new Clappr.Player({
              source: url,
              autoPlay: this.autoplay,
              mute: true,
              height: '100%',
              width: '100%'
            })
            // this.playList需要添加值，否则遮罩不消失
            this.playList.push(player)
            player.attachTo(this.containerList[index])
          })
        }
      })
    },
    /* 切换窗口个数 */
    changeWindow(amount) {
      // 处理 playList
      if (this.playList.length > amount) {
        this.playList = this.playList.splice(0, amount)
      } else {
        // 每次切换窗口个数时，保证playList的长度等于窗口个数，没有play对象的位置使用null填充
        for (let index = 0; index < amount; index++) {
          if (this.playList[index] == undefined) {
            this.playList[index] = null
          }
        }
      }

      // 处理 playList 后再给this.amount赋值
      this.amount = amount

      // 切换窗口格式时，重新获取div对象数组
      this.$nextTick(() => {
        this.containerList = this.$refs['play']
      })
    },
    /* 点击左侧列表执行播放操作 */
    handlePlay(params) {
      // 初始化未选中窗口时，点击列表某个监控，给this.currentIndex赋1
      if (this.currentIndex == '') this.currentIndex = 1
      // 当currentIndex-1等于amount时，说明已经播放完最后一个窗口，这时循环到第一个窗口，currentIndex置1
      let index = this.currentIndex - 1
      if (index == this.amount) this.currentIndex = 1
      if (index < this.amount) {
        // 如果playList[currentIndex]有值，使用点击的url重置该窗口
        if (this.playList[index]) {
          this.playList[index].configure({
            source: params.row.url,
            autoPlay: true
          })
        } else {
          const player = new Clappr.Player({
            source: params.row.url,
            autoPlay: this.autoplay,
            mute: true,
            height: '100%',
            width: '100%'
          })
          this.playList[index] = player
          this.playList[index].attachTo(this.containerList[index])
        }
        this.currentIndex++
        if (this.currentIndex - 1 == this.amount) this.currentIndex = 1
      }
    },
    /* 点击某一个播放窗口 */
    handleClick(index) {
      // index 从1开始
      this.currentIndex = index
    },
    /* 关闭播放 */
    closePlay(index) {
      if (this.playList[index - 1]) this.playList[index - 1] = null
    }
  }
})
</script>
<style scoped lang='scss'>
.current-window {
  border: 2px solid #419de6;
}

.clapprs-container {
  display: flex;
  flex-wrap: wrap;
  width: 1428px;
  height: 888px;
  // 单窗口样式
  .clapprs-item1 {
    position: relative;
    width: 1415px !important;
    height: 890px !important;
  }
  .shade1 {
    &::before {
      content: '';
      position: absolute;
      z-index: 111;
      top: 0px;
      left: 0px;
      width: 1415px;
      height: 890px;
      background-color: #000;
    }
  }
  // 4窗口样式
  .clapprs-item4 {
    position: relative;
    width: 702px !important;
    height: 437px !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .shade4 {
    &::before {
      content: '';
      position: absolute;
      z-index: 111;
      top: 0px;
      left: 0px;
      width: 702px;
      height: 437px;
      background-color: #000;
    }
  }
  .shade41 {
    &::before {
      content: '';
      position: absolute;
      z-index: 111;
      top: 0px;
      left: 0px;
      width: 702px;
      height: 437px;
      background-color: #000;
      border: 2px solid #419de6;
      box-sizing: border-box;
    }
  }
  // 9窗口样式
  .clapprs-item9 {
    position: relative;
    width: 464px !important;
    height: 284px !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .shade9 {
    &::before {
      content: '';
      position: absolute;
      z-index: 111;
      top: 0px;
      left: 0px;
      width: 464px;
      height: 284px;
      background-color: #000;
    }
  }
  // 16窗口样式
  .clapprs-item16 {
    position: relative;
    width: 346px !important;
    height: 215px !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .shade16 {
    &::before {
      content: '';
      position: absolute;
      z-index: 111;
      top: 0px;
      left: 0px;
      width: 346px;
      height: 215px;
      background-color: #000;
    }
  }
}
// 关闭按钮样式
.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 29px;
  z-index: 111;
  &:hover {
    background: url('/img/source/win-close.png') center 0 no-repeat;
    background-size: 100% 100%;
  }
}
/deep/ .player-poster {
  z-index: unset !important;
}
/* 播放视频撑满窗口 */
/deep/ video[data-html5-video] {
  padding: 2px;
  border: 2px solid #032f80;
  object-fit: cover;
  bject-position: center center;
}
</style>



