<template>
  <div class="cus-datepicker-container">
    <el-date-picker
      v-model="date"
      prefix-icon="none"
      :size="option.datePickerSize"
      :clearable="false"
      :format="option.format"
      :value-format="option.defaultDateParamFormat"
      :picker-options="pickerOptions"
      popper-class="layoutPopperClass"
      type="date"
      :unlink-panels="true"
      @change="dateChange"
      placeholder="选择日期"
      class="date-style"
    >
    </el-date-picker>
  </div>
</template>

<script>
import moment from "moment";
import create from "../../create";
export default create({
  name: "defaultDate",
  props: {
    option: Object,
    component: Object,
  },
  components: {},
  data() {
    return {
      date: null,
    };
  },
  computed: {},
  watch: {
    date: {
      handler(val) {
        if (val || val.length == 0) {
          this.handleClick();
        }
      },
      deep: true,
    },
    "option.intercept": {
      handler(n) {
        let disabledDate = {
          // 返回禁用时间
          disabledDate(time) {
            return time.getTime() > Date.now(); // 可选历史天、可选当前天、不可选未来天
            // return time.getTime() > Date.now() - 8.64e7;  // 可选历史天、不可选当前天、不可选未来天
            // return time.getTime() < Date.now() - 8.64e7;  // 不可选历史天、可选当前天、可选未来天
            // return time.getTime() < Date.now(); // 不可选历史天、不可选当前天、可选未来天
          },
        };
        this.pickerOptions = n ? disabledDate : {};
      },
      immediate: true,
    },
    "option.defaultValue": {
      handler(n) {
        let nowDate = moment().format("YYYY-MM-DD");
        this.date = n ? nowDate : "";
      },
      immediate: true,
    },
  },
  mounted() {
    this.updateClick({ value: this.date });
  },
  created() {},
  methods: {
    handleClick() {
      this.updateClick({ value: this.date });
    },
    /* 日期选择器选定日期 */
    dateChange(value) {
      this.handleClick();
	  if(value){
		const date = new Date(value);
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // 月份是从0开始的
		const day = date.getDate();
		this.changeFormatter && this.changeFormatter({
			year:year.toString(),
			month:month.toString(),
			day:day.toString(),
			data: this.date
		}, this.getItemRefs());
	  }
    },
  },
});
</script>
<style scoped lang="scss">
/deep/.input {
  background-color: unset !important;
}
/deep/ .el-input__inner {
  /*background: -webkit-linear-gradient(*/
  /*top left,*/
  /*rgba(0, 25, 83, 0.8),*/
  /*rgba(0, 3, 38, 0.8)*/
  /*);*/
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  /*border: 1px solid #2580dc !important;*/
  border: 1px solid transparent !important;
  color: #fff !important;
  width: 115px !important;
  padding-left: 20px !important;
  border: 1px solid #07d2f5 !important;
  .el-range-input {
    /*background: unset !important ;*/
    /*background: -webkit-linear-gradient(*/
    /*top left,*/
    /*rgba(0, 25, 83, 0.8),*/
    /*rgba(0, 3, 38, 0.8)*/
    /*);*/
    background-color: unset !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #c0c4cc !important;
    width: 45%;
  }
  .el-range-separator {
    color: #fff;
    height: unset;
  }
  .el-range__close-icon {
    width: 0;
  }
}
.date-style {
  width: 100%;
  height: 100%;
  /deep/.input {
    background-color: unset !important;
  }
  /deep/ .el-input__inner {
    background-color: #0b2255 !important;
    width: 115px !important;
  }
}
.layoutPopperClass {
  /deep/ .input {
    background-color: unset !important;
  }
}
.select-style {
  // border: 1px solid #2580dc !important;
  height: 42px !important;
  margin-right: 15px;
  /deep/ .input {
    background-color: unset !important;
  }
  /deep/ .el-input__inner {
    width: 115px !important;
    height: 42px;
    border-color: #07dbff !important; // 取消蓝边框
    background-color: #0b2255 !important;
  }
  /deep/ .el-input__icon {
    line-height: 49px !important;
  }
}
/deep/ .el-year-table td {
  text-align: center;
  padding: 10px 3px !important;
  cursor: pointer;
}
</style>
<style lang="scss">
.cus-datepicker-container {
  display: flex;
}
.cus-datepicker-container input {
  background-color: transparent !important;
}
// bim日期下拉样式
.layoutPopperClass {
  /deep/ .el-input__inner {
    background-color: #0b2255 !important;
    width: 115px !important;
  }
  background: -webkit-linear-gradient(
    top left,
    rgba(0, 25, 83, 1),
    rgba(0, 3, 38, 1)
  );
  border: 1px solid #2580dc;
  color: #fff;
  .el-year-table td {
    text-align: center;
    padding: 10px 3px;
    cursor: pointer;
  }
  .el-year-table td .cell {
    width: 48px;
    height: 32px;
    display: block;
    line-height: 32px;
    color: #fff;
    margin: 0 auto;
  }
  .el-date-picker__header-label {
    font-size: 16px;
    font-weight: 500;
    padding: 0 5px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    color: #fff;
  }
  .el-picker-panel__icon-btn {
    font-size: 12px;
    color: #fff;
    border: 0;
    background: 0 0;
    cursor: pointer;
    outline: 0;
    margin-top: 8px;
  }
  .el-date-range-picker__header {
    color: #c0c4cc;
    .el-date-picker__header-label {
      color: #c0c4cc;
    }
    .el-picker-panel__icon-btn {
      color: #c0c4cc !important;
    }
  }
  .el-date-table {
    .el-date-table__row {
      .in-range {
        div {
          background-color: #4978a9 !important;
        }
      }
    }
  }
  .el-date-table {
    .in-range {
      div {
        background-color: #4978a9 !important;
      }
    }
  }
  .el-picker-panel__content {
    .el-date-table {
      tr {
        th {
          color: #c0c4cc !important;
        }
      }
      .el-date-table__row {
        .prev-date {
          color: #fff !important;
        }
        .available {
          color: #c0c4cc !important;
          padding: 10px 3px !important;
        }
      }
    }
  }
  .el-picker-panel__footer {
    .popper__arrow {
      background-color: #1dd1f2 !important;
    }
  }
  .input {
    background-color: unset !important;
  }
  .el-range-input {
    background: unset !important;
    width: 45%;
  }
}
</style>
