<template>
    <div class="content">
      <div class="centerTxt">
        正在跳转到指定看板......
      </div>
    </div>
  </template>
  
  <script>
  import {
    getHzyProjectIdFromJianguan,
  } from '@/api/erp'
  
  export default {
    data() {
      return {
        bordTypeList: [
          '1528973677142634498', // 项目综合看板0
          '1529743932630659074', // 项目经营看板1
          '1529743541931241474', // 项目劳务看板2
          '1528976646164606977', // 项目设备看板3
          '1528967431748481026', // 项目物料看板4
          '1541597388928610306', // 项目质量安全看板5
          '1578589263728111618', // 塔吊监测6
          '1578589578678398978', // 升降机监测7
          '1578590142359302146', // 卸料平台监测8
          '1578588847858675713', // 环境监测9
          '1578590294281187330', // 智能水电10
          '1584385704832692225', // 视频监控11
          '1581909323464994817', // AI视频监测12
          '1581844131980931073', // 劳务实名制13
          '1581916498199474177', // 深基坑监测14
          '1582683759856218113', // 高支模监测15
          '1583001627248455681', // 智能地磅16
          '1641393692679868417', //仓库看板17
          '1654364445083701249', //云监控18
          '1660821912931577858' //安全帽看板19
        ],
        projectList: [],
        bordType: 0,
        createUser: null,
        searchValue: '',
        userName: '',
        // pwd: '',
        tenantId: '',
        projectId: '',
        key:'',
      }
    },
    async created() {
      // 获取URL参数
      // var query = this.$route.query;
      let query = window.location.search.substring(1)
      let windowParams = {}
      query = query.split('&')
      query.forEach((ele) => {
        let pair = ele.split('=')
        if (pair[0]) {
          windowParams[pair[0]] = pair[1]
        }
      })
      this.bordType = windowParams['bordType']
      this.projectId = windowParams['projectId']
      // URL参数中看板类型不存在时提示错误信息
      if (
        this.bordType == null ||
        this.bordType == undefined ||
        this.bordType == '' ||
        this.projectId == null ||
        this.projectId == undefined ||
        this.projectId == ''
      ) {
        this.$message({
          showClose: false,
          message: '参数错误，请从正确渠道访问本页面！',
          type: 'error'
        })
        return
      } else {
        // 获取看板类型
        // 如果看板类型是数字
        if (this.bordType.match('^\\d+$')) {
          // 转换看板类型为数值
          const type = parseInt(this.bordType)
          // 如果看板类型在看板List里不存在
          if (this.bordTypeList.length <= type) {
            this.$message({
              showClose: false,
              message: '您要查看的看板不存在，请确认后重新选择！',
              type: 'error'
            })
            return
          } else {
            // 将质量安全平台的项目ID转化为慧筑云的项目ID
            var response = await getHzyProjectIdFromJianguan({
              projectId: this.projectId
            })
            if (response && response.status === 200 && response.data && response.data.code == 200 && response.data.data.hzyProjectId &&  response.data.data.hzyProjectId != '') {
              this.viewBord(response.data.data.hzyProjectId)
            } else {
              this.$message({
                showClose: false,
                message: '您要查看的项目不存在，请重新确认后再进行操作',
                type: 'error'
              })
              return
            }
          }
          // 如果看板类型不是数字，提示错误消息
        } else {
          this.$message({
            showClose: false,
            message: '参数错误，请从正确渠道访问本页面！',
            type: 'error'
          })
          return
        }
      }
    },
    computed: {
      showProjectList() {
        return this.projectList.filter((item) =>
          item.simple.includes(this.searchValue)
        )
      }
    },
    methods: {
      /**
       * @param 项目ID
       * 看板跳转处理
       */
      viewBord(projectId) {
        // 根据看板类型获取看板ID
        const bordTypeSend = this.bordTypeList[this.bordType]
        this.$router.replace({
          path: '/view/' + bordTypeSend,
          query: { projectId: projectId }
        })
      }
    }
  }
  </script>
  
  <style lang="scss" scope>
  .content {
    height: 100%;
    background-image: url('../../assets/pj-bg.png');
    background-size: 100% 100%;
    display: flex;
    .centerTxt {
      margin: 50px auto;
      font-size: 18px;
      color: #fff;
    }
  
    .pj-select {
      margin: auto;
      width: 363px;
      height: 72vh;
      background-image: url('../../assets/pj-select-bg.png');
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      .search {
        width: 80%;
        margin: 30px;
        background-image: url('../../assets/pj-search-bg.png');
        background-size: 100% 100%;
      }
      .list {
        flex: 1;
        padding: 0 22px;
        overflow-y: scroll;
        .cell {
          min-height: 40px;
          display: flex;
          align-items: flex-start;
          img {
            width: 25px;
            height: 25px;
          }
          a {
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
  </style>
  <style scoped scss>
  .pj-select .search input {
    background-color: transparent !important;
  }
  </style>
  