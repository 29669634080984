<template>
  <div
    class="border1"
    :style="[{ height: height }, { 'padding-top': title === '-' ? '0' : '' }]"
  >
    <div class="echart-img-item" v-if="option !== undefined">
      <div class="echart-text">
        <div class="echart-text__box">
          <a href="#" target="_self" class="echart-text__text">{{
            option.title
          }}</a>
        </div>
        <a
          v-if="option.unitText !== undefined"
          href="#"
          target="_self"
          class="echart-text__unitText"
          :style="unitStyle"
          >{{ option.unitText.text }}</a
        >
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: "100%",
      unitStyle: null,
      title: "-",
    };
  },
  components: {},
  props: {
    option: {
      title: "-", // 值为-时，不显示title
      unitText: {
        text: "",
        align: "right",
        top: 0,
        left: 0,
        right: 0,
        color: "",
      },
      height: "100%",
    },
  },
  mounted() {
    if (this.option !== undefined) {
      this.title = this.option.title === undefined ? "-" : this.option.title;
      this.height =
        this.option.height === undefined ? "100%" : this.option.height;
      if (this.option.unitText !== undefined) {
        if (this.option.unitText.align === "center") {
          this.unitStyle = {
            left:
              this.option.unitText.left === undefined
                ? 0
                : this.option.unitText.left,
            right:
              this.option.unitText.right === undefined
                ? 0
                : this.option.unitText.right,
            top:
              this.option.unitText.top === undefined
                ? 0
                : this.option.unitText.top,
          };
        } else if (this.option.unitText.align === "left") {
          this.unitStyle = {
            left:
              this.option.unitText.left === undefined
                ? 0
                : this.option.unitText.left,
            top:
              this.option.unitText.top === undefined
                ? 0
                : this.option.unitText.top,
            color: this.option.unitText.color,
          };
        } else {
          this.unitStyle = {
            right:
              this.option.unitText.right === undefined
                ? 0
                : this.option.unitText.right,
            top:
              this.option.unitText.top === undefined
                ? 0
                : this.option.unitText.top,
          };
        }
      }
    } else {
      this.title = "-";
      this.height = "100%";
    }
  },
};
</script>

<style lang='scss' scoped>
.border1 {
  position: relative;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba(180, 181, 198, 0.1);
  background-clip: padding-box;
  opacity: 1;
  filter: blur(0px);
  border-image-source: url(/img/border/border3.png);
  border-image-slice: 10 10 10 10 fill;
  border-width: 10px;
  border-style: solid;
  box-sizing: border-box;
  padding-top: 35px;
  .echart-img-item {
    background: url("/img/source/source1-3.svg") center 0 no-repeat;
    background-size: contain;
    width: 170px;
    height: 75px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;

    .echart-text {
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;

      .echart-text__box {
        text-align: center;

        .echart-text__text {
          width: auto;
          transform: translateX(0px);
          letter-spacing: 0px;
          text-indent: 0px;
          font-weight: normal;
          font-size: 16px;
          line-height: 31px;
          color: white;
        }
      }

      .echart-text__unitText {
        transform: translateX(0px);
        letter-spacing: 0px;
        text-indent: 0px;
        font-weight: normal;
        font-size: 0.175rem;
        line-height: 31px;
        color: #7bbaf7;
        position: absolute;
      }
    }
  }
}
</style>
