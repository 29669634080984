<template>
  <el-drawer :visible.sync="drawer"
             :withHeader="false"
             size="361px"
             suffix-icon="el-icon-search"
             direction="ltr">
    <div style="background-color: transparent;">

      <div class="pj-select">
        <el-input class="search"
                  v-model="searchValue"
                  placeholder="请输入项目名称"
                  suffix-icon="el-icon-search"
                  clearable>
          <!--<div style="height: 100%; padding-right: 5px; display: flex;" slot="suffix"><i class="el-icon-search" style="margin: auto"></i></div>-->
        </el-input>
        <div class="list">
          <p v-for="(project, index) in showProjectList"
             :key="index"
             class="cell">
            <img src="../../assets/pj-item-pre.png"
                 alt="">
            <a style="cursor:pointer"
               @click="viewBord(project.id)">{{project.simple}}</a>
          </p>
        </div>

      </div>
    </div>

  </el-drawer>
</template>

<script>
import {
  getManagementAuth,
  getbiboardToken,
  getProjectListByCreateUser,
  getRNBProjectListByCreateUser
} from '@/api/erp'
import { getStore, setStore } from '@/utils/store'
export default {
  name: 'projectsList',
  data() {
    return {
      drawer: false,
      bordTypeList: [
        '1528973677142634498', // 项目综合看板
        '1529743932630659074', // 项目经营看板
        '1529743541931241474', // 项目劳务看板
        '1528976646164606977', // 项目设备看板
        '1528967431748481026', // 项目物料看板
        '1541597388928610306', // 项目质量安全看板
        '1578589263728111618', // 塔吊监测
        '1578589578678398978', // 升降机监测
        '1578590142359302146', // 卸料平台监测
        '1578588847858675713', // 环境监测
        '1578590294281187330', // 智能水电
        '1584385704832692225', // 监控视频
        '1581909323464994817', // AI视频监测
        '1581844131980931073', // 劳务实名制
        '1581916498199474177', // 深基坑监测
        '1582683759856218113', // 高支模监测
        '1583001627248455681', // 智能地磅
        '1641393692679868417', // 仓库看板
        '1654364445083701249', // 云监控
		'1660821912931577858' //安全帽看板
        //'1528973677142634498', // 公司综合看板
        //'1529743932630659074', // 公司经营看板
        //'1529743541931241474', // 公司劳务看板
        //'1528976646164606977', // 公司设备看板
        //'1528967431748481026', // 公司物料看板
        //'1528967431748481026', // 公司质量安全看板
      ],
      projectList: [],
      bordType: 0,
      createUser: null,
      searchValue: '',
      userName: '',
      pwd: '',
      tenantId: '',
	  key:'',
    }
  },
  async created() {
    let query = window.location.search.substring(1)
    let windowParams = {}
    query = query.split('&')
    query.forEach((ele) => {
      let pair = ele.split('=')
      if (pair[0]) {
        windowParams[pair[0]] = pair[1]
      }
    })
    if (!windowParams['createUser']) {
      // this.$message({ showClose: false, message: "缺少必要参数！", type: "error" });
      return
    }
    this.createUser = windowParams['createUser']
    this.userName = windowParams['username']
    // this.pwd = windowParams['password']
    this.tenantId = windowParams['tenantId']
	this.key = windowParams['key']
    localStorage.setItem('userName', JSON.stringify(this.userName))
    // localStorage.setItem('pwd', JSON.stringify(this.pwd))
    this.bordType = this.bordTypeList.findIndex((item) =>
      window.location.href.includes(item)
    )
    // 如果当前页面是劳务实名制，则获取劳务实名制的项目列表
    let response = null
    if (window.location.href.includes('1581844131980931073')) {
      response = await getRNBProjectListByCreateUser({
        createUser: this.createUser
      })
    } else {
      response = await getProjectListByCreateUser({
        createUser: this.createUser
      })
    }
    // 根据用户ID获取用户项目列表
    if (response.status == 200 && response.data.code == 200) {
      this.projectList = response.data.data
      const currentChooseProjectId = getStore({ name: 'currentChooseItem' })
      const index = this.projectList.findIndex(
        (obj) => obj.id === currentChooseProjectId
      )
      if (index !== -1) {
        const [obj] = this.projectList.splice(index, 1)
        this.projectList.unshift(obj)
      }
      // 如果该用户没有参与任何项目，提示错误信息
      if (this.projectList.length == 0) {
        this.$message({
          showClose: false,
          message: '您没有参与到任何项目，请向公司管理人员确认！',
          type: 'error'
        })
        return
      }
    } else {
      // 接口错误提示
      this.$message({
        showClose: false,
        message: '服务连接失败，请重试或联系管理员！',
        type: 'error'
      })
      return
    }
    const keywords = ['1641393692679868417','1648883738009931777','1649011372568088577', '1649011639552315393', '1651554504002371586', '1651554895179939842','1651067484335247362','1651071065960484866'];
    const currentURL = window.location.href;
    if (keywords.some(keyword => currentURL.includes(keyword))) {
      this.getAuth()
    }
  },
  computed: {
    showProjectList() {
      return this.projectList.filter((item) =>
        item.simple.includes(this.searchValue)
      )
    }
  },
  methods: {
    getAuth() {
		getbiboardToken().then((res) => {
		  const data = res.data.data
		  localStorage.setItem(
			'managementToken',                   
			JSON.stringify(data.access_token)
		  )
		  localStorage.setItem(
			'managementTenantId',
			JSON.stringify(data.tenant_id)
		  )
		  localStorage.setItem(
			'switchToCompanyId',
			JSON.stringify(data.switchToCompanyId)
		  )
		})
      // getManagementAuth().then((res) => {
      //   const data = res.data
      //   localStorage.setItem(
      //     'managementToken',
      //     JSON.stringify(data.access_token)
      //   )
      //   localStorage.setItem(
      //     'managementTenantId',
      //     JSON.stringify(data.tenant_id)
      //   )
      //   localStorage.setItem(
      //     'switchToCompanyId',
      //     JSON.stringify(data.AuditCompanyIds.split(',')[0])
      //   )
      // })
    },
    show() {
      this.drawer = true
    },
    /**
     * @param 项目ID
     * 看板跳转处理
     */
    viewBord(projectId) {
      setStore({ name: 'currentChooseItem', content: projectId })
      // 根据看板类型获取看板ID
      const bordTypeSend = this.bordTypeList[this.bordType]
      // 将项目ID存储到本地Storage
      setStore({
        name:
          'huizhuyun_bibord_default_project_' +
          this.createUser +
          '_' +
          this.bordType,
        content: projectId
      })
      // 跳转到指定看板
      if (bordTypeSend === '1641393692679868417') {
        this.$router.replace({
          path: '/view/' + bordTypeSend,
          query: {
            projectId: projectId,
            createUser: this.createUser,
            username: this.userName,
            // password: this.pwd,
            tenantId: this.tenantId,
            grant_type: 'password',
            scope: 'all',
            type: 'account',
			key:this.key
          }
        })
      } else {
        this.$router.replace({
          path: '/view/' + bordTypeSend,
          query: { projectId: projectId, createUser: this.createUser }
        })
      }
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.pj-select {
  margin-top: 20vh;
  height: 60vh;
  background-image: url('../../assets/pj-select-bg.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
/deep/ .el-drawer {
  background-color: transparent;
}
.search {
  width: 80%;
  margin: 30px;
  background-image: url('../../assets/pj-search-bg.png');
  background-size: 100% 100%;
  /deep/ input {
    background-color: transparent !important;
  }
}
.list {
  flex: 1;
  padding: 0 22px;
  overflow-y: scroll;
  .cell {
    min-height: 40px;
    display: flex;
    align-items: flex-start;
    img {
      width: 25px;
      height: 25px;
    }
    a {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
