<template>
  <div>
    <div class="container">
      <div class="left_container">
        <!-- 左上模块 -->
        <div class="left1">
          <!-- 在线/离线图标 -->
          <Chartimg :option="optin"></Chartimg>
          <!-- 标题-塔机信息 -->
          <div class="tjxx_title1">
            <div class="echart-text">
              <span>塔机信息</span>
            </div>
          </div>
          <!-- 内容-塔机信息 -->
          <div class="taji_text">
            <p>
              塔机编号：<i>{{ tjxxData.tower_crane_no }}</i>
            </p>
            <p>
              塔机名称：<i>{{ tjxxData.tower_crane_name }}</i>
            </p>
            <p>塔机型号：<i>{{ tjxxData.device_type }}</i></p>
            <p>
              生产厂商：<i>{{ tjxxData.manufacturer }}</i>
            </p>
          </div>
          <!-- 标题-司机信息 -->
          <div class="tjxx_title2">
            <div class="echart-text">
              <span>司机信息</span>
            </div>
          </div>
          <!-- 内容-司机信息 -->
          <div class="siji_text">
            <p>
              司机姓名：<i>{{ tjxxData.driver_name }}</i>
            </p>
            <p>
              识别类型：<i>{{ tjxxData.recognition_type }}</i>
            </p>
          </div>
        </div>
        <!-- 左下模块 -->
        <div class="left2">
          <div class="scroll_board">
            <dv-scroll-board
              :config="config"
              @click="handleTjlb"
              class="scroll_board_body"
            />
          </div>
        </div>
      </div>
      <div class="right_container">
        <div class="right1">
          <span>{{
            tjxxData.hoisting_count !== undefined
              ? tjxxData.hoisting_count
              : 0
          }}</span>
          <span>{{
            tjxxData.sos_count !== undefined ? tjxxData.sos_count : 0
          }}</span>
          <span>{{
            tjxxData.overload_time !== undefined ? tjxxData.overload_time : 0
          }}</span>
          <span>{{
            tjxxData.overload_rate !== undefined ? tjxxData.overload_rate : 0
          }}</span>
        </div>
        <div class="right2">
          <!-- 幅度 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[0].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[0].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[0].value }}</div>
              <div class="unit">{{ btmInfo[0].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[0].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[0].style"></div>
          </div>
          <!-- -------------------------- -->
          <!-- 高度 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[1].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[1].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[1].value }}</div>
              <div class="unit">{{ btmInfo[1].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[1].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[1].style"></div>
          </div>
          <!-- -------------------------- -->
          <!-- 风速 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[2].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[2].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[2].value }}</div>
              <div class="unit">{{ btmInfo[2].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[2].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[2].style"></div>
          </div>
          <!-- -------------------------- -->
          <!-- 吊重 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[3].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[3].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[3].value }}</div>
              <div class="unit">{{ btmInfo[3].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[3].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[3].style"></div>
          </div>
          <!-- -------------------------- -->
          <!-- 边矩比 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[4].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[4].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[4].value }}</div>
              <div class="unit">{{ btmInfo[4].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[4].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[4].style"></div>
          </div>
          <!-- -------------------------- -->
          <!-- 转角 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[5].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[5].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[5].value }}</div>
              <div class="unit">{{ btmInfo[5].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[5].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[5].style"></div>
          </div>
          <!-- -------------------------- -->
          <!-- 倾角 -->
          <div class="item_box">
            <div class="row1">
              <div class="title1">{{ btmInfo[6].title }}</div>
              <div class="title_icon">
                <img :src="btmInfo[6].icon" alt="" />
              </div>
            </div>
            <div class="row2">
              <div>{{ btmInfo[6].value }}</div>
              <div class="unit">{{ btmInfo[6].unit }}</div>
            </div>
            <div class="row3">
              <div class="progress">
                <div
                  class="progress_bar"
                  :style="`width:${btmInfo[6].percentage}`"
                ></div>
              </div>
            </div>
            <div class="row4" :style="btmInfo[6].style"></div>
          </div>
          <!-- -------------------------- -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chartimg from "./chartimg.vue";
export default {
  // 塔机监测页组件
  name: "test5",
  components: { Chartimg },
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      optin: {
        data: [
          {
            style:
              "width: 70px;right:5px; top: 25px;color:#4e5052; font-size: 18px; ",
            value: "离线",
            url: "/img/source/source173.svg",
            // title: "在册人数",
            spin: false,
          },
        ],
      },
      config: {
        rowNum: 13,
        headerBGC: "rgba(8, 90, 111, 0.2)",
        oddRowBGC: "rgba(14, 86, 241, 0.08)", //奇
        evenRowBGC: "", //偶
        header: ["设备编号", "塔吊名称", "在线/离线"],
        columnWidth: [150, 150, 150],
        data: [
          // ["行1列1", "行1列2", "行1列3", "行1列4"],
        ],
      },
      tower_crane_no: "", // 获取塔机信息接口的参数
      tjxxData: [
        {
          tower_crane_no: "",
          tower_crane_name: "",
          manufacturer: "",
          driver_name: "",
          recognition_type: "",
        },
      ],
      virtualData: [["", "", ""]],
      btmInfo: [
        {
          title: "幅度",
          value: 0,
          unit: "米",
          style: "background-color: #1ff021",
          icon: "/img/source/副度.svg",
          percentage: "",
        },
        {
          title: "高度",
          value: 0,
          unit: "米",
          style: "background-color: #1ff021",
          icon: "/img/source/高度.svg",
          percentage: "",
        },
        {
          title: "风速",
          value: 0,
          unit: "米/秒",
          // style: "background-color: #f99032",
          style: "background-color: #1ff021",
          icon: "/img/source/风度.svg",
          percentage: "",
        },
        {
          title: "吊重",
          value: 0,
          unit: "吨",
          style: "background-color: #1ff021",
          icon: "/img/source/吊重.svg",
          percentage: "",
        },
        {
          title: "边矩比",
          value: 0,
          unit: "%",
          // style: "background-color: #f71223",
          style: "background-color: #1ff021",
          icon: "/img/source/力距比.svg",
          percentage: "",
        },
        {
          title: "转角",
          value: 0,
          unit: "度",
          style: "background-color: #1ff021",
          icon: "/img/source/转角.svg",
          percentage: "",
        },
        {
          title: "倾角",
          value: 0,
          unit: "度",
          style: "background-color: #1ff021",
          icon: "/img/source/倾角.svg",
          percentage: "",
        },
      ],
      staticData: [
        {
          "tower_crane_no": "T1000001",
          "tower_crane_name": "中型塔吊#1",
          "tower_crane_status": "在线",
          "dip_angle": 3.8,
          "range": 23,
          "height": 36,
          "weight": 23,
          "moment": 2.25,
          "turn": 16,
          "wind_speed": 3.9,
          "height_percentage": 0.02,
          "range_percentage": 0.03,
          "wind_turn_percentage": 0.05,
          "weight_percentage": 0.05,
          "moment_percentage": 0.11,
          "turn_percentage": 0.72,
          "dip_angle_percentage": 0.23,
          "system_state": "00000100010110",
          "hoisting_count": 5,
          "sos_count": 10,
          "driver_name": "李新宇",
          "recognition_type": "人脸",
          "device_type": "中型塔机",
          "manufacturer": "三一重工",
          "overload_time": 12,
          "overload_rate": "10%"
        },
        {
          "tower_crane_no": "T1000002",
          "tower_crane_name": "中型塔吊#2",
          "tower_crane_status": "离线",
          "dip_angle": 4.8,
          "range": 33,
          "height": 25,
          "weight": 18,
          "moment": 1.25,
          "turn": 23,
          "wind_speed": 7,
          "height_percentage": 0.03,
          "range_percentage": 0.15,
          "wind_turn_percentage": 0.11,
          "weight_percentage": 0.02,
          "moment_percentage": 0.08,
          "turn_percentage": 0.04,
          "dip_angle_percentage": 0.25,
          "system_state": "01001000010000",
          "hoisting_count": 9,
          "sos_count": 13,
          "driver_name": "赵万年",
          "recognition_type": "指纹",
          "device_type": "中型塔机",
          "manufacturer": "河南新型建筑机械",
          "overload_time": 6,
          "overload_rate": "18%"
        },
        {
          "tower_crane_no": "T1000003",
          "tower_crane_name": "中型塔吊#3",
          "tower_crane_status": "在线",
          "dip_angle": 5.5,
          "range": 21,
          "height": 18,
          "weight": 33,
          "moment": 1.38,
          "turn": 34,
          "wind_speed": 6,
          "height_percentage": 0.05,
          "range_percentage": 0.12,
          "wind_turn_percentage": 0.13,
          "weight_percentage": 0.09,
          "moment_percentage": 0.03,
          "turn_percentage": 0.08,
          "dip_angle_percentage": 0.33,
          "system_state": "00010000100001",
          "hoisting_count": 3,
          "sos_count": 16,
          "driver_name": "肖云鹏",
          "recognition_type": "指纹",
          "device_type": "中型塔机",
          "manufacturer": "河南新型建筑机械",
          "overload_time": 9,
          "overload_rate": "22%"
        },
        {
          "tower_crane_no": "T1000004",
          "tower_crane_name": "中型塔吊#4",
          "tower_crane_status": "在线",
          "dip_angle": 5.2,
          "range": 28,
          "height": 16,
          "weight": 24,
          "moment": 2.12,
          "turn": 55,
          "wind_speed": 6,
          "height_percentage": 0.09,
          "range_percentage": 0.33,
          "wind_turn_percentage": 0.15,
          "weight_percentage": 0.21,
          "moment_percentage": 0.11,
          "turn_percentage": 0.09,
          "dip_angle_percentage": 0.21,
          "system_state": "00010000000000",
          "hoisting_count": 7,
          "sos_count": 21,
          "driver_name": "常宇峰",
          "recognition_type": "人脸",
          "device_type": "中型塔机",
          "manufacturer": "大连其中机械制造有限公司",
          "overload_time": 6,
          "overload_rate": "10%"
        },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      this.getTjlb(); // 塔机列表
    });
  },
  methods: {
    // 塔机列表
    getTjlb() {
      let _self = this;
      // let url = myRequest.getRequestUrlWithAnyParam2(
      //   "/api/homepage/towercrane/list"
      // );
      // axios.get(url).then((res) => {
        let tjData = [];
        // if (res.status === 200 && res.data.data !== undefined) {
          // let data = res.data.data;
          let data = this.staticData
          let tower_crane_no = []; // 保存塔机列表的设备编号
          data.forEach((item) => {
            tower_crane_no.push(item.tower_crane_no);
            tjData.push([
              item.tower_crane_no,
              item.tower_crane_name,
              item.tower_crane_status,
            ]);
          });
          this.getTjxx(0);
        // } else {
        //   tjData = this.virtualData;
        //   this.getTjxx();
        // }
        _self.config = {
          rowNum: 13,
          headerBGC: "rgba(8, 90, 111, 0.2)",
          oddRowBGC: "rgba(14, 86, 241, 0.08)", //奇
          evenRowBGC: "", //偶
          header: ["设备编号", "塔吊名称", "在线/离线"],
          data: tjData,
        };
      // });
    },
    // 塔机信息
    async getTjxx(index) {
      let _self = this;
      // let url = myRequest.getRequestUrlWithAnyParam(
      //   "/api/homepage/towercrane/info",
      //   `&tower_crane_no=${id}`
      // );
      // const res = await axios.get(url);
      // if (res.status === 200 && res.data.data !== undefined) {
      //   let data = res.data.data;
      let data = this.staticData[index]
        let status = _self.getStatus(data.system_state);
        this.btmInfo[0].value = data.range;
        this.btmInfo[1].value = data.height;
        this.btmInfo[2].value = data.wind_speed;
        this.btmInfo[3].value = data.weight;
        this.btmInfo[4].value = data.moment;
        this.btmInfo[5].value = data.turn;
        this.btmInfo[6].value = data.dip_angle;

        
        // ------------- 颜色
        _self.btmInfo[0].style = status.range;
        _self.btmInfo[1].style = status.height;
        _self.btmInfo[2].style = status.wind_speed;
        _self.btmInfo[3].style = status.weight;
        _self.btmInfo[4].style = status.moment;
        _self.btmInfo[5].style = status.turn;
        _self.btmInfo[6].style = status.dip_angle;


        // -------------------------------------
        this.btmInfo[0].percentage = data.range_percentage * 100 + "%"; // `width:${data.range_percentage* 100}%`
        this.btmInfo[1].percentage = data.height_percentage * 100 + "%";
        this.btmInfo[2].percentage = data.wind_speed_percentage + "%";
        this.btmInfo[3].percentage = data.weight_percentage + "%";
        this.btmInfo[4].percentage = data.moment_percentage * 100 + "%";
        this.btmInfo[5].percentage = data.turn_percentage * 100 + "%";
        this.btmInfo[6].percentage = data.dip_angle_percentage * 100 + "%";

        this.btmInfo.forEach((item) => {
          if (item.percentage !== "") {
            item.value = parseInt(item.value).toFixed(2);
          } // 进度条百分比显示整数
        });
        // -------------------------------------
        this.tjxxData = data;
        // this.$bus.$emit("busPass", data);
        // 获取塔机状态
        this.optin.data[0].value = data.tower_crane_status;
        if (data.tower_crane_status == "离线") {
          this.optin.data[0].spin = false;
          this.optin.data[0].style =
            "width: 70px;right:5px; top: 25px;color:#4e5052; font-size: 18px; ";
        } else {
          this.optin.data[0].spin = true;
          this.optin.data[0].style =
            "width: 70px;right:5px; top: 25px;color:#2cf314; font-size: 18px; ";
        }
      // } else {
      //   this.optin.data[0].spin = false;
      //   this.optin.data[0].style =
      //     "width: 70px;right:5px; top: 25px;color:#4e5052; font-size: 18px; ";
      // }
    },
    handleTjlb(row) {
      this.getTjxx(row.rowIndex);
    },
    getStatus(res) {
      //  system_state说明
      // 	0~1位重量,00正常,01预警,10报警
      // 	2~3位高度限位,00正常,01预警,10报警
      // 	4~5位超速,00正常,01预警,10报警
      // 	6~7位人数,00正常,01预警,10报警
      // 	8~9位倾斜,00正常,01预警,10报警
      // 	10位前门锁状态,0正常,1异常
      // 	11位后门锁状态,0正常,1异常
      
        console.log(res);
      let status = {
        range: this.getStatusColor(res.slice(0, 2)),
        height: this.getStatusColor(res.slice(2, 4)),
        wind_speed: this.getStatusColor(res.slice(4, 6)),
        weight: this.getStatusColor(res.slice(6, 8)),
        moment: this.getStatusColor(res.slice(8, 10)),
        turn: this.getStatusColor(res.slice(10, 12)),
        dip_angle: this.getStatusColor(res.slice(12, 14)),
      };
      return status;
    },
    getStatusColor(code) {
      console.log(code);
      if (code == "00" || code == "0") {
        return 'background-color: #1ff021';
      } else if (code == "01") {
        return 'background-color: #dbda2c';
      } else if (code == "10" || code == "1") {
        return 'background-color: #d9360b';
      }
    }
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 1387px;
  height: 922px;
  display: flex;
  .left_container {
    width: 437px;
    height: 922px;
    // background-color: rgba(255, 192, 203, 0.4);
    .left1 {
      overflow: hidden;
      position: relative;
      width: 434px;
      height: 372px;
      // background-color: rgba(255, 206, 68, 0.4);
      .tjxx_title1 {
        width: 170px;
        height: 35px;
        margin: 7px auto 0;
        color: #fff;
        text-align: center;
        background: url("/img/source/标题框.png") center 0 no-repeat;
        background-size: 100% 100%;
        .echart-text {
          font-size: 16px;
          line-height: 35px;
        }
      }
      .tjxx_title2 {
        width: 170px;
        height: 35px;
        margin: 162px auto 0;
        color: #fff;
        text-align: center;
        background: url("/img/source/标题框.png") center 0 no-repeat;
        background-size: 100% 100%;
        .echart-text {
          font-size: 16px;
          line-height: 35px;
        }
      }
      .taji_text {
        position: absolute;
        top: 50px;
        left: 20px;
        padding: 2px 0 0 6px;
        width: 460px;
        height: 145px;
        p {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 2px;
          i {
            font-style: normal;
            color: #e8a36a;
          }
        }
      }
      .siji_text {
        position: absolute;
        top: 244px;
        left: 20px;
        padding: 2px 0 0 6px;
        width: 460px;
        height: 80px;
        p {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 2px;
          i {
            font-style: normal;
            color: #e8a36a;
          }
        }
      }
    }
    .left2 {
      width: 434px;
      height: 549px;
      padding-top: 46px;
      // background-color: rgba(255, 0, 0, 0.4);
      .scroll_board {
        width: 407px;
        margin: auto;
        height: 100%;
      }
    }
  }
  .right_container {
    position: relative;
    width: 950px;
    height: 922px;
    .right1 {
      display: flex;
      position: absolute;
      top: 62px;
      left: 0px;
      width: 950px;
      height: 50px;
      span {
        flex: 1;
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        text-align: center;
        color: #2cf314;
      }
    }
    .right2 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 950px;
      height: 230px;
      // background-color: #ffce44;
      .item_box {
        margin: 0 10px;
        .row1,
        .row2,
        .row4 {
          display: flex;
          margin-bottom: 10px;
        }
        .row1 {
          margin-left: 5px;
          .title1 {
            color: #3fc2ff;
            font-weight: 700;
            font-size: 20px;
          }
          .title_icon {
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
              margin-left: 25px;
            }
          }
        }
        .row2 {
          margin-left: 5px;
          font-size: 28px;
          font-weight: 700;
          color: #fff;
          .unit {
            margin-left: 10px;
            // margin-top: 8px;
            font-size: 16px;
            color: #00b8de;
            line-height: 40px;
          }
        }
        .row3 {
          width: 100%;
          margin-left: 5px;
          margin-top: -10px;
          .progress {
            position: relative;
            width: 90px;
            height: 3px;
            border-radius: 3px;
            background-color: #fff;
            .progress_bar {
              width: 80%;
              height: 3px;
              border-radius: 3px;
              background-color: #5f54a6;
            }
            .pervalue {
              position: absolute;
              top: -150%;
              right: -27%;
              font-size: 12px;
              color: #5eb0e8;
            }
          }
        }
        .row4 {
          width: 110px;
          height: 35px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.dv-scroll-board .header {
  font-size: 12px;
}
</style>