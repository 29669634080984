<template>
  <el-scrollbar class="build views">
    <container :props="props"
               :option="option"
               ref="container" v-if="flag"></container>
  </el-scrollbar>
</template>
<script>
import init from '@/mixins/'
import { getDept } from "@/api/system/dept"
export default {
  props: {
    option: Object,
    props: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      flag: false,
      projectId: '',
    }
  },
  mixins: [init],
  created() {
      this.flag = true
      var query = this.$route.query;
      if (query == null || query == undefined || query.projectId == null || query.projectId == undefined) {
      } else {
        const projectId = query.projectId
        window.projectId = projectId
        console.log(projectId)
      }
    // var query = this.$route.query;
    // if (query == null || query == undefined || query.orgid == null || query.orgid == undefined) {
    //   this.$message({ showClose: false, message: "参数错误，请从正确渠道访问本页面！", type: "error" });
    // } else {
    //   const orgid = query.orgid
    //   getDept(orgid).then((response) => {
    //     const dept = response.data.data
    //     if (dept && dept.deptCategory != null && (dept.deptCategory == 0 || dept.deptCategory == 2 || dept.deptCategory == 4)) {
    //       this.flag = true
    //     } else {
    //       this.$message({ showClose: false, message: "参数错误，请从正确渠道访问本页面！", type: "error" });
    //     }        
    //   });
    // }
  }
}
</script>
<style lang="scss">
@import "../styles/style.scss";
</style>