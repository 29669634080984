<template>
  <div class="container">
    <div class="dateSelect">
      <!-- 下拉框 -->
      <el-select
        style="width: 200px; height: 26px"
        :popper-append-to-body="false"
        v-model="materialValue"
        placeholder="请选择"
        :clearable="false"
        size="mini"
        refs="mySelect"
        :reserve-keyword="true"
        filterable
        :multiple-limit="3"
        :filter-method="filter"
        @change="changed"
        @remove-tag="removeTab"
        @focus="focus"
        @blur="funb"
        @visible-change="hidden"
        :multiple="true"
      >
        <el-option
          v-for="item in optionfen"
          :key="item.value"
          :label="item.label"
          remote
          :value="item.value"
        >
        </el-option>

        <div class="el-page">
          <el-pagination
            small
            @current-change="handleCurrentChange"
            :current-page="currentpage"
            :page-size="pageSize"
            layout="prev, pager,next,total"
            :total="options.length"
          >
          </el-pagination>
        </div>
      </el-select>
    </div>
    <!-- 折线图 -->
    <div
      id="xm-cljgqs"
      style="width: 99%; height: 85%; margin-top: 30px; margin-left: 17px"
    ></div>
  </div>
</template>
<script>
export default {
  // 物料管理-材料价格趋势
  name: "test8",
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      materialValue: 1,
      materialList: [],
      options: [], //总数据
      options1: [], //搜索的数据
      optionfen: [], //当前页码的数据
      materialValue: [], //输入框的值
      currentpage: 1, //当前页码
      pageSize: 6, //每页展示的条数
      dataAll: [],
      isTimer: "", //防抖时间
      options: [
        {
          value: 1,
          label: "材料选择",
        },
      ],
      config: {
        value: 123,
        color: "rgba(249, 119, 4, 1)",
      },
      line1: {
        chartData: {
          columns: ["日期", "访问用户"],
          rows: [
            { 日期: "1/1", 访问用户: 1393 },
            { 日期: "1/2", 访问用户: 3530 },
            { 日期: "1/3", 访问用户: 2923 },
            { 日期: "1/4", 访问用户: 1723 },
            { 日期: "1/5", 访问用户: 3792 },
          ],
        },
        vchartsConfig: {
          settings: {
            // offsetY: "50%",
            // radius: 80,
          },
          extend: {
            legend: {
              left: "right",
              textStyle: {
                //图例文字的样式
                color: "#ddd",
                fontSize: 16,
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    // 材料价格趋势
    this.getMaterialList();
    // 材料价格趋势:展示默认图表
    this.getData();
  },
  methods: {
    // 防抖
    isDebounce(fn, delay) {
      let _self = this;
      let args = arguments;
      if (_self.isTimer) {
        clearTimeout(_self.isTimer);
      }
      _self.isTimer = setTimeout(function () {
        fn.apply(_self.isDebounce, args); // 用apply指向调用debounce的对象，相当于_this.fn(args);
      }, delay);
    },
    // 材料价格趋势模块
    getData() {
      let _self = this;
      let dataList = [];
      try {
        let url = myRequest.getRequestUrlWithAnyParam(
          "/api/innerStock/getInnerStockDetailPriceCurveChartData",
          "&materiels=" + _self.materialValue.join(",")
        );
        axios.get(url).then((res) => {
          if (
            res.status === 200 &&
            res.data.data !== undefined &&
            res.data.data.length !== 0 &&
            _self.materialValue.length !== 0
          ) {
            let data = res.data.data;
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < data[i].materielPriceList.length; j++) {
                dataList.push({
                  name: data[i].materielPriceList[j].month,
                  value: data[i].materielPriceList[j].price,
                  group: _self.dataAll[i].label,
                });
              }
            }
          } else {
            dataList = [{ value: 0, name: "", group: "" }];
          }
          var option = MyEcharts.EchartsOption.Line("", "", dataList);
          option.legend.show = false;
          MyEcharts.initChart(option, "xm-cljgqs");
        });
      } catch (err) {
        dataList = [{ value: 0, name: "", group: "" }];
        var option = MyEcharts.EchartsOption.Line("", "", dataList);
        option.legend.show = false;
        option.grid = {
          bottom: 30,
          left: 60,
          right: 40,
          top: 10,
        };
        MyEcharts.initChart(option, "xm-cljgqs");
      }
    },
    // 获取物料的 名和id
    async getMaterialList() {
      let _self = this;
      let url = myRequest.getRequestUrlWithAnyParam(
        "/api/innerStock/getMaterielList",
        "&pageSize=1000000"
      );
      const res = await axios.get(url);
      let data = res.data.data;
      if (res.status === 200 && data !== undefined) {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            _self.materialList.push({
              label: data[i].name,
              value: data[i].id,
            });
          }
          _self.options = _self.materialList;
          _self.options1 = _self.materialList;
        }
      }
    },

    removeTab(res) {},
    //分页的实现,currentpage 为页码，每页展示的数据为10（可自行更改pageSize）条，分别是在总数据options中
    //下标从(currentpage -1)*10开始的十条数据
    handleCurrentChange(val) {
      this.optionfen = [];
      this.currentpage = val;
      let start = (val - 1) * this.pageSize;
      let end = Number(start) + Number(this.pageSize);
      //此处需要判断如果计算的结束下标大于总数据的长度，则需要修改结束下标
      if (end > this.options.length) {
        end = this.options.length;
      }
      for (let i = start; i < end; i++) {
        //将取到的数据展示在页面
        this.optionfen.push(this.options[i]);
      }
      this.optionfen.forEach((item) => {
        item.label = item.label.substr(0, 13);
      });
    },
    //已选择数据
    changed(e) {
      let _self = this;
      this.isShow = false;
      _self.dataAll = [];
      //匹配当前分页与输入框中value对应的数据
      let selectValLength = Array.isArray(_self.materialValue)
        ? _self.materialValue.length
        : 1;
      for (let j = 0; j < selectValLength; j++) {
        const selectVal = Array.isArray(_self.materialValue)
          ? _self.materialValue[j]
          : _self.materialValue;
        for (let i = 0, len = _self.options1.length; i < len; i++) {
          const option = _self.options1[i];
          if (option.value === selectVal) {
            let bool = true;
            //防止添加重复数据
            for (let k = 0; k < _self.dataAll.length; k++) {
              if (_self.dataAll[k].value === selectVal) {
                bool = false;
                break;
              }
            }
            bool ? _self.dataAll.push(option) : "";
            break;
          }
        }
      }
      _self.getData();
      // this.$emit(`${this.funName}`, _self.dataAll); //推送
    },
    // 获得焦点
    //获得焦点的时候跳转到当前第一个value所在的页码
    focus() {
      let _self = this;
      let flag = false;
      let func = function () {
        let firstInputVal = Array.isArray(_self.materialValue)
          ? _self.materialValue[0]
          : _self.materialValue;
        for (let i in _self.options1) {
          i = Number(i);
          if (firstInputVal && _self.options1[i].value == firstInputVal) {
            flag = true;
            if (_self.pageSize >= i) {
              //pageSize大于等于总数据长度，说明只有1页数据或没有数据
              _self.currentpage = 1;
              _self.handleCurrentChange(1);
            } else {
              const size = parseInt(i / _self.pageSize); //取商
              const rest = i % _self.pageSize; //取余数
              if (rest > 0) {
                //余数大于0，说明实际最后一页数据不足pageSize，应该取size+1为最后一条的页码
                _self.currentpage = size + 1; //当前页码重置，取size+1
              } else if (rest === 0) {
                //余数等于0，最后一页数据条数正好是pageSize //注：余数不可能小于0
                _self.currentpage = size; //当前页码重置，取size
              }
            }
            _self.handleCurrentChange(_self.currentpage);
            break;
          }
        }
      };

      _self.isDebounce(func, 150);

      //如果没有就默认展示第一页
      if (!flag) {
        _self.currentpage = 1;
        _self.handleCurrentChange(1);
      }
    },
    // 失去焦点
    funb() {
      //  this.materialValue = this.val;
      let elment = document.querySelector(".el-input__inner");
      elment.style.color = "#ddd";
    },
    // 隐藏select列表
    hidden(bool) {
      if (!bool) {
        //关闭select下拉框的时候重置页码及数据,并移除事件监听
        this.optionfen = [];
        this.options = this.options1;
        let start = 0;
        let end = Number(start) + Number(this.pageSize);
        if (end > this.options1.length) {
          end = this.options1.length;
        }
        for (let i = start; i < end; i++) {
          this.optionfen.push(this.options1[i]);
        }
        // 移除mousedown事件监听
        removeEventListener("mousedown", function () {}, false);
      } else {
        // 打开select列表
        // 增加mousedown事件监听  当点击分页时移除输入框的默认事件 ，让他不会失去焦点（blur),如果不加，就会
        //出现当用户点击分页之后，输入框会失去焦点，这个时候如果用户需要输入数据进行搜索就需要删除输入框的值再输入，体验不好。
        //（elementUI下拉框的默认样式，当可搜索时点击输入框可直接输入，不需要删除上次数据）
        document.addEventListener(
          "mousedown",
          function (e) {
            if (
              e.target.tagName === "LI" ||
              (e.target.tagName == "I" && e.target.localName == "i")
            ) {
              e.preventDefault();
            }
          },
          false
        );
        this.focus();
      }
    },
    //搜索方法,将符合的数据存入options中，并分页展示
    filter(val) {
      // this.optionfen = [];
      let arr = [];
      let value = val.toLowerCase();
      for (let i = 0, len = this.options1.length; i < len; i++) {
        if (this.options1[i].label.toLowerCase().indexOf(value) >= 0) {
          arr.push(this.options1[i]);
        }
      }
      this.options = arr;
      this.handleCurrentChange(1);
    },
  },
};
</script>
<style lang='scss' scoped>
.container {
  overflow: hidden;
  width: 1184px;
  height: 306px;
}
/deep/ .el-select__caret {
  margin-top: 0px;
}
.dateSelect {
  position: absolute;
  top: 12px;
  right: 14.8px;
  .el-select__tags-text {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /deep/.el-select {
    background: #01427b00;
    position: relative;
    .el-select__input {
      background-color: unset !important;
    }
    .el-select-dropdown {
      z-index: 5504 !important;
    }
    .el-input__icon {
      height: 100%;
      width: 22px;
      text-align: center;
      transition: all 0.3s;
      line-height: unset;
    }
    .el-input {
      .el-input__inner {
        background: #01427b2f;
        border-color: #01427b;
        color: #ddd;
        border: 0;
        height: 24px !important;
        line-height: 24px;
      }
    }

    .el-select-dropdown {
      background-color: #27343e;
      border: none; //;0.0125rem solid #646464;
    }
    .el-popper[x-placement^="bottom"] .popper__arrow::after {
      top: 1px;
      margin-left: -6px;
      border-top-width: 0;
      border-bottom-color: #fff;
    }
    .el-select-dropdown__item {
      color: #ddd;
    }
    // option选中的颜色
    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
      background-color: #23434f;
    }
    // option 鼠标经过的颜色
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #23434f;
    }

    .el-select-dropdown__item.selected {
      color: #ddd;
      font-weight: 700;
    }

    // 下拉中的分页按钮
    .el-pagination {
      color: #ddd;
      .el-pager li {
        background: transparent;
        color: #fff;
      }
      .el-pager li:hover {
        color: #62e3fb;
      }
      .el-pagination__total {
        color: #ddd;
      }
    }
    .el-pagination .btn-next,
    .el-pagination .btn-prev {
      background: center center no-repeat transparent;
    }
    .el-pagination .btn-next,
    .el-pagination .btn-prev {
      color: #ddd;
    }
  }
}
</style>