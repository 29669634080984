const path = process.env.VUE_APP_PATH;
//基本配置
export const config = {
    width: 1920,
    height: 1080,
    query: '',
    header: '',
    mark: {
        show: false,
        text: 'avue数据大屏水印',
        fontSize: 20,
        color: 'rgba(100,100,100,0.2)',
        degree: -20
    },
    scale: 1,
    backgroundImage: `${path}img/bg/bg.png`,
    url: '',
    gradeShow: false,
    gradeLen: 30,
};
// 颜色的配置
export const colorOption = {
    menuWidth: 150,
    refreshBtn: false,
    columnBtn: false,
    labelWidth: 100,
    column: [{
        label: '颜色1',
        prop: 'color1',
        type: 'color',
    }, {
        label: '渐变色',
        prop: 'color2',
        type: 'color',
    }, {
        label: '位置',
        prop: 'postion',
        type: 'number'
    }]
}

// 表格的列配置
export const tableOption = {
    menuWidth: 150,
    refreshBtn: false,
    columnBtn: false,
    labelWidth: 100,
    column: [
        {
            label: '名称',
            prop: 'label',
        }, {
            label: 'key值',
            prop: 'prop',
        }, {
            label: '宽度',
            prop: 'width',
            hide: true,
        },
        {
            label: '状态',
            prop: 'hide',
            type: 'switch',
            hide: true,
            value: false,
            dicData: [{
                label: '隐藏',
                value: true
            }, {
                label: '显示',
                value: false
            },]
        },
        {
            label: '编辑',
            prop: 'edit',
            type: 'select',
            hide: true,
            value: false,
            dicData: [{
                label: '是',
                value: true
            }, {
                label: '否',
                value: false
            },]
        },
        {
            label: '编辑类型',
            prop: 'editType',
            type: 'select',
            hide: true,
            value: 'input',
            dicData: [{
                label: '下拉框',
                value: 'select'
            }, {
                label: '输入框',
                value: 'input'
            },]
        },
        {
            label: '动态字典名',
            hide: true,
            prop: 'dynamicSelect',
        },
        {
            label: '静态字典',
            prop: 'dicType',
            type: 'select',
            hide: true,
            value: 'input',
            dicData: [{
                label: 'line',
                value: 'line'
            }, {
                label: 'fontWeight',
                value: 'fontWeight'
            },
                {
                    label: 'border',
                    value: 'border'
                },
                {
                    label: 'textAlign',
                    value: 'textAlign'
                },
                {
                    label: 'materialType',
                    value: 'materialType'
                },
                {
                    label: 'dataType',
                    value: 'dataType'
                },
                {
                    label: 'orientList',
                    value: 'orientList'
                },
                {
                    label: 'dataMethod',
                    value: 'dataMethod'
                },
                {
                    label: 'tabsTypeList',
                    value: 'tabsTypeList'
                },
                {
                    label: 'mapType',
                    value: 'mapType'
                },
                {
                    label: 'target',
                    value: 'target'
                },
                {
                    label: 'swiperType',
                    value: 'swiperType'
                },
                {
                    label: 'swiperIndicator',
                    value: 'swiperIndicator'
                },
                {
                    label: 'format',
                    value: 'format'
                },
                {
                    label: 'datePickerSizeList',
                    value: 'datePickerSizeList'
                },
                {
                    label: 'datePickerTypeList',
                    value: 'datePickerTypeList'
                },
                {
                    label: 'datePickerDefaultSectionList',
                    value: 'datePickerDefaultSectionList'
                },]
        },
        {
            label: 'change事件名',
            hide: true,
            prop: 'changeEvent',
        },
        {
            label: 'blur事件名',
            hide: true,
            prop: 'blurEvent',
        },
        {
            label: 'input事件名',
            hide: true,
            prop: 'inputEvent',
        },
        ]
}
//一些字典的配置
export const dicOption = {
  line: [{ label: '线条', value: 'line' }, { label: '圆环', value: 'circle' }],
  fontWeight: [{ label: 'normal', value: 'normal' }, { label: 'bold', value: 'bold' }, { label: 'bolder', value: 'bolder' }, { label: 'ligter', value: 'ligter' }],
  border: [{ label: '无边框', value: '' }, { label: '内置图片', value: 'img' }, { label: '内置边框', value: 'border' }],
  textAlign: [{ label: '居中', value: 'center' }, { label: '左对齐', value: 'left' }, { label: '右对齐', value: 'right' }],
  dataType: [{ label: '静态数据', value: 0 }, { label: '动态数据', value: 1 }, { label: 'SQL数据', value: 2 }],
  orientList: [{ label: '竖排', value: 'vertical' }, { label: '横排', value: 'horizontal' }],
  dataMethod: [{ label: 'POST', value: 'post' }, { label: 'GET', value: 'get' }],
  eventList: ['tabs', 'text', 'flop', 'defaultDate', 'datePicker', 'year', 'month', 'monthSB','popupQuestion','customPie','customPieTransverse','detailTable', 'three', 'day', 'materialSearch', 'tree','wordClouds','companyDetail'],
  dataList: ['common', 'datav', 'text','defaultDate', 'wordcloud', 'wordClouds', 'companyDetail','year', 'inputSearch', 'month', 'monthSB','popupQuestion','customPie','customPieTransverse','detailTable', 'three', 'day', 'img', 'tabs', 'map', 'video', 'clapper', 'clappers', 'pie', 'pictorialbar', 'iframe', 'swiper', 'flop', 'bar', 'line', 'progress', 'table', 'gauge', 'funnel', 'scatter', 'radar', 'img', 'imgborder', 'test', 'imgList', 'imgTabs', 'pie3d', 'materialSearch', 'tree'],
  themeList: [{
    label: '默认配色',
    value: 'avue'
  }, {
    label: '紫色主题',
    value: 'macarons'
  }, {
    label: '绿色主题',
    value: 'wonderland'
  }],
  barList: ['bar', 'line'],
  titleList: ['bar', 'pie', 'line', 'radar', 'funnel'],
  labelList: ['bar', 'line', 'pie', 'radar', 'scatter'],
  legendList: ['bar', 'pie', 'line', 'radar', 'funnel'],
  colorList: ['bar', 'pie', 'line', 'gauge', 'funnel', 'scatter', 'radar', 'pie3d'],
  tipList: ['bar', 'pie', 'line', 'gauge', 'funnel', 'scatter', 'radar'],
  postionList: ['bar', 'line', 'pictorialbar'],
  labelFormatterList: ['bar', 'map', 'line', 'pie', 'gauge', 'funnel', 'scatter', 'radar'],
  changeFormatterList:['defaultDate'],
  tabsTypeList: [{
    label: '选项卡',
    value: 'tabs',
  }, {
    label: '选择框',
    value: 'select',
  }],
  mapType: [{
    label: '原生',
    value: 0
  }],
  target: [{ label: '本窗口', value: '_self' }, { label: '新窗口', value: '_blank', }],
  swiperType: [{ label: '普通', value: '' }, { label: '立体', value: 'card' }],
  swiperIndicator: [{ label: '外部', value: 'indicator' }, { label: '不显示', value: 'none' }],
  format: [{ label: '日期', value: 'yyyy-MM-dd' }, { label: '日期+时分', value: 'yyyy-MM-dd hh:mm' }, { label: '日期+时分秒', value: 'yyyy-MM-dd hh:mm:ss' }, { label: '日期(无年)', value: 'MM-dd' }, { label: '时分', value: 'hh:mm' }, { label: '时分秒', value: 'hh:mm:ss' }, { label: '星期', value: 'day' }],
  //日期选择器
  datePickerSizeList: [
    {
      label: 'large',
      value: 'large',
    },
    {
      label: 'small',
      value: 'small',
    },
    {
      label: 'mini',
      value: 'mini',
    },
  ],
  datePickerTypeList: [
    {
      label: '年',
      value: 'year',
    },
    {
      label: '月',
      value: 'month',
    },
    {
      label: '周',
      value: 'week',
    },
    {
      label: '日期范围',
      value: 'daterange',
    },
  ],
  datePickerDefaultSectionList: [
    {
      label: '年',
      value: 'year',
    },
    {
      label: '半年',
      value: 'halfMonth',
    },
  ],
    materialType: [{label: '主材', value: '1'}, {label: '辅材', value: '2'}],

}


function concat(prop, count, type, extend = [], defaults) {
    let list = [];
    for (let i = 1; i <= count; i++) {
        list.push({
            label: prop + i,
            value: `${path}img/${prop}/${prop}${i}.${extend.includes(i) ? defaults : type}`
        })
    }
    return list;
}

//加载图片素材库
export const imgOption = [
    concat('bg', 10, 'jpg', [1, 2, 3], 'png'),
    concat('border', 16, 'png'),
    concat('source', 260, 'svg', [1, 15, 16, 20, 239.240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260], 'png'),
    concat('banner', 10, 'png'),
]
