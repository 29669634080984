import request from '../../axios';
export const getList = (current, size, params) => {
  return request({
    url: '/api/blade-system/dept/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getLazyList = (parentId, params) => {
  return request({
    url: '/api/blade-system/dept/lazy-list',
    method: 'get',
    params: {
      ...params,
      parentId
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/blade-system/dept/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/blade-system/dept/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/blade-system/dept/submit',
    method: 'post',
    data: row
  })
}

export const getDept = (id) => {
  return request({
    url: '/api/blade-system/dept/detail',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getDeptTree = (tenantId) => {
  return request({
    url: '/api/blade-system/dept/tree',
    method: 'get',
    params: {
      tenantId,
    }
  })
}

export const getDeptLazyTree = (parentId) => {
  return request({
    url: '/api/blade-system/dept/lazy-tree',
    method: 'get',
    params: {
      parentId
    }
  })
}

/**
 * 查询公司列表
 */
 export const getCompanyList = () => {
  return request({
      url: '/api/blade-system/dept/select-company',
      method: 'get'
  })
}

/**
 * 根据用户所属组织结构查询公司列表
 */
 export const getUserCompanyList = () => {
  return request({
      url: '/api/blade-system/dept/select-user-company',
      method: 'get'
  })
}

/**
 * 查询公司所属项目列表
 */
 export const getCompanyProjectList = (companyId) => {
  return request({
      url: '/api/blade-system/dept/select-company-project',
      method: 'get',
      params: {
        companyId,
      }
  })
}

/**
 * 查询员工所属部门层级（公司、分公司、项目）
 */
 export const getUserDeptLevelList = () => {
  return request({
      url: '/api/blade-system/dept/select-user-dept-levels',
      method: 'get'
  })
}

/**
 * 查询部门列表
 */
 export const getUserDeptList = () => {
  return request({
      url: '/api/blade-system/dept/select-institutional-dept',
      method: 'get'
  })
}

/**
 * 带权限的机构树
 */
 export const getDeptAuthTree = (deptId) => {
  return request({
      url: '/api/huizhuyun-project6j/contracts-projectbaseinfo/board-tree-auth',
      method: 'get',
      params: {
        deptId,
      }
  })
}

/**
 * 带权限的项目列表
 */
 export const getDeptAuthProject = (deptId, projectName, projectStatus) => {
  return request({
      url: '/api/huizhuyun-project6j/contracts-projectbaseinfo/board-list-project-auth',
      method: 'get',
      params: {
        deptId,
        projectName,
        projectStatus
      }
  })
}
