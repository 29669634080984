<!-- 实时时间配置 -->
<template>
    <div>
        <el-form-item label="size">
            <avue-select v-model="main.activeOption.datePickerSize"
                         :dic="dicOption.datePickerSizeList">
            </avue-select>
        </el-form-item>
        <el-form-item label="类型">
            <avue-select v-model="main.activeOption.datePickerType"
                         :dic="dicOption.datePickerTypeList">
            </avue-select>
        </el-form-item>
        <el-form-item label="自定义格式">
            <avue-input v-model="main.activeOption.format">
            </avue-input>
        </el-form-item>
        <el-form-item label="默认显示时间范围">
            <avue-select v-model="main.activeOption.datePickerDefaultSection"
                         :dic="dicOption.datePickerDefaultSectionList">
            </avue-select>
        </el-form-item>
    </div>
</template>

<script>
    import { dicOption } from '@/option/config'
    export default {
        data () {
            return {
                dicOption: dicOption
            }
        },
        inject: ["main"]
    }
</script>

<style>
</style>